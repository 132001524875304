import React, { useEffect, useRef, useState } from 'react';
import { Input, } from '../utils';
import { SearchDropdown, Dropdown } from '../utils';
import { Button } from '../utils';
import { buttonClasses } from '../utils/theme';
import { useCheckpointData } from '../../services/hooks';
import { useUpdateManageTripMutation, useModifyTripMutation } from '../../api/api';
import { useVehicleDetailsMutation } from '../../api/vmsSlice';
import { capitalizeCamelCase, capitalizeSentence, findDeepValue, formatDate, parseUnderscore, removeSpecialChars } from '../../services/functions';
import { toastMessage } from '../utils';
import './Trip.css'
import { useLocation } from 'react-router';
import { SearchIcon } from '../../services/icons';
import DynamicFormRender from '../Dynamic/DynamicForm';
import { useUserDetail } from '../../services/hooks';
import { useUserPermissions } from '../../services/hooks';
import { useGetTripcheckpointMutation } from '../../api/vmsSlice';
import { CheckIcon } from '../../services/icons';

const defaultFilter = {
    "checkpointId": "all",
};

export default function TripIndex() {
    const filterRef = useRef(defaultFilter);
    const { userPermissions } = useUserPermissions();
    const [isModifying, setIsModifying] = useState(false);
    const [searchVehicle, setSearchVehicle] = useState('');
    const [searchTrigger, setSearchTrigger] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const [vehicledata, setVehicleData] = useState({});
    const [vehicleTableHeader, setVehicleTableHeader] = useState([]);
    const [allCheckpointList, setAllCheckpointList] = useState([]);
    const [forcedEndTrip, setForcedEndTrip] = useState(false);
    const [cancelTrip, setCancelTrip] = useState(false);
    const [remarks, setRemarks] = useState('');
    const [error, setError] = useState({
        searchBy: '',
        checkpointId: '',
        remarks: '',
    });
    const { userDetail } = useUserDetail();
    // console.log('userDetail', userDetail)
    const { trigger, checkpointData, isLoading, isFetching, count, message, error: checkpointError } = useCheckpointData(filterRef.current);
    const [updateManageTrip] = useUpdateManageTripMutation();
    const [modifyTripTrigger] = useModifyTripMutation();
    const [vehicleDetailTrigger] = useVehicleDetailsMutation();
    const [getTripcheckpointTrigger] = useGetTripcheckpointMutation();
    const location = useLocation();

    const handleChange = (value, key) => {
        // console.log('value', value, 'key', key);
        setSearchVehicle((previous) => {
            return { [key]: value === null ? "" : value };
        });
    }

    const onhandleClear = () => {
        setSearchTrigger(true)
        setSearchVehicle('')
    }

    const onHandleSearch = () => {
        // console.log('search', searchVehicle);
        setIsModifying(false)
        // convertTextToSpeech(JSON.stringify(searchVehicle));
        if (!searchVehicle) {
            setError({ ...error, 'searchBy': 'Please entered search value' });
        } else {
            setError({ ...error, 'searchBy': '' });
        }
        filterRef.current = { ...filterRef.current, ...{ searchBy: Object.keys(searchVehicle), search: Object.values(searchVehicle) } };
        if (Object.keys(searchVehicle).length > 0) {
            setSearchTrigger(false)
            trigger(filterRef.current);
        }
    }

    // console.log('checkpointData', checkpointData);

    useEffect(() => {
        if (location?.state?.data?._id) {
            handleVehicleDetail(location.state.data._id);
        }
    }, [location])

    const handleVehicleDetail = (id) => {
        getTripcheckpointTrigger({ "type": "all", "tripId": id }).unwrap()
            .then((res) => {
                // console.log('detail-chk', res.data);
                if (res?.error) {
                    toastMessage(false, res.message);
                } else {
                    setAllCheckpointList(res?.data || []);
                }
            })
            .catch((err) => {
                console.log('err', err)
            })
        vehicleDetailTrigger({ vehicleEntryId: id }).unwrap()
            .then((res) => {
                // console.log('detail-status-data', res.data.data);
                // console.log('detail-status', res.data.statusHistory);
                setVehicleData(res?.data || {});
                setSelectedVehicle(res?.data || {});
                setVehicleTableHeader(res?.tableHeader || [])
                // generateDetail(res.data, res.tableHeader)
            })
            .catch((err) => {
                console.log('err', err)
            })
    }

    const onHandleTrip = () => {
        if (!selectedVehicle.vehicleId) {
            return toastMessage(false, 'Vehicle not selected');
        }
        if (remarks.length <= 0) {
            return setError({ ...error, remarks: 'Remarks should be required' });
            // return toastMessage(false,'Remarks should be required')
        }

        const payload = {
            "vehicleEntryId": selectedVehicle._id,
            "remarks": remarks,
            "forceEndTrip": forcedEndTrip,
            "cancelTrip": cancelTrip,
        }

        // console.log(payload);

        updateManageTrip(payload).unwrap()
            .then((res) => {
                if (res?.error) {
                    return toastMessage(false, res.message)
                }
                handleVehicleDetail(selectedVehicle._id)
                // toastMessage(true, 'Trip Updated Successfully')
                setIsModifying(false);
                toastMessage(true, res.message);
                setForcedEndTrip(false)
                setCancelTrip(false)
                setRemarks('')
                setSelectedVehicle({})
                setSearchVehicle('')
                setSearchTrigger(false)
            })
            .catch((err) => {
                toastMessage(false, err.message)
            })
    }

    function flattenObj(res, obj = {}) {
        for (let key in obj) {
            if (Array.isArray(obj[key])) {
                if (obj[key].every((i) => typeof i == "string")) {
                    res[key] = obj[key].join(",");
                } else if (obj[key].every(i => typeof i === "object")) {
                    if (obj[key].every(subObj => typeof subObj[key + "Name"] === "string")) { // static hack for camelcase [key]Name array of objects
                        res[key] = (obj[key].reduce((p, c) => p + c[key + "Name"] + ", ", "")).replace(/,\s*$/, "");
                    }
                }
            } else if (typeof obj[key] == "object") {
                flattenObj(res, obj[key]);
            } else {
                res[key] = obj[key];
            }
        }
    }

    const generateDetail = (data, checkpointList) => {
        if (!data) return [];
        const newStaticDetail = checkpointList?.map((ele, index) => {
            const { order, checkpoints, optional, visited } = ele;
            let checkPointStatus = checkpoints.map((ele, index) => (
                <div style={visited ? {backgroundColor : 'var(--primary-color)'} : {backgroundColor : 'var(--dark-gray-color)'}} className='status-clip' key={`checkpoint-key-${index}`}>   
                    {ele.displayName}
                </div>
            ))
            const status = visited ? data.statusHistory.find((ele, index)=> ele.checkpointId  === checkpoints[0]._id) : null;
            let type;
            let res = data?.data?.map((_, i) => {
                let result = {}
                if (_.checkpointId === status?.checkpointId) {
                    flattenObj(result, _.formData);
                    type = _.type;
                    delete result._id;
                }
                return result;
            })
            let rows = [];
            let uniqueId = 0; // Initialize a unique identifier
            for (let obj of res) {
                for (let key in obj) {
                    if (obj[key]) {
                        rows.push(
                            <div className="detail_row" key={`${key}-${uniqueId}`}>
                                <div className="detail_row_title">{capitalizeSentence(parseUnderscore(capitalizeCamelCase(key)))}</div>
                                <div className="detail_row_value">{obj[key]}</div>
                            </div>
                        );
                        uniqueId++; // Increment the unique identifier
                    }
                }
            }
            rows.push(
                <div className="detail_row" key={`${order}-${status?.checkpointId}-inTime`}>
                    <div className="detail_row_title">{status?.checkpointType == 'access' ? 'Approval Request Time' : status?.checkpointType == 'bay' ? 'Dock Operation Start' : 'In Time'}</div>
                    <div className="detail_row_value">{formatDate(status?.inTime)|| '-'}</div>
                </div>
            );
            
            rows.push(
                <div className="detail_row" key={`${order}-${status?.checkpointId}-outTime`}>
                    <div className="detail_row_title">{status?.checkpointType == 'access' ? 'Approval Accepted Time' : status?.checkpointType == 'bay' ? 'Dock Operation End' : 'Out Time'}</div>
                    <div className="detail_row_value">{formatDate(status?.outTime) || '-'}</div>
                </div>
            );
            const view = (
                <div key={index} className='statusHistory-container'>
                    <div className='statuslayout-container'>
                        <div className='status-connector-container'>
                            <div style={index === 0 ?  {backgroundColor : 'var(--background-color)'} : visited ? {backgroundColor : 'green'} : {backgroundColor : 'var(--dark-gray-color)'}} className='status-connector-line'></div>
                            <div style={visited ? {backgroundColor : 'green'} : {backgroundColor : 'var(--dark-gray-color)'}} className='status-connector-circle'>
                               {visited && <CheckIcon fontSize={'small'} htmlColor={'#fff'}/>}
                            </div>
                            <div style={index === checkpointList.length - 1 ?  {backgroundColor : 'var(--background-color)'} : visited ? {backgroundColor : 'green'} : {backgroundColor : 'var(--dark-gray-color)'}} className='status-connector-line'></div>
                        </div>
                        <div className='status-checkpoint-container'>
                            {checkPointStatus}
                        </div>
                    </div>
                    <div className='statusHistory-detail-content'>
                        <div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>
                            {status?.displayName || ''}{type && `(${capitalizeCamelCase(type)})`}
                        </div>
                        <div style={{ padding: "0.5rem", borderRadius: "12px", color: "var(--text-color)" }}>
                            {rows}
                        </div>
                    </div>
                </div>
            );
            return view;

        }) || [];
        // const newStaticDetail = data.statusHistory?.map(({ displayName, inTime, outTime, checkpointId, checkpointType }, i) => {
        //     // { type, formData, checkpointId }
        //     let type;
        //     let res = data?.data?.map((_, i) => {
        //         let result = {}
        //         if (_.checkpointId === checkpointId) {
        //             flattenObj(result, _.formData);
        //             type = _.type;
        //             delete result._id;
        //         }
        //         return result;
        //     })
        //     let rows = [];
        //     for (let obj of res) {
        //         for (let key in obj) {
        //             if (obj[key]) {
        //                 rows.push(
        //                     <div className="detail_row" key={key}>
        //                         <div className="detail_row_title">{capitalizeSentence(parseUnderscore(capitalizeCamelCase(key)))}</div>
        //                         <div className="detail_row_value">{obj[key]}</div>
        //                     </div>
        //                 );
        //             }
        //         }
        //     }
        //     rows.push(
        //         <div className="detail_row" key={inTime + displayName}>
        //             <div className="detail_row_title">{checkpointType == 'access' ? 'Approval Request Time' : checkpointType == 'bay' ? 'Dock Operation Start' : 'In Time'}</div>
        //             <div className="detail_row_value">{formatDate(inTime)}</div>
        //         </div>
        //     );
        //     rows.push(
        //         <div className="detail_row" key={outTime + i + displayName}>
        //             <div className="detail_row_title">{checkpointType == 'access' ? 'Approval Accepted Time' : checkpointType == 'bay' ? 'Dock Operation End' : 'Out Time'}</div>
        //             <div className="detail_row_value">{formatDate(outTime)}</div>
        //         </div>
        //     );
        //     const view = (
        //         <div key={i} className='statusHistory-detail-content'>
        //             <div style={{ fontWeight: "bold", textAlign: "center", color: "var(--text-color)" }}>
        //                 {displayName} {type && `(${capitalizeCamelCase(type)})`}
        //             </div>
        //             <div style={{ padding: "0.5rem", borderRadius: "12px", color: "var(--text-color)" }}>{rows}</div>
        //         </div>
        //     );
        //     return view;

        // })
        return (
            <>
                {newStaticDetail}
            </>
        );
    }

    useEffect(() => {
        if (checkpointError && !isLoading) {
            toastMessage(false, message);
        }
    }, [isLoading])
    //dynamic form
    const handleClose = () => {
        // console.log('handleClose called');
        setIsModifying(!isModifying)
    }
    const handleFormSubmit = (data) => {
        const payload = {
            "vehicleEntryId": selectedVehicle._id,
            ...data?.data
        }
        // console.log('Form submitted', payload);
        modifyTripTrigger(payload).unwrap()
            .then((res) => {
                if (res?.error) {
                    return toastMessage(false, res.message)
                }
                // toastMessage(true, 'Trip Updated Successfully');
                handleVehicleDetail(selectedVehicle._id)
                setIsModifying(false);
                toastMessage(true, res.message);
                setForcedEndTrip(false)
                setCancelTrip(false)
                setRemarks('')
                setSelectedVehicle({})
                setSearchVehicle('')
                setSearchTrigger(false)

            })
            .catch((err) => {
                toastMessage(false, err.message)
            })
    }
    return (
        <>
            <div className="contentpanel" onClick={() => !searchTrigger ? setSearchTrigger(true) : null}>
                <div className="cust-row flex-algn-cent">
                    <div className="cust-col-5">
                        <h1 className="title">Manage Trip</h1>
                    </div>
                    <div className="cust-col-5 flex-jc-end gap-3">
                    </div>
                </div>
                <hr className="bgg" />
                <div className='trip-search-section'>
                    <div className='trip-search-section-left'>
                        <SearchDropdown
                            id={"vehicleNumber"}
                            name={"vehicleNumber"}
                            options={[
                                { label: "Vehicle Number", value: "vehicleNumber" },
                                { label: "Trip ID", value: "trip_counter" },
                            ]}
                            preSelectedOption={{ label: "Vehicle Number", value: "vehicleNumber" }}
                            label={"Search by"}
                            placeholder={"Enter value"}
                            value={searchVehicle}
                            Debounce={true}
                            textBorderRadius="1px 0px 0px 1px"
                            selectBorderRadius="20px 1px 1px 20px"
                            adornment={{
                                is: true,
                                onClick: () => {
                                    setSearchTrigger(true)
                                    setSearchVehicle('')
                                },
                                type: isLoading ? "loader" : "CloseIcon",
                            }}
                            onDropdownChange={onhandleClear}
                            onChange={(value, key) => handleChange(value, key)}
                            onhandleClear={onhandleClear}
                        />
                        <div style={{ textAlign: 'start' }}>
                            {error?.searchBy ? <span style={{ color: 'var(--red-color)', fontSize: '12px' }}>{error.searchBy}</span> : null}
                        </div>
                        <div>
                            {!searchTrigger && checkpointData?.length > 0 ? <div className={'trip-search-section-left-list'}>
                                <ul>
                                    {checkpointData.map((ele, index) => (
                                        <li
                                            className={ele._id === selectedVehicle._id ? 'active' : null}
                                            key={ele._id}
                                            onClick={() => {
                                                // console.log('selected vehicle', ele)
                                                handleVehicleDetail(ele._id)

                                            }}
                                        >
                                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>Vehicle No. : </span>
                                            <span style={{ fontSize: "14px" }}>{`${ele.vehicleNumber}`}</span>
                                            <span style={{ fontSize: "14px", fontWeight: "bold" }}>, Trip ID :</span>
                                            <span style={{ fontSize: "14px" }}>{`${ele.trip_counter}`}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div> : null}
                        </div>

                    </div>
                    <div className='trip-search-section-right'>
                        <Button
                            text={<SearchIcon />}
                            style={{ ...buttonClasses.lynkitOrangeFill, height: '45px', minWidth: '45px', borderRadius: '50%', transform: 'Translate(-12px, -3px)' }}
                            onClick={() => {
                                onHandleSearch();
                            }}
                        />
                    </div>
                </div>
                {Object.keys(vehicledata).length > 0 ? <div className='trip-detail-section'>
                    <div className='trip-detail-container'>
                        <div className='trip-detail-header'>
                            <h4>{!isModifying ? "Vehicle Detail" : "Modify Trip"}</h4>
                            {(userPermissions?.modifyTrip?.value && !isModifying && !selectedVehicle?.trip_over) && <span className='modify-btn' onClick={() => setIsModifying(!isModifying)}>Modify Trip</span>}
                        </div>
                        {isModifying ?
                            <>
                                <div style={{ padding: "0px 20px" }}>
                                    <div>
                                        <p style={{ color: "var(--red-color)", fontSize: "14px" }}>
                                            {`**Note: Once you click Submit the trip the current trip with ID ${selectedVehicle?.trip_counter} will be concluded, and the timestamp for this action will be recorded as the current time for upcoming checkpoints of current trip.`
                                            }
                                        </p>
                                    </div>
                                    <DynamicFormRender
                                        templatePayload={{
                                            "organization_id": userDetail.orgId,
                                            "template_name": "modifyTrip",
                                            "type": "modifyTrip"
                                        }}
                                        checkoutData={{ trip_counter: vehicledata.trip_counter }}
                                        onClose={handleClose}
                                        onSubmit={handleFormSubmit}
                                    // isSubmitDisabled={isSubmitting}
                                    />
                                </div>
                            </>
                            :
                            <>
                                <div className='trip-detail-content'>
                                    {
                                        vehicleTableHeader.length > 0 && vehicleTableHeader.map((ele, index) => (
                                            <div key={index} className='trip-detail-content-field'>
                                                <div style={isModifying && (ele.label == 'Product' || ele.label == 'Product Category') ? { paddingTop: '5px' } : {}} className='trip-detail-content-field-label'>
                                                    <span>{ele.label} :</span>
                                                </div>
                                                <div className='trip-detail-content-field-value'>
                                                    {isModifying && (ele.label == 'Product' || ele.label == 'Product Category') ?
                                                        <span>
                                                            <Dropdown
                                                                id={ele.label}
                                                                name={ele.label == 'Product' ? 'product' : ele.label == 'Product Category' ? 'productCategory' : null}
                                                                value={null}
                                                                options={[]}
                                                                label={ele.label}
                                                            // onChange={(option, key) => onHandleCheckpointDetailChange(option, key)}
                                                            // error={error?.checkPoint?.orgId}
                                                            />
                                                        </span>
                                                        :
                                                        <span>
                                                            {ele.type == "date" ? formatDate(findDeepValue(vehicledata, ele.name.split("."))) : findDeepValue(vehicledata, ele.name.split("."))}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <div>
                                    {(allCheckpointList.length > 0 && vehicleTableHeader.length > 0) && generateDetail(vehicledata, allCheckpointList)}
                                </div>
                            </>}
                    </div>
                </div> : null}
                {Object.keys(selectedVehicle).length > 0 && !selectedVehicle?.trip_over && !isModifying ?
                    <div className='trip-button-section'>
                        {userPermissions?.cancelTrip?.value && <div>
                            {!cancelTrip ? <Button
                                text="Cancel Trip"
                                style={{ ...buttonClasses.lynkitOrangeFill, height: '40px', marginRight: '10px' }}
                                onClick={() => {
                                    setCancelTrip(true);
                                    setForcedEndTrip(false);
                                    setRemarks('');
                                    setError({ ...error, remarks: '' });
                                }}
                            />
                                :
                                <div className='trip-cancel-section'>
                                    <div>
                                        <Input
                                            id={"remark"}
                                            name={"remark"}
                                            value={remarks}
                                            onChange={(e) => {
                                                setRemarks(e.target.value);
                                            }}
                                            label={"Remarks"}
                                            placeholder={"Enter Remarks"}
                                            required={true}
                                            error={error?.remarks}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button
                                            text="Submit"
                                            style={{ ...buttonClasses.lynkitOrangeFill, height: '40px', marginRight: '10px' }}
                                            onClick={() => {
                                                onHandleTrip()
                                            }}
                                        />
                                    </div>

                                </div>}

                        </div>}
                        {userPermissions?.forceEndTrip?.value && <div>
                            {!forcedEndTrip ? <Button
                                text="Forced End Trip"
                                style={{ ...buttonClasses.lynkitOrangeFill, height: '40px' }}
                                onClick={() => {
                                    setForcedEndTrip(true);
                                    setCancelTrip(false);
                                    setRemarks('');
                                    setError({ ...error, remarks: '' });
                                }}
                            /> :
                                <div className='trip-cancel-section'>
                                    <div>
                                        <Input
                                            id={"remark"}
                                            name={"remark"}
                                            value={remarks}
                                            onChange={(e) => {
                                                setRemarks(e.target.value);
                                            }}
                                            label={"Remarks"}
                                            placeholder={"Enter Remarks"}
                                            required={true}
                                            error={error?.remarks}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <Button
                                            text="Submit"
                                            style={{ ...buttonClasses.lynkitOrangeFill, height: '40px', marginRight: '10px' }}
                                            onClick={() => {
                                                onHandleTrip()
                                            }}
                                        />
                                    </div>

                                </div>
                            }
                        </div>}
                    </div>
                    : null}
            </div>
        </>
    )
}
