export const valid_mobile = (mobile) => {
    var phoneno = /^\d{10}$/;
    if(!mobile.match(phoneno)){
      return false;
    }
    else{
      return true;
    }
}
  
export const valid_email = (email) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email))
    {
      return true;
    }
    else{
      return false; 
    }        
}
  
export const valid_gst = (gst) => {
    var regexGST = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}Z[a-zA-Z\d]{1}?$/;
    return regexGST.test(gst);
}
  
export const valid_pan = (pan) => {
    var regexPAN = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}?$/;
    if(!pan.match(regexPAN)|| pan.length!==10) 
    {
      return false;
    }
    else{
        return true;
    }
}

export const valid_password = (password) => {
  var regexPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if(!password.match(regexPassword)) 
  {
    return false;
  }
  else{
      return true;
  }
}

export const includes_special_char = str=>{
  var regex = /[!"`'%&,:;<>={}~\$\(\)\+\/\\\?\[\]\^\|]+/;
  // return str.match(regex)
  return str
}

