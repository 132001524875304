import React, { useEffect, useRef, useState } from "react";
import { Dropdown, GenericTable, Input, toastMessage, Button, tCell, ActionMenu, Dialog } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import './TagMapping.css';
import { useGetDataFromUlipMutation } from "../../api/vmsSlice";
import { useGetArchiveTagsQuery, useGetTagsMutationMutation } from "../../api/deviceSlice";
import { useGetVehiclesMutationMutation } from "../../api/vmsSlice";
import { useMapVehicleTagMutation } from "../../api/vmsSlice";

import { formatDate, findDeepValue } from "../../services/functions";

const defaultFilter = {
    pagesize: 10,
    page_no: 1,
};
const dummy = [
    {
        tagId: "34161FA82033E764D9F45341",
        vehicleNumber: "",
        time: "12:30 PM",
    },
    {
        tagId: "34161FA82033E764D9F45444",
        vehicleNumber: "",
        time: "12:50 PM",
    }
]
export default function TagMappingIndex() {
    const { page_no = 1, pagesize = 5 } = defaultFilter;
    const [isModal, setIsModal] = useState(false);
    const [fastTagUnable, setFastTagUnable] = useState(true);
    const [isloadingText, setIsLoadingText] = useState('');
    const [formData, setFormData] = useState({});
    const [erroredFields, setErroredFields] = useState({});
    //apis
    const [ulipTrigger] = useGetDataFromUlipMutation();
    const [getVehicleTrigger] = useGetVehiclesMutationMutation();
    const [mapVehicleTagTrigger] = useMapVehicleTagMutation();
    const [getTagsTrigger] = useGetTagsMutationMutation();
    const { data, refetch, isLoading, isFetching } = useGetArchiveTagsQuery({}, { refetchOnMountOrArgChange: true }); // get tag from this api
    const { data: archiveTags, message, error } = data || [];
    // table 
    let header = ["S.no", "Tag Value", "Vehicle Number", "Time", "Action"];
    let actionHandlers = {};
    actionHandlers.edit = async (id) => {
        // console.log('Edit', id, archiveTags[id]);
        const { tagId, isExistOnTagMaster } = archiveTags[id];
        setIsModal(true);
        setFormData((previouseFormData) => ({
            scanTag: tagId,
        }));
        const payload = {
            "tagId": tagId
        }
        setIsLoadingText('Please wait, verifying the tag from ULIP...');
        await ulipTrigger({ ...payload }).unwrap()
            .then(async (res) => {
                // console.log('res', res);
                const vehicleDetail = res?.data?.api_response?.response[0]?.response?.vehicle?.vehicledetails?.find((vehicle, index) => {
                    const result = vehicle.detail.find((ele) => ele.value === tagId)
                    return Boolean(result) && vehicle.detail
                }).detail || {}
                // console.log('vehicleNumber==>', vehicleDetail?.REGNUMBER);
                if (vehicleDetail?.REGNUMBER) {
                    setIsLoadingText('Please wait Now, fetching vehicle detail...');
                    const vehicleDetailFromIO = await handlefetchVehicle(vehicleDetail?.REGNUMBER);
                    // console.log('====>io', vehicleDetailFromIO);
                    if (Object.keys(vehicleDetailFromIO).length > 0) {
                        setIsLoadingText('');
                        setFormData((previouseFormData) => ({
                            ...previouseFormData,
                            scanTag: tagId,
                            tagType: "fastTag",
                            vehicleNumber: vehicleDetail?.REGNUMBER,
                            driverName: vehicleDetailFromIO?.formData?.driverName,
                            driverNumber: vehicleDetailFromIO?.formData?.driverNumber,
                            VehicleOwnerName: vehicleDetailFromIO?.owner_Name,
                            vehicleExist: true,
                        }));
                    } else {
                        setIsLoadingText('');
                        setFormData((previouseFormData) => ({
                            ...previouseFormData,
                            scanTag: tagId,
                            vehicleNumber: vehicleDetail?.REGNUMBER,
                            tagType: "fastTag",
                            vehicleExist: false,
                        }));

                    }
                } else {
                    setIsLoadingText('');
                    if (isExistOnTagMaster === false) {
                        setFastTagUnable(false)
                    }
                }

            })
            .catch((err) => {
                setIsLoadingText('');
                console.error(err);
            })
        if (isExistOnTagMaster) {
            const payload = {
                "pagesize": 10,
                "page_no": 1,
                "epc": tagId,
                "orgId": ""
            }
            getTagsTrigger(payload).unwrap()
                .then((res) => {
                    if (!res.error) {
                        const { displayName, tagType } = res?.data[0] || {}
                        // console.log('res', res?.data[0]);
                        setFormData((previouseFormData) => ({
                            ...previouseFormData,
                            DisplayName: displayName,
                            tagType: tagType
                        }));
                        setFastTagUnable(true)
                    }
                })
                .catch((err) => {
                    console.log('err', err);
                })

        }
    };
    const rows = archiveTags?.map((d, i) => {
        let row = [];
        row.push(
            tCell((page_no - 1) * pagesize + i + 1),
            tCell(d.tagId),
            tCell(d.vehicleNumber || '-'),
            tCell(formatDate(d.created_on) || '-'),
        );
        row.push(<div style={{paddingLeft: "0.5rem"}}>
				<ActionMenu id={i} handlers={actionHandlers} />
			</div>);
        return row;
    }) || [];


    //api handle

    const handlefetchVehicle = async (vehicleNumber) => {
        try {
            const response = await getVehicleTrigger({
                "page_no": "1",
                "limit": "25",
                "searchBy": "vehicle",
                "search": vehicleNumber
            });
            if (response?.data?.error === false) {
                // console.log('vehicleDetail==>', response?.data?.data[0]);
                return response?.data?.data[0];
            } else {
                return {};
            }
            // const vehicle = response;
            // console.log('vehicle', vehicle);
        }
        catch (error) {
            console.error('Error while fetching vehicle', error);
            return {};
        }
    }

    //modal action

    // console.log('archiveTags clicked', archiveTags);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    const handleDropdownChange = (value, name) => {
        setFormData({ ...formData, [name]: value });
    }

    const handleSumbit = (data) => {
        // console.log('Submit Clicked', formData);
        const validation = validatePayload(formData);
        if (validation.isValid === false) {
            setErroredFields(validation.errors);
            return;
        }
        let payLoad = {
            "type": "unregistered",
            "vehicleExist": formData.vehicleExist,
            "vehicleData": {
                "reg_no": formData.vehicleNumber,
                "truck_no": formData.vehicleNumber,
                "owner_Name": formData.VehicleOwnerName,
                "formData": {
                    "driverName": formData.driverName,
                    "driverNumber": formData.driverNumber
                },
                "template_name": "vehicle"
            },
            "tagData": {
                "tag_tid": "",
                "tag_epc": formData.scanTag,
                "tagType": formData.tagType,
                "displayName": formData.DisplayName
            }
        }
        mapVehicleTagTrigger({ ...payLoad }).unwrap()
            .then((res) => {
                closeModal();
                refetch();
            })
            .catch((err) => {
                console.error('Error while submitting form', err);
                closeModal();
            })
        // console.log('validation', validation);
    };

    const onDropdownChange = (value, name) => {
    
        // console.log('name==>', name, 'value==>', value);
        // handleDropdownChange(value, name);
    }

    const onInputChange = (e) => {
        // console.log('on search', e.target.value);
    }


    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className="cust-col-5">
                    <h1 className="title">Unregister Tag</h1>
                </div>
                <div className="cust-col-5 flex-jc-end gap-3">
                </div>
            </div>
            <hr className="bgg" />
            <div>
                <GenericTable
                    header={header}
                    rows={rows}
                // pageCount={count}
                // pageNo={page_no}
                // limit={pagesize}
                // onPageChange={onPageChange}
                // onPageSizeChange={onPageSizeChange}
                // isLoading={isLoading}
                />
            </div>
            <Dialog size="sm" open={isModal} handleSubmit={() => handleSumbit()} handleClose={() => setIsModal(false)} title={"Tag Allocation"}>
                <div className='tagMapping-field-container'>
                    <Input
                        // error={errors?.orgName}
                        label="Scan Tag (epc) "
                        placeholder="Enter Scan Tag"
                        name="scanTag"
                        value={formData?.scanTag || ''}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.scanTag || ""}
                    />
                    <Input
                        // error={errors?.orgName}
                        label="Vehicle Number"
                        placeholder="Enter Vehicle Number"
                        name="vehicleNumber"
                        value={formData?.vehicleNumber || ''}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.vehicleNumber || ""}
                    />
                    {/* <div style={{ width: '215px', }}>
                        <Dropdown
                            // readonly={updateData}
                            id="vehicleNumber"
                            name="vehicleNumber"
                            label="Search Vehicle Number"
                            onChange={(option, key) => handleDropdownChange(option?.value, key)}
                            onInputChange={onInputChange}
                            options={[{
                                label: "Owner Name",
                                value: "ownerName"
                            },
                            {
                                label: "Driver Name",
                                value: "driverName"
                            }]}
                            value={formData?.vehicleNumber || ''}
                            required 
                            error={erroredFields?.vehicleNumber || ""}
                        />
                    </div> */}
                    <div style={{ width: '215px', }}>
                        <Dropdown
                            name="tagType"
                            value={formData?.tagType ? { label: convertToCap(formData?.tagType), value: formData?.tagType } : ""}
                            options={[
                                {
                                    label: "Fast Tag",
                                    value: "fastTag"
                                },
                                {
                                    label: "Fixed Tag",
                                    value: "fixedTag"
                                },
                                {
                                    label: "Temp Tag",
                                    value: "tempTag"
                                }
                            ]}
                            onChange={(option, key) => handleDropdownChange(option?.value, key)}
                            label="Tag Type"
                            // readonly={fastTagUnable}
                            error={erroredFields?.tagType || ""}
                        />
                    </div>
                    <Input
                        // error={errors?.orgName}
                        label="Tag Display Name"
                        placeholder="Enter DisplayName"
                        name="DisplayName"
                        value={formData?.DisplayName || ""}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.DisplayName || ""}
                    />
                    <Input
                        // error={errors?.orgName}
                        label="Vehicle Owner Name"
                        placeholder="Enter Vehicle Owner Name"
                        name="VehicleOwnerName"
                        value={formData?.VehicleOwnerName || ""}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.VehicleOwnerName || ""}
                    />
                    <Input
                        // error={errors?.orgName}
                        label="Driver Name"
                        placeholder="Enter Driver Name"
                        name="driverName"
                        value={formData?.driverName || ""}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.driverName || ""}
                    />
                    <Input
                        // error={errors?.orgName}
                        label="Driver Name"
                        placeholder="Enter Driver Number"
                        name="driverNumber"
                        value={formData?.driverNumber || ""}
                        onChange={(e) => handleChange(e)}
                        error={erroredFields?.driverNumber || ""}
                    />
                    {isloadingText && <div className="loading-container">
                        <p>{isloadingText}</p>
                    </div>}

                </div>
            </Dialog>
        </div>
    )
}

export function convertToCap(camelCaseStr) {
    let result = '';

    for (let i = 0; i < camelCaseStr.length; i++) {
        const char = camelCaseStr[i];

        if (char === char.toUpperCase() && i !== 0) {
            result += ' ';
        }

        result += char;
    }

    return result.replace(/\b\w/g, char => char.toUpperCase());
}

export function validatePayload(payload) {
    const errors = {};

    // Validate scanTag: should be a non-empty string
    if (!payload.scanTag || typeof payload.scanTag !== 'string') {
        errors['scanTag'] = 'it should be a non-empty string.';
    }

    // Validate vehicleNumber: should be a non-empty string
    if (!payload.vehicleNumber || typeof payload.vehicleNumber !== 'string') {
        errors['vehicleNumber'] = 'this is required.';
    }

    // Validate time: should be a non-empty string, here we can add more complex validation if needed
    if (!payload.time || typeof payload.time !== 'string') {
        errors['time'] = 'this is required.';
    }

    // Validate tagType: should be "fastTag"
    if (!payload.tagType || typeof payload.tagType !== 'string') {
        errors['tagType'] = 'this is required".';
    }

    // Validate VehicleOwnerName: should be a non-empty string
    if (!payload.VehicleOwnerName || typeof payload.VehicleOwnerName !== 'string') {
        errors['VehicleOwnerName'] = 'this is required.';
    }

    // Validate RCNumber: should be a non-empty string
    if (!payload.driverName || typeof payload.driverName !== 'string') {
        errors['driverName'] = 'this is required.';
    }

    if (!payload.driverNumber || typeof payload.driverNumber !== 'string') {
        errors['driverNumber'] = 'this is required.';
    }

    // If there are errors, return them
    if (Object.keys(errors).length > 0) {
        return {
            isValid: false,
            errors: errors
        };
    }

    // If everything is valid, return isValid as true
    return {
        isValid: true,
        errors: {}
    };
}
