import React from 'react';
import { Link } from 'react-router-dom';

const links = ['Home', 'Partners', 'Contact US']

const RightNav = ({ open, change }) => {
  const handleClick = () => {
    change("partners")
  }

  return (
    <ul className={open ? 'web-ul2' : 'web-ul'} open={open}>
      <li className='head-btn' onClick={() => handleClick()}>Home</li>
      <li className='head-btn' onClick={() => handleClick()}>Partners</li>
      <li className='head-btn' onClick={() => handleClick()}> Contact Us</li>
      <Link to="/login" className='a_tag_no'><li className='head-btn' onClick={() => handleClick()}>Login</li></Link>
    </ul>
  )

}

export default RightNav

