import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { GenericTable, Input, toastMessage, Button, tCell, Dialog, ActionMenu, Dropdown } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { useCheckpoints, useOrgs, useRoles, useUserDetail, useUserPermissions } from "../../services/hooks";
import { useAddRoleMutation, useDeleteRoleMutation, useUpdateRoleMutation } from "../../api/userSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const Roles = () => {
	const { userPermissions, isFetched } = useUserPermissions();
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [filterApplied, setFilterApplied] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const updateDataRef = useRef(null);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};

	const filterFields = [
		{
			inputType: "textfield",
			id: "role",
			name: "role",
			type: "text",
			label: "Role",
			placeholder: "Role",
			readOnly: false,
			error: "",
			value: "",
		},
	];

	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}

	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { roles, count, isLoading, getRoles } = useRoles(filterRef.current);

	// const [handleDelete] = useDeleteRoleMutation();
	let actionHandlers = {};
	if (userPermissions?.updateUserRole?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = roles[id];
			setIsModal(true);
		};
	}
	// if (userPermissions?.deleteUserRole?.value) {
	// 	actionHandlers.delete = async (id) => {
	// 		// console.log(id, "delete");
	// 		const data = roles[id];
	// 		const payload = { deleteId: data._id };
	// 		const {
	// 			data: { error, message },
	// 		} = await handleDelete(payload);
	// 		if (error) {
	// 			toastMessage(false, message);
	// 		} else {
	// 			onUpdateSuccess();
	// 			toastMessage(true, message);
	// 		}
	// 	};
	// }
	let header = ["S.no", "Role", "Display Name"];
	if (userDetail?.userType == "superAdmin") header.push("Org Name");
	if (userPermissions?.updateUserRole?.value || userPermissions?.deleteUserRole?.value) {
		header.push("Action");
	}
	const rows =
		roles?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.role), tCell(d.displayName));

			if (userDetail?.userType == "superAdmin") row.push(tCell(d.orgName));

			if (userPermissions?.updateUserRole?.value || userPermissions?.deleteUserRole?.value) {
				row.push(<div style={{paddingLeft: "0.5rem"}}>
				<ActionMenu id={i} handlers={actionHandlers} />
			</div>);
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getRoles(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getRoles(filterRef.current);
	};

	const onUpdateSuccess = () => {
		getRoles(filterRef.current);
	};

	const handleFilterChange = (filter) => {
		if ((filter?.role != "" || filter?.orgId != "") && Object.keys(filter).length > 0) {
			// console.log('filter on change-device', filter);
			// Update orgId property safely
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getRoles(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.role === "" && filter?.orgId === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log('without-filter on change-device', filter);
			getRoles(defaultFilter);
			setFilterApplied(false);
		}
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getUserRoles?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Roles</h1>
				</div>
				{userPermissions?.createUserRole?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link className="a_tag_no">
							<Button onClick={onModalOpen} text="Add Role" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={'roles'}/>
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			{isModal && <AddRole open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</div>
	);
};

export default Roles;

const defaultForm = {
	role: "",
	displayName: "",
	updateId: undefined,
	orgId: undefined,
	checkpoints: [],
};

function fillUpdateData(data) {
	return {
		role: data.role,
		displayName: data.displayName,
		updateId: data._id,
		orgId: data.orgId,
		checkpoints: data.checkpoints || [],
	};
}

function AddRole({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const filterRef = useRef({ orgId: updateData?.orgId });
	const { checkpointOptions } = useCheckpoints(filterRef.current);
	let checkpointValues = checkpointOptions?.filter((chk) => formData.checkpoints?.find((fchk) => fchk == chk.value));

	const orgValue = orgOptions.find((_) => _.value == formData.orgId);
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const onDropdownChange = (option, name) => {
		let value = Array.isArray(option) ? option.map((_) => _.value) : option?.value || "";
		handleInput({ target: { name, value } });
		if (name == "orgId") {
			filterRef.current = { ...filterRef.current, orgId: option?.value || "" };
		}
	};

	const [handleAdd, { isLoading: isAdding }] = useAddRoleMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateRoleMutation();

	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label };
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.role) error.role = "Role is required";
		if (!formData.displayName) error.displayName = "Display Name is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	return (
		<Dialog size="xs" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={updateData ? "Update Role" : "Add Role"} isSubmitDisabled={isAdding || isUpdating}>
			<form>
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} required error={error.orgId} />
					) : null}
					<Input label="Role" placeholder="Enter Role" name="role" value={formData.role} onChange={handleInput} required error={error.role} />
					<Input label="Display Name" placeholder="Enter Display Name" name="displayName" value={formData.displayName} onChange={handleInput} required error={error.displayName} />
					<Dropdown
						id="checkpoints"
						name="checkpoints"
						label="Checkpoints"
						onChange={onDropdownChange}
						options={checkpointOptions}
						value={checkpointValues}
						error={error.checkpoints}
						multiple
					/>
				</div>
			</form>
		</Dialog>
	);
}
