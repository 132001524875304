import React, { useRef, useState } from 'react';
import { useGetApprovalListingQuery, useUpdateApprovalMutation, useGetTripcheckpointMutation } from '../../api/vmsSlice';
import { useUserDetail, useUserPermissions } from '../../services/hooks';
import { GenericTable, tCell, ActionMenu, toastMessage } from '../utils';
import { formatDate, findDeepValue, capitalizeCamelCase, removeSpecialChars } from '../../services/functions';
import './Approval.css'
import { Navigate } from "react-router-dom/dist";
import { Checkbox, Button } from '../utils';
import { buttonClasses } from '../utils/theme';

const defaultFilter = {
    pagesize: 10,
    page_no: 1,
};

export default function ApprovalIndex() {
    const { userPermissions, isFetched } = useUserPermissions();
    const { userDetail } = useUserDetail();
    const filterRef = useRef(defaultFilter);
    const { page_no, pagesize } = filterRef.current;
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [moveToList, setMoveToList] = useState([]);
    const [updateTicketTrigger] = useUpdateApprovalMutation();
    const [getTripcheckpointTrigger] = useGetTripcheckpointMutation();
    const { data, isLoading, refetch, isFetching } = useGetApprovalListingQuery(filterRef.current, { refetchOnMountOrArgChange: true });
    let { data: approvalList, tableHeader, count, } = data || { data: [] };

    let header = ["S.No", ...(tableHeader || []).map((_) => _.label), "Action"];
    let actionHandlers = {};
    actionHandlers.approve = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "approvalStatus": "Approved",
            "module": "accessManagement"
        }
        updateTicketTrigger({ ...payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    toastMessage(true,`Trip is updated successfully`);
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })
        // console.log('approve clicked', payload);
    };
    actionHandlers.reject = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;

        const payload = {
            "reference_id": [_id],
            "approvalStatus": "Rejected",
            "isRejected": true,
            "module": "accessManagement"
        }
        updateTicketTrigger({ ...payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    toastMessage(true, `Trip is updated successfully`);
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })
        // console.log('reject clicked', payload);
    };
    actionHandlers.hold = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "approvalStatus": "Hold", // will follow the format moveTo-checkpointStatus,
            "module": "accessManagement"
        }
        updateTicketTrigger({ ...payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    toastMessage(true, `Trip is updated successfully`);
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })
        // console.log('hold clicked', payload);
    };
    actionHandlers.moveTo = (index) => {
        const entry = approvalList[index];
        const { _id } = entry;


        getTripcheckpointTrigger({ "tripId": _id }).unwrap()
            .then((res) => {
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    const checkpointList = res.data;
                    setMoveToList(checkpointList.map((checkpoint) => ({
                        label: checkpoint.displayName,
                        value: checkpoint.checkpointNumber
                    })));
                }
            })
            .catch((err) => {
                toastMessage(false, 'Error fetching trip checkpoint data');
                console.log('error fetching trip checkpoint data',
                    err);
            })


        // console.log('moveto clicked', entry);
    };

    const handleChildMenuClick = (item) => {
        const entry = approvalList[item.index];
        const { _id } = entry;
        const payload = {
            "reference_id": [_id],
            "approvalStatus": `moveTo-${item.label}`, // will follow the format moveTo-checkpointStatus,
            "toCheckpoint": item.value,
            "module": "accessManagement"
        }

        // console.log('moveTo-secondmenu clicked', payload);

        updateTicketTrigger({ ...payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    toastMessage(true, `Trip is updated successfully`);
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })
    }

    const handleMultiAction = (action) => {
        let payload;
        if (action == 'approve') {
            payload = {
                "reference_id": selectedTickets,
                "approvalStatus": "Approved",
                "module": "accessManagement"
            }
        }
        if (action == 'reject') {
            payload = {
                "reference_id": selectedTickets,
                "approvalStatus": "Rejected",
                "isRejected": true,
                "module": "accessManagement"
            }
        }
        if (action == 'hold') {
            payload = {
                "reference_id": selectedTickets,
                "approvalStatus": "Hold", // will follow the format moveTo-checkpointStatus,
                "module": "accessManagement"
            }
        }
        // console.log('handleMultiAction', action, payload);
        updateTicketTrigger({ ...payload }).unwrap()
            .then((res) => {
                // console.log(res);
                if (res.error) {
                    toastMessage(false, res.message);
                } else {
                    setSelectedTickets([])
                    if(res?.additionalSuccessMessage){
                        const {pass, fail} = CheckResponse(res.additionalSuccessMessage)
                        toastMessage(true, `${pass} Trip is updated ${fail ? `${fail} Trip is failed to update` : ``}`);
                    }else{
                        toastMessage(true, `Trip is ${res.message}`);
                    }
                    
                }
            })
            .catch((err) => {
                console.log('error updating approval', err);
            })
    }

    const handleTrip = (ticket) => {
        // console.log('handleTrip', ticket);
        setSelectedTickets((prev) => {
            const index = prev.findIndex((t) => t === ticket);
            if (index === -1) {
                return [...prev, ticket];
            } else {
                return prev.filter((t) => t !== ticket);
            }
        });
    }

    const rows = approvalList?.map((d, i) => {
        let row = [];
        row.push(
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                <Checkbox
                    disabled={(['Cancelled','Terminated'].includes(d.trip_status)) ? true : ["Rejected", "Approved"].includes(d.approvalStatus) ? true : false}
                    checked={selectedTickets.includes(d._id) ? true : false}
                    onChange={() => handleTrip(d._id)}
                    name={"trip_id"}
                />
                {((page_no - 1) * pagesize + i + 1)}
            </div>
        )
        for (let item of tableHeader) {
            row.push(tCell(item.type === "date" ? formatDate(findDeepValue(d, item.name.split("."))) : findDeepValue(d, item.name.split("."))));
        }
        let _action = { ...actionHandlers };
        const ActionStatus = () => {
            const term = /terminated/i;
            const canc = /cancelled/i;
            if (term.test(d.trip_status) || canc.test(d.trip_status)) {
                if (/approved/i.test(d.approvalStatus)) {
                    return <span className="status green">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                } else {
                    return <span className="status red">
                        {capitalizeCamelCase(removeSpecialChars('Rejected'))}
                    </span>
                }
            }
            else if (d.approvalStatus === "Pending" && !d.hasOwnProperty('isApproved')) {
                return <ActionMenu id={i} handlers={_action} childMenuOpen={['moveTo']} childMenu={moveToList} handleChildMenuClick={(id) => handleChildMenuClick(id)} />; // Component to show all 4 buttons
            } else if (d.approvalStatus === "Approved" && d.isApproved === true) {
                return (
                    <span className="status green">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                ) // Component to just show status
            } else if (d.approvalStatus.includes("moveTo")) {
                delete _action.hold;
                delete _action.moveTo;
                return <ActionMenu id={i} handlers={_action} />; // Component to show only approve and reject buttons
            } else if ((d.approvalStatus === "Rejected" && d.isApproved === false)) {
                return (
                    <span className="status red">
                        {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                    </span>
                ) // Component to just show status
            } else if (d.approvalStatus.includes("Hold")) {
                delete _action.hold;
                return <ActionMenu id={i} handlers={_action} childMenuOpen={['moveTo']} childMenu={moveToList} handleChildMenuClick={(id) => handleChildMenuClick(id)} />
                // return (
                //     <span className="status yellow">
                //         {capitalizeCamelCase(removeSpecialChars(d.approvalStatus))}
                //     </span>
                // ) // Component to just show status
            }
        }

        row.push(ActionStatus());
        return row;
    });

    const onPageChange = (page_no) => {
        filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
        refetch(filterRef.current);
    };
    const onPageSizeChange = (e) => {
        const { value } = e.target;
        filterRef.current = { ...filterRef.current, pagesize: value };
        refetch(filterRef.current);
    };

    if (isFetched && userPermissions && Object.keys(userPermissions).length && userDetail && !(userDetail?.userRole == "bayOperator" || userPermissions?.manageApprovals?.value)) return <Navigate to="/not-authorized" replace />;
    // console.log('api response', approvalList);
    return (
        <div className="contentpanel">
            <div className="cust-row flex-algn-cent">
                <div className="cust-col-5">
                    <h1 className="title">Approval Management</h1>
                </div>
            </div>
            <hr className="bgg" />
            {/* <div className="cust-row flex-algn-cent">
                <Genericfilter
                    filterFields={filterFields}
                    onFilterChange={(filter) => handleFilterChange(filter)}
                />
            </div> */}
            {selectedTickets.length > 0 && <div style={{ display: 'flex', justifyContent: 'start', gap: "20px", marginBottom: "20px" }}>
                <Button
                    onClick={() => handleMultiAction('approve')}
                    text={'Approve'}
                    style={{ ...buttonClasses.lynkitGreenFill, width: "100px" }}
                />
                <Button
                    onClick={() => handleMultiAction('reject')}
                    text={'Reject'}
                    style={{ ...buttonClasses.lynkitRedFill, width: "100px" }}
                />
                <Button
                    onClick={() => handleMultiAction('hold')}
                    text={'Hold'}
                    style={{ ...buttonClasses.lynkitYellowFill, width: "100px" }}
                />
            </div>}
            <div className="main_content">
                <GenericTable
                    header={header}
                    rows={rows}
                    pageCount={count}
                    pageNo={filterRef.current.page_no}
                    limit={filterRef.current.pagesize}
                    onPageChange={onPageChange}
                    onPageSizeChange={onPageSizeChange}
                    isLoading={isFetching}
                />
            </div>
        </div>
    )
}


function CheckResponse(errorArr){
    const res = {pass : 0, fail : 0}
    errorArr.forEach((ele, index)=> {
        if(!ele.error){
            res['pass'] = res['pass'] + 1
        }else{
            res['fail'] = res['fail'] + 1
        }
    })
    
    return res
}
