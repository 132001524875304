import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AddRemove, Button, DataNotFound, Loader } from "../utils";
import { CustomInput } from "./Utils";
import { useTemplateDetail } from "../../services/hooks";
import { customAPI, debounce, base64Convert } from "../../services/functions";
import { buttonClasses, textClasses } from "../utils/theme";
import { InputLabel, Typography } from "@mui/material";

export default function DynamicFormRender({
	templateId,
	templatePayload,
	checkoutData = null,
	checkpointId,
	onClose,
	isSaveDraft = false,
	onSubmit,
	extraClass = "",
	btnText = "Back",
	btn2Text = "Submit",
	isSubmitDisabled = false,
	updateData = null,
}) {
	const { templateDetail, isLoading, isSuccess, error: templateDetailError } = useTemplateDetail(templatePayload ? templatePayload : { id: templateId });

	const autoFillOnFocus = "";

	const [attributes, setAttributes] = useState([]);
	// console.log(attributes)
	const [errors, setErrors] = useState({});
	const isInitialLoadRef = useRef(true);
	useEffect(() => {
		if (isSuccess && templateDetail?.attributes && !attributes.length) {
			if (!!updateData) {
				let updatedAttrs = templateDetail.attributes.map((attr) => {
					return { ...attr, value: updateData[attr.name || attr._id] };
				});
				setAttributes(updatedAttrs);
			} else {
				setAttributes(templateDetail?.attributes);
			}
			// console.log("field effect");
		}
	}, [isSuccess, templateDetail, updateData]);

	useEffect(() => {
		// console.log({attributes})
		const vehicleField = attributes.find((_) => _._id == "vehicleNumber");
		if (!vehicleField || !isInitialLoadRef.current || !attributes?.length || !vehicleField?.values?.length || !checkoutData) return;

		const { vehicleNumber } = checkoutData;
		// handleInputChange(vehicleField, vehicleNumber);
		handleDropdownChange({ ...vehicleField, value: vehicleNumber });
		// console.log(vehicleField);
		// console.log({ ...vehicleField, value: vehicleNumber });
		isInitialLoadRef.current = false;
	}, [attributes, checkoutData]);

	useEffect(() => {
		const tripField = attributes.find((_) => _._id == "tripId");
		if (!tripField || !isInitialLoadRef.current || !attributes?.length || !tripField?.values?.length || !checkoutData) return;
		const { trip_counter } = checkoutData;
		setInitialValue(tripField, trip_counter);
		isInitialLoadRef.current = false;
	}, [attributes, checkoutData]);

	const setInitialValue = (field, inputValue = "") => {
		const { _id, api, apiMethod, apiParameters, apiDataReturnKey = "data", apiBindingKey, apiBindingValue, suffix = "", extraValue = [], domain } = field;
		let payload;
		if (apiMethod.toLowerCase() == "get") {
			payload = apiParameters?.map((_) => `${encodeURIComponent(_.label)}=${encodeURIComponent(_.value)}`).join("&");
		} else {
			payload = {};
			apiParameters?.forEach((_) => {
				payload[_.label] = _.value == "onSearch" ? inputValue : _.value == "checkpointId" ? checkpointId : _.value;
			});
		}
		// console.log(payload);
		customAPI(payload, api, apiMethod, domain).then((res) => {
			if (!res.error) {
				let _data = res[apiDataReturnKey] || [];
				updateAttributes(_id, _data);
				handleDropdownChange({ ...field, value: inputValue, values: _data });
			} else {
				handleDropdownChange({ ...field, value: "" });
			}
		});
	};

	function setNestedAttr(attribute, _id, value, key = "values", index = null) {
		// console.log('at nested', attribute);
		if (attribute._id == _id) {
			// group_addon case
			if (index != null && key == "value") {
				let newVal;
				if (Array.isArray(attribute.value)) {
					newVal = [...attribute.value];
					if (newVal[index] == undefined) {
						newVal[index] = "";
					}
				} else {
					newVal = new Array(index + 1).fill(null);
				}
				newVal.splice(index, 1, value);
				return { ...attribute, value: newVal };
			} else {
				return { ...attribute, [key]: value };
			}
		}
		if (attribute.childrens?.length) {
			return { ...attribute, childrens: attribute.childrens.map((attr) => setNestedAttr(attr, _id, value, key, index)) };
		}
		return attribute;
	}
	function updateAttributes(id, data = "", type, index = null) {
		setAttributes((old) => old.map((attr) => setNestedAttr(attr, id, data, type, index)));
	}
	function fetchSourceDropdownData(field, inputValue = "", cb = null) {
		const { _id, api, apiMethod, apiParameters, apiDataReturnKey = "data", apiBindingKey, apiBindingValue, domain, suffix = "", extraValue = [] } = field;
		// console.log('field', field)
		let payload;
		if (apiMethod.toLowerCase() == "get") {
			payload = apiParameters?.map((_) => `${encodeURIComponent(_.label)}=${encodeURIComponent(_.value)}`).join("&");
		} else {
			payload = {};
			apiParameters?.forEach((_) => {
				payload[_.label] = _.value == "onSearch" ? inputValue : _.value == "checkpointId" ? checkpointId : _.value;
			});
		}
		// console.log(payload);
		customAPI(payload, api, apiMethod, domain).then((res) => {
			if (!res.error) {
				let _data = res[apiDataReturnKey] || [];
				updateAttributes(_id, _data);
				updateAttributes(_id, false, "isSearching");
				if (cb) cb(_data);
			} else {
				updateAttributes(_id, []);
				updateAttributes(_id, false, "isSearching");
			}
		});
	}
	function calculateTargetAPIParams(field) {
		let payload;
		const { targetAPIMethod, targetParameters, type, values, value, apiBindingValue } = field;
		// debugger;
		if (targetParameters?.length) {
			let $self = "";
			if (type == "select" && values?.length) {
				$self = values.find((el) => el[apiBindingValue || "value"] == value);
			} else {
				$self = value;
			}

			targetParameters.forEach((el) => {
				if (el.value) {
					if (el.value.includes("$self")) {
						let value = $self;
						let keyArr = el.value.split(".");
						keyArr = keyArr.slice(1);
						for (let key of keyArr) {
							value = value[key];
						}
						appendData(value, el.label);
					} else {
						appendData(el.value == "self" ? value : el.value, el.label);
					}
				}
			});
		}

		return payload;
		function appendData(value, label = "") {
			switch (targetAPIMethod) {
				case "get":
					if (!payload) payload = [];
					payload.push(encodeURIComponent(label) + "=" + encodeURIComponent(value));
					break;
				case "get_URI":
					if (!payload) payload = "";
					payload += encodeURIComponent(value);
					break;
				case "post":
					if (!payload) payload = {};
					payload[label] = value;
					break;
				default:
					break;
			}
		}
	}
	function findNestedAttr(attribute, keys) {
		const [head, ...tail] = keys;

		if (keys.length == 1) {
			if (Array.isArray(attribute)) {
				return attribute.find((_) => _._id == head);
			} else return attribute._id == head ? attribute : null;
		} else if (attribute.childrens) {
			return findNestedAttr(attribute.childrens, tail);
		} else return null;
	}
	function setTarget(element, data, type, value = "") {
		let new_target = element.split("-");
		// console.log({ element, data, type, value });
		updateAttributes(new_target.pop(), data, type);

		// the following is for the case when we have to also set the value of the target dropdown along with just setting its options

		// if (value) {
		// 	let valueCode = target.values.find((el) => el[target.apiBindingKey || "label"] == value);
		// 	if (valueCode) {
		// 		valueCode = valueCode[target.apiBindingValue || "value"];
		// 	}
		// 	target["value"] = valueCode;
		// }
	}

	function calculateTarget(item, data = {}) {
		const { onChangeSet, apiBindingValue, type, values, value, _id } = item;

		for (let key in onChangeSet) {
			let _value;
			let keyArr = [];
			if (onChangeSet[key].includes("$")) {
				keyArr = onChangeSet[key].split(".");
				if (keyArr[0] == "$response") {
					_value = data;
				} else {
					if (type == "select" && values?.length) {
						if (Array.isArray(value)) {
							_value = values.filter((el) => value.find((id) => id == el[apiBindingValue || "value"]));
						} else _value = values.find((el) => el[apiBindingValue || "value"] == value);
					} else {
						_value = value;
					}
				}
				keyArr = keyArr.slice(1);
			} else {
				if (data) {
					_value = data;
				} else {
					// _value = ;
				}
				keyArr = item.onChangeSet[key].split(".");
			}

			for (let key of keyArr) {
				if (_value) {
					if (Array.isArray(_value)) {
						_value = _value.map((_) => _[key]);
						// console.log(_value);
					} else {
						_value = _value[key];
					}
				}
			}

			const [targetAttr] = attributes.map((attr) => findNestedAttr(attr, key.split("-"))).filter(Boolean);
			setTarget(key, _value, "value");

			if (targetAttr?.type == "select" && targetAttr?.dynamic) {
				if (_value != undefined) {
					fetchSourceDropdownData(
						{ ...targetAttr, apiParameters: targetAttr.apiParameters.map((_) => (_.label == "searchBy" ? { ..._, value: targetAttr.apiBindingValue } : _)) },
						_value,
						(values) => {
							handleDropdownChange({ ...targetAttr, value: _value, values });
						}
					);
				}
			}
		}
	}

	const handleDropdownSearch = useCallback(
		debounce((component, inputValue) => {
			fetchSourceDropdownData(component, inputValue);
		}, 500),
		[]
	);

	// index in case of group_addon
	const handleInputChange = async (component, data = "", index = null) => {
		const { _id, searchableDropdown, type } = component;
		// console.log({ _id, component, data });
		if (type != "select") {
			if (type === "file") {
				const filedata = await base64Convert(data.target.files[0]);
				// console.log('File selected', filedata);
				updateAttributes(_id, filedata, "value", index);
			} else {
				updateAttributes(_id, data, "value", index);
			}
		}
		if (searchableDropdown) {
			updateAttributes(_id, [], "values"); //make options array empty
			updateAttributes(_id, true, "isSearching");

			handleDropdownSearch({ ...component }, data);
		}
	};

	// console.log(attributes)
	const handleDropdownChange = (field, index = null) => {
		const { value, bindTarget, setTargetValue, conditionalDependent, targetAPI, targetElement, targetAPIMethod, targetParameters, onChangeSet = {}, _id, domain } = field;

		updateAttributes(_id, value, "value", index);

		// usecase #1 : on source selection -> fill options of a target
		if (bindTarget || (setTargetValue && targetAPI)) {
			let payload = calculateTargetAPIParams(field);
			if (targetAPIMethod == "get") {
				payload = "/?" + payload.join("&");
			} else if (targetAPIMethod == "get_URI") {
				payload = "/" + payload;
			}

			// console.log(payload, "payload", onChangeSet);
			customAPI(payload, targetAPI, targetAPIMethod, domain).then((res) => {
				if (!res.error) {
					// debugger
					if (Object.keys(onChangeSet).length) {
						try {
							calculateTarget(field, res.data);
						} catch (e) {
							console.error(e);
						}
					} else {
						// console.log("onchange", targetElement, payload);
						setTarget(targetElement, res.data, "values"); //targetValue -> to set the value of the targetDropdown

						const newApiParams = Object.keys(payload).map((key) => {
							return { label: key, value: payload[key] };
						});
						// console.log(newApiParams)
						// add the payload to target's api parameters
						// eg : payload = {productCategoryId: "abc"}; and targetElement is "product"
						// so when product is searched, we want "productCategoryId: "abc"" to be passed in the payload
						setAttributes((old) =>
							old.map((attr) => {
								if (attr._id == targetElement) {
									return { ...attr, apiParameters: [...attr.apiParameters, ...newApiParams] };
								}
								return attr;
							})
						);
					}
				} else {
					setTarget(targetElement, [], "values"); //targetValue -> to set the value of the targetDropdown
				}
			});
		}
		// usecase #2: on source selection -> set value of targets
		else if (setTargetValue && onChangeSet) {
			try {
				calculateTarget(field);
			} catch (error) {
				console.error(error);
			}
		}

		// usecase #3: on source selection -> change attributes of target (show/hide, readonly, disbabled, etc)
		if (conditionalDependent?.length) {
			for (let condition of conditionalDependent) {
				const { target: _target, actions, onValue } = condition;
				if (_target) {
					let target = _target?.split("-");
					let tempObj = attributes.find((el) => el._id == target[0]);
					if (target[1]) {
						tempObj = tempObj.childrens?.find((el) => el._id == target[1]);
					}

					if (actions?.length) {
						let conditionCheck = onValue == value;
						for (let act of actions) {
							if (act.key) {
								if (conditionCheck && act.other_dependency?.length) {
									for (let dp of act.other_dependency) {
										let dtarget = dp.target?.split("-");
										let dependentElem = attributes.find((el) => el._id == dtarget[0]);
										if (target[1]) {
											dependentElem = dependentElem.childrens.find((el) => el._id == dtarget[1]);
										}
										if (dependentElem.value != dp.value) {
											condition = false;
											break;
										}
									}
								}
								tempObj[act.key] = conditionCheck ? act.value : act.elseValue;
							}
						}
					}
				}
			}
		}
	};

	if (isLoading) return <Loader size="2rem" />;
	if (templateDetailError) return <DataNotFound />;

	const handleFormSubmit = (evt) => {
		/**
		 * checkout : undefined | true | false
		 * evt : undefined | "draft"
		 */
		let { vehicleId, checkout, ...data } = createPayloadFromAttr(attributes);
		vehicleId = vehicleId ? vehicleId : checkoutData?.vehicleId;

		const payload = { vehicleId, data, checkout: evt == "draft" ? false : checkout ? checkout : checkoutData ? true : false };
		// console.log(payload);
		onSubmit(payload);
	};
	const handleFormValidation = (e, type) => {
		e.preventDefault();

		let errors = {};
		attributes.forEach((attr) => {
			const { value, required, regex, label, _id, type, childrens } = attr;
			if (type == "grid_box") {
				childrens.forEach((child) => {
					const { value, required, regex, label, _id } = child;
					if (required && (value == undefined || (typeof value != "boolean" && value == "") || (Array.isArray(value) && !value.length))) {
						errors[_id] = `${label} is required`;
					} else if (required && Array.isArray(value) && value.every((v) => typeof v == "string") && !value.every(Boolean)) {
						errors[_id] = "Please fill the input value";
					} else if (regex && !new RegExp(regex).test(value)) {
						errors[_id] = `Invalid value for ${label}`;
					}
				});
			}
			// console.log({ value, required, regex, label, _id });
			if (required && (value == undefined || (typeof value != "boolean" && value == "") || (Array.isArray(value) && !value.length))) {
				errors[_id] = `${label} is required`;
			} else if (required && Array.isArray(value) && value.every((v) => typeof v == "string") && !value.every(Boolean)) {
				errors[_id] = "Please fill the input value";
			} else if (regex && !new RegExp(regex).test(value)) {
				errors[_id] = `Invalid value for ${label}`;
			}
		});

		if (Object.keys(errors).length) {
			setErrors(errors);
		} else {
			setErrors({});
			handleFormSubmit(type);
		}
	};
	/**
	 * Our dynamic form template is of the form Array of Objects. Each Object represents a form input.
	 * By Default, we want the form inputs to render SIDE-BY-SIDE, EQUAL-SIZED, and RESPONSIVE.
	 * eg: template = [child, child, child, child] => <child child child child>
	 * However, when an Object of type GRID_BOX, GROUP_ADDON, is encounter, we want it to occupy full width and render its children inside
	 * eg: template = [child, gridbox: [child, child], child, child] => <child>
	 * 																	<child child>
	 * 																	<child child>
	 */
	return (
		<form
			className={`dynamic-form ${extraClass}`}
			onSubmit={handleFormValidation}
			onKeyDownCapture={(e) => {
				if (e.key == "Enter" || e.keyCode == "13") e.preventDefault();
			}}
			style={{ margin: "1.5rem 0 0.5rem 0" }}
		>
			<div
				style={{
					display: "grid",
					gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
					gap: "0.5rem",
				}}
			>
				{attributes.map((component, i) => {
					switch (component.type) {
						case "grid_box":
							return (
								<div
									style={{
										gridColumn: "1/-1",
										display: "grid",
										gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
										gap: "0.5rem",
									}}
									key={i}
								>
									{component.childrens.map((child, j) => {
										return (
											<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={j}>
												<CustomInput
													component={child}
													autoFillOnFocus={autoFillOnFocus}
													errors={errors}
													fetchSourceDropdownData={fetchSourceDropdownData}
													onDropdownChange={(newVal) => {
														handleDropdownChange({ ...child, value: newVal });
													}}
													onInputChange={(e) => {
														// console.log(child._id, e.target?.value);
														if (child.type === "file") {
															// console.log('file input change', e.target.value)
															handleInputChange(child, e);
														} else {
															handleInputChange(child, e.target.value);
														}
														// change the value in the main attributes array
													}}
												/>
												<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>{errors[child._id] || ""}</Typography>
											</div>
										);
									})}
								</div>
							);
						case "group_addon":
							const { label, childrens, hideLabel, required, value = [], _id } = component;
							// value here will be an array of objects
							// each object will contain key-value pairs of the number of input
							// eg: the group addon component should have one Driver dropdwon and one driver mobile number input
							// so the value = [{dr1: "", dr2:""}];
							// dr1 and dr2 are the references to the actual inputs inside the childrens array (_id or name)
							// the reason for having this Value Array is only to keep track of the groups. When the plus icon, is clicked, another entry is added to the value array.
							const onAdd = () => {
								updateAttributes(_id, [...value, value[0]], "value");
								childrens.forEach((child) => {
									// console.log({v: child.value})
									if (!Array.isArray(child.value)) {
										updateAttributes(child._id, [""], "value");
									} else {
										updateAttributes(child._id, [...child.value, ""], "value");
									}
								});
							};
							const onRemove = (index) => {
								let newV = [...value];
								newV.splice(index, 1);
								updateAttributes(_id, newV, "value");
								childrens.forEach((child) => {
									// console.log({v: child.value})
									if (Array.isArray(child.value) && child.value[index] != undefined) {
										updateAttributes(
											child._id,
											child.value.filter((_, i) => index != i),
											"value"
										);
									}
								});
							};
							return (
								<div key={i} style={{ gridColumn: "1/-1", display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }}>
									{!hideLabel ? <InputLabel>{label}</InputLabel> : null}
									{value.map((_, i) => {
										return (
											<div
												key={i}
												style={{
													gridColumn: "1/-1",
													display: "grid",
													gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
													gap: "0.5rem",
												}}
											>
												{childrens.map((child, j) => {
													if (!child.conditionalView || child.conditionSatisfied)
														return (
															<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={j}>
																<CustomInput
																	component={child}
																	index={i}
																	autoFillOnFocus={autoFillOnFocus}
																	errors={errors}
																	fetchSourceDropdownData={fetchSourceDropdownData}
																	onDropdownChange={(newVal) => {
																		handleDropdownChange({ ...child, value: newVal }, i);
																	}}
																	onInputChange={(e) => {
																		// console.log(component._id, e.target?.value, i);
																		if (child.type === "file") {
																			// console.log('file input change', e.target.value)
																			handleInputChange(child, e, i);
																		} else {
																			handleInputChange(child, e.target.value, i);
																		}
																		// change the value in the main attributes array
																	}}
																/>
																<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>
																	{errors[child._id] || ""}
																</Typography>
															</div>
														);
													return null;
												})}
												<AddRemove
													list={value}
													filterMethod={(c, i) => {
														const hasValidValue = childrens.some(
															(child) => child.value && Array.isArray(child.value) && child.value[i] != undefined && child.value[i] != ""
														);
														return !hasValidValue;
													}}
													onAdd={onAdd}
													onRemove={onRemove}
													index={i}
													outerIndex={i}
												/>
											</div>
										);
									})}
								</div>
							);
						default:
							return (
								<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "0.25rem" }} key={i}>
									<CustomInput
										component={component}
										autoFillOnFocus={autoFillOnFocus}
										errors={errors}
										fetchSourceDropdownData={fetchSourceDropdownData}
										onDropdownChange={(newVal) => {
											handleDropdownChange({ ...component, value: newVal });
										}}
										onInputChange={(e) => {
											// console.log(component._id, e.target?.value);
											if (component.type === "file") {
												// console.log('file input change', e.target.value)
												handleInputChange(component, e);
											} else {
												handleInputChange(component, e.target.value);
											}
											// change the value in the main attributes array
										}}
									/>
									<Typography sx={{ ...textClasses.t12n, color: "#e74c3c", textAlign: "left", mt: "5px", ml: "5px" }}>{errors[component._id] || ""}</Typography>
								</div>
							);
					}
				})}
			</div>

			<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem", marginTop: "1rem" }}>
				<Button
					onClick={
						isSaveDraft
							? () => {
									handleFormSubmit("draft");
							  }
							: onClose
					}
					text={btnText}
					style={{ ...buttonClasses.lynkitOrangeEmpty, width: "fit-content" }}
				/>
				<Button type="submit" text={btn2Text} style={{ ...buttonClasses.lynkitOrangeFill, width: "fit-content" }} disabled={isSubmitDisabled} />
			</div>
		</form>
	);
}

function createPayloadFromAttr(attributes = []) {
	let payload = {};
	// console.log(attributes);
	for (let attr of attributes) {
		// console.log(attr);
		const { type, childrens, value, values, multiple, name, _id, conditionalView, conditionSatisfied, apiBindingValue, valueType, extraValue, valueFormat } = attr;

		const key = name || _id;
		if (type == "grid_box") {
			payload = createPayloadFromAttr(childrens);
		} else if (type == "group_addon") {
			if (!conditionalView || (conditionalView && conditionSatisfied)) {
				if (childrens && childrens.length) {
					let childArr = [];
					value?.map((el, index) => {
						let newObj = {};
						for (let child of childrens) {
							if (!child.conditionalView || (child.conditionalView && child.conditionSatisfied)) {
								newObj[child._id] = child.value ? child.value[index] : "";
								if (child.extraValue?.length) {
									for (let val of child.extraValue) {
										if (!child.values) child.values = [];
										let $self = child.values.find((el2) => el2[child.apiBindingValue || "value"] == child.value[index]);
										if ($self && val.valueType == "$self") {
											if (!val.label || val.label == "self") {
												let valObj = getFormattedValue($self, val.valueFormat);
												if (valObj) {
													newObj = { ...newObj, ...valObj };
												}
											} else {
												assignDeep(newObj, val.label, getFormattedValue($self, val.valueFormat));
											}
										} else {
											let label = val.label;
											let temp_obj = newObj;
											if (label.includes("$out")) {
												temp_obj = payload;
												label = label.split(".").slice(1).join(".");
											}
											if ((val.value + "").includes("$self")) {
												let key = val.value.split(".").slice(1).join(".");
												assignDeep(temp_obj, label, getPathValue($self, key));
											} else if ((val.value + "").includes("$data")) {
												let key = val.value.split(".").slice(1).join(".");
												// assignDeep(temp_obj, label, getPathValue(dynamicData, key, index));
											} else {
												assignDeep(temp_obj, label, val.value == "self" ? child.value[index] : val.value);
											}
										}
									}
								}
							}
						}
						childArr.push(newObj);
					});
					assignDeep(payload, key, childArr);
				}
			}
		} else if (type == "file") {
			assignDeep(payload, key, multiple ? values : value);
		} else if (!["heading", "paragraph"].includes(type)) {
			if (!conditionalView || (conditionalView && conditionSatisfied)) {
				let $self; // this refers to the object which holds the entire data of a dropdown selection. For eg: for a vehicle dropdown, $self will refer to the vehicle object from the backend;
				if (values && Array.isArray(values)) {
					if (value && Array.isArray(value)) {
						$self = values.filter((el) => value.includes(el[apiBindingValue || "value"]));
					} else {
						$self = values.find((el) => el[apiBindingValue || "value"] == value);
					}
				}

				//first two are for dropdown selection,

				if ($self && extraValue?.length) {
					for (let val of extraValue) {
						if ($self && val.valueType == "$self") {
							assignDeep(payload, val.label, getFormattedValue($self, val.valueFormat));
						} else {
							if (val.value.includes("$self")) {
								let key = val.value.split(".").slice(1).join(".");
								assignDeep(payload, val.label, getPathValue($self, key));
							} else {
								assignDeep(payload, val.label, val.value);
							}
						}
					}
				} else if ($self && valueType == "$self") {
					assignDeep(payload, key, getFormattedValue($self, valueFormat));
				} else {
					assignDeep(payload, key, value);
				}
			}
		}
	}
	return payload;
}

export function assignDeep(obj, key, value) {
	try {
		if (key && value != undefined) {
			let keys = (key + "").split(".");
			if (keys.length > 1) {
				keys.forEach((key, index) => {
					if (index === keys.length - 1) {
						// assign value on the lowest level
						obj[key] = value;
					} else {
						if (!obj[key]) obj[key] = {};
						obj = obj[key];
					}
				});
			} else {
				// console.log(obj,key,value,"asjifjhasbdf");
				obj[key] = value;
			}
		}
		// console.log(key,"kkkkkkkkk");
	} catch (e) {
		console.log("Error in payload creation", e);
	}
	return true;
}

function getFormattedValue($self, valueFormat) {
	if (!valueFormat.length) return $self;

	if (Array.isArray($self)) {
		return $self.map((val) => getFormatObj(val));
	} else {
		return getFormatObj($self);
	}
	function getFormatObj(obj) {
		let res = {};
		for (let vf of valueFormat) {
			res[vf.label] = getPathValue(obj, vf.value);
		}
		return res;
	}
}

function getPathValue($self, key) {
	let value = $self;
	try {
		let keyArr = key.split(".");
		if (Array.isArray(value)) {
			value = $self.map((val) => getInternalValue(val, keyArr));
		} else {
			value = getInternalValue(value, keyArr);
		}

		function getInternalValue(value, keyArr) {
			for (let key of keyArr) {
				if (value && value[key]) {
					value = value[key];
				} else {
					value = "";
					break;
				}
			}
			return value;
		}
	} catch (e) {
		console.log(e, key, $self);
	}
	return value;
}
