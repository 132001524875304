import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Layout.css";
import LynkIdlogo from "../../LynkIdlogo.png";
import HaierLogo from "../../HaierLogo.png";
import { useEffect, useRef, useState } from "react";
import { getShortText, redirectToTMS, userDetail, capitalizeCamelCase, removeSpecialChars } from "../../services/functions";
import { useCheckpoints, useClickOutside, useDynamicModules, useSocket, useUserDetail, useUserPermissions } from "../../services/hooks";
import {
	DashboardIcon,
	DeviceIcon,
	TagIcon,
	UsersIcon,
	CompanyIcon,
	ProductIcon,
	ProductCategoriesIcon,
	SupplierIcon,
	VehicleIcon,
	TagMappingIcon,
	RoleIcon,
	GateOutIcon,
	GateInIcon,
	AccessIcon,
	BayIcon,
	DummyIcon,
	ReportIcon,
	WorkFlowIcon,
	ManageTripIcon,
	ParkingIcon,
	WeighbridgeIcon,
	ApprovalIcon,
	CheckpointTypeIcon,
	DynamicFormIcon,
	RightArrowIcon,
	ArrowDownIcon,
	DynamicModuleIcon,
	BallotIcon,
} from "../../services/icons";
import { Dialog, Input, Loader, toastMessage } from "../utils/index";
import { useChangePasswordMutation } from "../../api/userSlice";
import { useApplytheme, useCheckInternet, useOfflineTemplate } from "../../services/hooks";
import { socket } from "../../socket";
import { useGetThemeQuery } from "../../api/userSlice";

export default function Layout({ children }) {
	useSocket();
	useApplytheme();
	const { userDetail, isFetching } = useUserDetail();
	// useOfflineTemplate();
	// const isOnline = useCheckInternet();
	let location = useLocation();
	const navigate = useNavigate();
	const windowType = localStorage.getItem("windowType") || false;
	const activeUrl = localStorage.getItem("activeURL") || false;
	if (windowType === "windowOpener" && activeUrl !== location.pathname) {
		navigate("/not-authorized");
	}

	return (
		<div className="layout">
			{windowType === "windowIframe" ? null : <Header />}
			{activeUrl ? null : <Sidebar />}
			<main>{isFetching ? <Loader size="2.5rem" height="80%" /> : children ? children : <Outlet />}</main>
		</div>
	);
}

function Header() {
	const activeUrl = localStorage.getItem("activeURL");
	const { data, refetch, isLoading, isFetching, isSuccess } = useGetThemeQuery(
		{},
		{
			refetchOnMountOrArgChange: true,
		}
	);
	let { data: orgDetail, count, message, error } = data || { data: {} };
	const [Logo, setLogo] = useState(LynkIdlogo);

	useEffect(() => {
		if (orgDetail?.logo) {
			localStorage.setItem("logo", orgDetail?.logo);
			setLogo(orgDetail?.logo);
		}
	}, [orgDetail?.logo]);
	return (
		<header>
			<div className="logo">
				<Link to={activeUrl ? activeUrl : "/login"}>
					<img src={localStorage.getItem("logo") || LynkIdlogo} alt="" className="" />
				</Link>
			</div>
			<Profile />
		</header>
	);
}

function Profile() {
	const { userPermissions } = useUserPermissions();
	const [isMenu, setIsMenu] = useState(false);
	const handleMenuOpen = () => setIsMenu(true);

	const profileRef = useRef();
	useClickOutside(profileRef, () => setIsMenu(false));

	const { userDetail } = useUserDetail();
	const initial = userDetail?.name ? userDetail.name[0].toUpperCase() : "G";
	const name = userDetail?.name ? userDetail?.name.split(" ")[0] : "";
	const onLogOut = () => {
		socket.disconnect();
		localStorage.removeItem("primaryColor");
		localStorage.removeItem("selectedTheme");
		localStorage.removeItem("secretkey");
		localStorage.removeItem("favicon");
		localStorage.removeItem("title");
		// localStorage.removeItem("logo");
		localStorage.removeItem("project");
		window.location.reload();
	};

	const [isChangePass, setIsChangePass] = useState(false);
	const handleChangePassOpen = () => setIsChangePass(true);
	const handleChangePassClose = () => setIsChangePass(false);

	return (
		<>
			<div className="user_panel" ref={profileRef}>
				<button className="profile_btn" onClick={handleMenuOpen}>
					{initial}
				</button>
				{isMenu ? (
					<ul>
						<li className="profile_detail">
							<div className="profile_btn">{initial}</div>
							<div className="profile_info">
								<div className="profile_name">{name || "Guest"}</div>
								<div className="profile_mob">{userDetail?.mobile || "NA"}</div>
							</div>
						</li>
						<li>
							<button onClick={handleChangePassOpen}>Change Password</button>
						</li>
						<li>
							<button onClick={onLogOut}>Log Out</button>
						</li>
					</ul>
				) : null}

				{isChangePass && userPermissions?.changePassword?.value ? <ChangePassword open={isChangePass} onClose={handleChangePassClose} /> : null}
			</div>
		</>
	);
}

function ChangePassword({ open, onClose }) {
	const [formData, setFormData] = useState({
		password: "",
		newPassword: "",
		confirmNewPassword: "",
	});
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const [handleSubmit, { isLoading }] = useChangePasswordMutation();

	const handleFormSubmit = async () => {
		const payload = { ...formData };
		delete payload.confirmNewPassword;
		const {
			data: { success, message },
		} = await handleSubmit(payload);
		if (!success) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
			socket.disconnect();
			localStorage.removeItem("secretkey");
			localStorage.removeItem("primaryColor");
			localStorage.removeItem("selectedTheme");
			localStorage.removeItem("favicon");
			localStorage.removeItem("title");
			localStorage.removeItem("logo");
			localStorage.removeItem("project");
			window.location.reload();
		}
	};
	const handleClose = () => {
		onClose();
	};
	return (
		<Dialog open={open} handleClose={handleClose} handleSubmit={handleFormSubmit} title="Change Password" size="sm" isSubmitDisabled={isLoading}>
			<form className="form-inputs">
				<Input label="Old Password" placeholder="Enter Old Password" name="password" value={formData.password} onChange={handleInput} required />
				<Input label="New Password" placeholder="Enter New Password" name="newPassword" value={formData.newPassword} onChange={handleInput} required />
				<Input label="Confirm Password" placeholder="Confirm New Password" name="confirmNewPassword" value={formData.confirmNewPassword} onChange={handleInput} required />
			</form>
		</Dialog>
	);
}

function Sidebar() {
	const { userPermissions } = useUserPermissions();
	const { userDetail } = useUserDetail();
	const { pathname } = useLocation();
	const [openItems, setOpenItems] = useState([]);
	const [isActiveChild, setIsActiveChild] = useState("");

	const checkpointItems = useCheckpointItems();
	const dynamicModuleItems = useDynamicModuleItems();

	const toggleItem = (index) => {
		setOpenItems([index]);
	};

	const items = [
		{
			title: "Dashboard",
			Icon: DashboardIcon,
			link: "/dashboard",
			permission: userPermissions?.getDashboard?.value,
			current: ["/dashboard"].includes(pathname),
			children: [],
			class: "dashboard-module",
		},
		{
			title: "Dashboard",
			Icon: DashboardIcon,
			link: "/new_dashboard",
			permission: userPermissions?.getDashboardV2?.value,
			current: ["/new_dashboard"].includes(pathname),
			children: [],
			class: "dashboard-module",
		},
		{
			title: "Devices",
			Icon: DeviceIcon,
			link: "/devices",
			permission: userPermissions?.getDevice?.value,
			current: ["/devices", "/devices/add-device"].includes(pathname),
			children: [],
			class: "device-module",
		},
		{
			title: "Tags",
			Icon: TagIcon,
			link: "/tags",
			permission: userPermissions?.getTag?.value,
			current: ["/tags"].includes(pathname),
			children: [],
			class: "tag-module",
		},
		{
			title: "Unregister Tags",
			Icon: TagMappingIcon,
			link: "/unregister-tag",
			permission: userDetail?._id === "65169fbfcc82da0dd8d3aaa0",
			current: ["/unregister-tag"].includes(pathname),
			children: [],
		},
		// {
		// 	title: "Approval Configuration",
		// 	Icon: TagMappingIcon,
		// 	link: "/approval-configuration",
		// 	permission: true,
		// 	current: ["/approval-configuration"].includes(pathname),
		// 	children: [],
		// },
		{
			title: "Users",
			Icon: UsersIcon,
			link: "/users",
			permission: userPermissions?.getUsers?.value,
			current: ["/users"].includes(pathname),
			children: [],
			class: "users-module",
		},
		{
			title: "Roles",
			Icon: RoleIcon,
			link: "/roles",
			permission: userPermissions?.getUserRoles?.value,
			current: ["/roles"].includes(pathname),
			children: [],
			class: "role-module",
		},
		{
			title: "Companies",
			Icon: CompanyIcon,
			link: "/companies",
			permission: userPermissions?.getOrganizations?.value,
			current: ["/companies"].includes(pathname),
			children: [],
		},
		{
			title: "Product Categories",
			Icon: ProductCategoriesIcon,
			link: "/product-categories",
			permission: userPermissions?.getProductCategory?.value,
			current: ["/product-categories"].includes(pathname),
			children: [],
			class: "product-categories-module",
		},
		{
			title: "Products",
			Icon: ProductIcon,
			link: "/products",
			permission: userPermissions?.getProduct?.value,
			current: ["/products"].includes(pathname),
			children: [],
			class: "product-module",
		},
		{
			title: "Suppliers",
			Icon: SupplierIcon,
			link: "/suppliers",
			permission: userPermissions?.getSupplier?.value,
			current: ["/suppliers"].includes(pathname),
			children: [],
			class: "supplier-module",
		},
		{
			title: "Reports",
			Icon: ReportIcon,
			link: "/reports",
			permission: userPermissions?.viewReport?.value,
			current: pathname.includes("/reports"),
			children: [],
			class: "report-module",
		},
		{
			title: "Vehicle Master",
			Icon: VehicleIcon,
			link: "/vehicle-master",
			permission: userPermissions?.getVehicle?.value,
			current: pathname.includes("/vehicle-master"),
			children: [],
			class: "vehicle-module",
		},
		{
			title: "Checkpoints",
			Icon: ReportIcon,
			link: "/create-checkpoint",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/create-checkpoint"),
			children: [],
		},
		{
			title: "Checkpoint Types",
			Icon: CheckpointTypeIcon,
			link: "/checkpoint-types",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/checkpoint-types"),
			children: [],
		},
		{
			title: "WorkFlow",
			Icon: WorkFlowIcon,
			link: "/workflow",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/workflow"),
			children: [],
		},
		{
			title: "Manage Trips",
			Icon: ManageTripIcon,
			link: "/manage-trip",
			permission: userPermissions?.manageTrip?.value,
			current: pathname.includes("/manage-trip"),
			children: [],
		},
		{
			title: "Approval Management",
			Icon: ApprovalIcon,
			link: "/approval-management",
			permission: userDetail?.userRole == "bayOperator" || userPermissions?.manageApprovals?.value,
			current: pathname.includes("/approval-management"),
			children: [],
		},
		{
			title: "Dynamic Form",
			Icon: DynamicFormIcon,
			link: "/templates",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/templates"),
			children: [],
		},
		{
			title: "Dynamic Module",
			Icon: DynamicModuleIcon,
			link: "/dynamic-module",
			permission: userDetail?.userType == "superAdmin",
			current: pathname.includes("/dynamic-module"),
			children: [],
		},
		...dynamicModuleItems,
		...checkpointItems,
	];

	return items?.length ? (
		<aside onMouseLeave={() => setOpenItems([])}>
			<ul>
				{items?.map((item, i) => {
					if (!item.permission) return null;
					const isOpen = openItems.includes(i);
					return (
						<li key={i}>
							{item.children.length > 0 ? (
								<Link className={Boolean(item.children.find((ele) => ele.title === isActiveChild)) ? "navlink childActive" : "navlink"} onClick={() => toggleItem(i)}>
									<item.Icon fontSize="small" className="sidebar_icon" />
									<span className={`sidebar_title ${item.class ? item.class : ""}`}>{item.title}</span>
									{item.children.length > 0 && !isOpen ? (
										<RightArrowIcon fontSize="small" className="sidebar_icon arrowIcon" />
									) : (
										<ArrowDownIcon fontSize="small" className="sidebar_icon" />
									)}
								</Link>
							) : (
								<NavLink
									to={item.link}
									state={item.state}
									className={({ isActive }) => (isActive ? "navlink active" : "navlink")}
									onMouseEnter={() => toggleItem(i)}
									onClick={() => setIsActiveChild("")}
								>
									<item.Icon fontSize="small" className="sidebar_icon" />
									<span className={`sidebar_title ${item.class ? item.class : ""}`}>{item.title}</span>
								</NavLink>
							)}

							{isOpen &&
								item.children?.map((child, indx) => {
									if (!child.permission) return null;
									return (
										<NavLink
											key={indx}
											to={child.link}
											state={child.state}
											style={{ paddingLeft: "3rem" }}
											className={({ isActive }) => (isActive ? "navlink active" : "navlink")}
											onClick={() => setIsActiveChild(child.title)}
										>
											<span className="sidebar_title">{child.title}</span>
										</NavLink>
									);
								})}
						</li>
					);
				})}
			</ul>
		</aside>
	) : null;
}

function useCheckpointItems() {
	const { pathname } = useLocation();
	const { checkpoints, getCheckpoints } = useCheckpoints();
	const checkpointItems = categoriesCheckpointItems(checkpoints);
	const { userDetail } = useUserDetail();

	useEffect(() => {
		if (!userDetail || userDetail.userType == "superAdmin") return;
		getCheckpoints();
	}, [userDetail]);

	if (userDetail?.userType == "superAdmin") return [];

	const checkpointMenu = Object.keys(checkpointItems).map((ele, index) => {
		const iconMap = {
			gateOut: GateOutIcon,
			gateIn: GateInIcon,
			access: AccessIcon,
			bay: BayIcon,
			parking: ParkingIcon,
			weighbridge: WeighbridgeIcon,
		};
		if (checkpointItems[ele].length > 0) {
			return {
				title: capitalizeCamelCase(removeSpecialChars(ele)),
				Icon: iconMap[ele] || DummyIcon,
				link: null,
				permission: true,
				current: null,
				class: "checkpoint-module",
				children: checkpointItems[ele].map((_) => {
					const iconMap = {
						gateOut: GateOutIcon,
						gateIn: GateInIcon,
						access: AccessIcon,
						bay: BayIcon,
						parking: ParkingIcon,
						weighbridge: WeighbridgeIcon,
					};
					return {
						title: _.displayName,
						Icon: iconMap[_.checkpointType] || DummyIcon,
						link: `/checkpoints/${_.checkpointType}/${_.checkpointNumber}`,
						permission: true,
						current: [`/checkpoints/${_.checkpointType}`].includes(pathname),
						children: [],
					};
				}),
			};
		} else {
			null;
		}
	});
	return checkpointMenu || [];
}
function categoriesCheckpointItems(data) {
	// Define an empty object to store the categorized data
	const categorizedData = {};

	// Iterate through the array and group objects by checkpointType
	data.forEach((item) => {
		const { checkpointType } = item;
		if (!categorizedData[checkpointType]) {
			// If the category doesn't exist, create an array for it
			categorizedData[checkpointType] = [item];
		} else {
			// If the category already exists, push the item to the existing array
			categorizedData[checkpointType].push(item);
		}
	});

	return categorizedData;
}
function useDynamicModuleItems() {
	const { pathname } = useLocation();
	const { userDetail } = useUserDetail();
	const { userPermissions } = useUserPermissions();
	const { dynamicModules } = useDynamicModules({});
	return (
		dynamicModules.map((_) => {
			const canGet = userDetail?.userType == "superAdmin" || !!userPermissions[`module-get${_.moduleName.replaceAll(" ", "")}`]?.value;
			return {
				title: _.moduleName,
				Icon: BallotIcon,
				link: `/module/${_.moduleName.replaceAll(" ", "")}`,
				state: { data: _ },
				permission: canGet,
				current: [`/module/${_.moduleName}`].includes(pathname),
				children: [],
			};
		}) || []
	);
}
