import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Button, GenericTable, tCell } from "../utils";
import dayjs from "dayjs";
import { findDeepValue, removeSpecialChars, capitalizeCamelCase, formatDate } from "../../services/functions";
import { useSocketCheckpointForDashboard } from "../../services/hooks";
import { useVehicleDetailsMutation } from "../../api/vmsSlice";
import { useCheckpointData } from "../../services/hooks";
import theme, { buttonClasses } from "../utils/theme";
import { PopOver } from "../utils";
import { DatePicker, SearchDropdown } from "../utils";
import { CheckIcon } from "../../services/icons";
import { Grid } from "@mui/material";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

export default function AllCheckpointData({ forwordLink }) {
	const navigate = useNavigate();
	const filterRef = useRef(defaultFilter);
	const [anchorEl, setAnchorEl] = useState(null);
	const [filter, setFilter] = useState({});
	const { checkpointEvents } = useSocketCheckpointForDashboard();
	const { page_no, pagesize } = filterRef.current;
	const { trigger, checkpointData, isLoading: isCheckpointDataLoading, tableHeader, count } = useCheckpointData(filterRef.current);
	const [vehicleDetailTrigger, { data: vehicleDetail, isLoading: vehicleDetailLoading }] = useVehicleDetailsMutation();

	const fetchTable = (index) => {
		// console.log('fetching table for: ', index, dataSet[index])
		filterRef.current = { ...filterRef.current, templateId: "default-dashboard-listing", checkpointId: "all", type: "arrived" };
		trigger(filterRef.current);
	};

	useEffect(() => {
		fetchTable();
	}, []);

	const handleChange = (value, key) => {
		// console.log("filter on change", { [key]: value });
		setFilter((previous) => {
			return { ...previous, [key]: value === null ? "" : value };
		});
	};

	const handleSubmit = () => {
		if (Object.keys(filter)?.length > 0) {
			if (Object.keys(filter).includes("start") || Object.keys(filter).includes("end")) {
				filterRef.current = { ...filterRef.current, ...{ start: filter?.start || "", end: dayjs(filter?.end).add(1, "day").toDate().toISOString() || "" } };
			}
			if (Object.keys(filter).includes("productName") || Object.keys(filter).includes("productCategory")) {
				filterRef.current = {
					...filterRef.current,
					...{
						searchBy: filter?.productName ? ["product.productName"] : filter?.productCategory ? ["productCategory.productCategory"] : [],
						search: filter?.productName ? filter.productName : filter?.productCategory ? filter.productCategory : [],
					},
				};
			}
		} else {
			filterRef.current = { ...filterRef.current, ...{ searchBy: [], search: [], start: "" } };
		}
		// console.log('filter on submit', filterRef.current, filter);

		trigger(filterRef.current);
	};

	const onhandleClear = () => {
		setFilter({});
		filterRef.current = { ...filterRef.current, ...{ searchBy: [], search: [], start: "" } };
		trigger(filterRef.current);
	};

	const handlePopOver = (event, id) => {
		setAnchorEl(event.currentTarget);
		// console.log("event", id);
		vehicleDetailTrigger({ vehicleEntryId: id });
	};

	const handleClosePopOver = () => {
		setAnchorEl(null);
	};

	const openPopOver = Boolean(anchorEl);
	const popOverId = open ? "simple-popover" : undefined;

	let header = ["S.No", ...(tableHeader || []).map((_) => _.label)];
	const filteredCheckpointData = checkpointData?.length > 0 ? checkpointData.filter((item2) => !checkpointEvents.some((item1) => item1._id === item2._id)) : []; // here filter socket data from api data.
	let rows =
		(checkpointData &&
			checkpointData.length > 0 &&
			[...checkpointEvents, ...filteredCheckpointData].map((data, index) => {
				let row = [tCell(index + 1)];
				for (let item of tableHeader) {
					if (item.name === "vehicleNumber") {
						row.push(tCell(findDeepValue(data, item.name.split(".")), theme.themeOrange, "pointer", handlePopOver, data._id));
					} else {
						row.push(tCell(item.type === "date" ? formatDate(findDeepValue(data, item.name.split("."))) : findDeepValue(data, item.name.split("."))));
					}
				}
				const formattedStatus = capitalizeCamelCase(removeSpecialChars(data.approvalStatus));
				const approvalStatus = (
					<span className={`status ${!data.hasOwnProperty("isApproved") ? "Yellow" : data.isApproved ? "green" : "red"}`} title={formattedStatus}>
						{formattedStatus}
					</span>
				);
				let submittedText = "";
				if (filterRef.current?.type === "pending") {
					submittedText = "";
				} else {
					submittedText = tCell(<span className="status green">Submitted</span>);
				}
				return row;
			})) ||
		[];

	return (
		<>
			<div className="table-header-container">
				<div>
					<h5>All Checkpoint Data</h5>
				</div>
				<div style={{ width: "125px" }}>
					<Button
						style={buttonClasses.lynkitBlackEmpty}
						text={"View Details"}
						onClick={() => {
							navigate(forwordLink);
						}}
					/>
				</div>
			</div>
			<Grid container spacing={"1em"} sx={{ marginBottom: "0.75em" }}>
				<Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
					<SearchDropdown
						id={"dashboard-product-filter-dropdown"}
						name="productfilter"
						options={[
							{ label: "Product Name", value: "productName" },
							{ label: "Product Categories", value: "productCategory" },
						]}
						label={"Product / Product Categories"}
						placeholder={"Enter value"}
						value={filter}
						Debounce={true}
						onChange={(value, key) => handleChange(value, key)}
						onhandleClear={onhandleClear}
					/>
				</Grid>
				<Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
					<DatePicker name={"start"} maxDATE={dayjs()} value={filter?.start || null} required onChange={(val) => handleChange(val.toISOString(), "start")} label={"Start Date"} />
				</Grid>
				<Grid item xl={2} lg={2} md={2} sm={3} xs={12}>
					<DatePicker
						name={"start"}
						minDATE={filter?.start ? dayjs(filter?.start) : null}
						maxDATE={dayjs()}
						value={filter?.end || null}
						required
						onChange={(val) => handleChange(val.toISOString(), "end")}
						label="End Date"
					/>
				</Grid>
				<Grid item xl={1} lg={1} md={1} sm={3} xs={12}>
					<Button
						testId={"Clear"}
						text={"Clear"}
						style={buttonClasses.outlined}
						// disabled={false}
						onClick={() => onhandleClear()}
					/>
				</Grid>
				<Grid item xl={1} lg={1} md={1} sm={3} xs={12}>
					<Button
						testId={"Submit"}
						text={"Submit"}
						style={buttonClasses.outlined}
						// disabled={false}
						onClick={() => handleSubmit()}
					/>
				</Grid>
			</Grid>

			<GenericTable
				header={header}
				rows={rows}
				TableData={[...checkpointEvents, ...filteredCheckpointData]}
				// pageCount={count}
				// pageNo={page_no}
				// limit={pagesize}
				// onPageChange={onPageChange}
				// onPageSizeChange={onPageSizeChange}
				// isLoading={isCheckpointDataLoading}
			/>
			<PopOver id={popOverId} open={openPopOver} anchorEl={anchorEl} onClose={handleClosePopOver}>
				{vehicleDetailLoading ? (
					<p>loading.....</p>
				) : vehicleDetail?.data?.statusHistory ? (
					<div className="dashboard-popover-container">
						<div className="status-history">
							<span>Status History</span>
						</div>
						{vehicleDetail?.data?.statusHistory.map((ele, index) => (
							<div className="dashboard-status-card" key={ele._id}>
								<div className="dashboard-status-card-uppper">
									<div className="dashboard-status-card-uppper-left">
										<div className="dot">
											<CheckIcon fontSize={"10px"} htmlColor={"#fff"} />
										</div>
										<div className="arrow"></div>
									</div>
									<div className="dashboard-status-card-uppper-right">
										<div className="content-header">
											<span>{ele.displayName}</span>
										</div>
										<div className="content-detail">
											<span className="title">Gate-In :</span>
											<span className="detail">{formatDate(ele.inTime)}</span>
											<br />
											<span className="title">Gate-Out : </span>
											<span className="detail">{formatDate(ele.outTime)}</span>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				) : null}
			</PopOver>
		</>
	);
}
