import { useEffect, useState, useRef } from "react";
import { useGetReportFieldsMutation, useGetReportMutation } from "../../api/api";
import { Button, Checkbox, DateTimePicker, Dropdown, Loader, toastMessage } from "../utils";
import dayjs from "dayjs";
import { buttonClasses } from "../utils/theme";
import { useGetVehiclesQuery } from "../../api/vmsSlice";
import { debounce } from "../../services/functions";

import "./Report.css";
import { useCheckpointWithExtra } from "../../services/hooks";
import { Grid, TablePagination, useMediaQuery } from "@mui/material";
import { TablePaginationActions } from "../utils/GenericTable";

// const DEFAULT_REPORT_TYPE = "combined";
const reportTypeMap = {
	Report: { type: "vehicleStats" },
};

const defaultFilter = {
	startdate: "",
	enddate: "",
	pagesize: 10,
	page_no: 1,
};
export default function Reports() {
	// hard coding for now, might change when there are multiple report types
	const reportType = "Report";

	const { fieldMapping = [] } = useReportFieldMapping(reportTypeMap[reportType]);
	const { checkpointOptions } = useCheckpointWithExtra({ forReport: true });
	const { getReport, clearData } = useGetReport();
	const { fetchReportPdf } = useGetReportPdf();

	const [screen, setScreen] = useState("fieldMapping"); // fieldMapping | filterSelection | downloadReport
	const handleScreenChange = (type = "fieldMapping") => {
		setScreen(type);
		if (type === "filterSelection") {
			setFilter((old) => {
				return {
					...filter,
					startdate: "",
					enddate: "",
				};
			});
		}
	};

	const [selectedFields, setSelectedFields] = useState([]);
	const handleFieldSelect = (e) => {
		const { name, checked, value } = e.target;
		let nextSelected = [...selectedFields];
		if (name == "selectAll") {
			if (checked) {
				nextSelected = fieldMapping
					.filter((ele) => ele.field_name != "Checkpoint Name")
					.map((_) => ({
						label: _.field_name,
						value: _.field_id,
					}));
			} else {
				nextSelected = [];
			}
		} else {
			if (checked) {
				if (name === "Checkpoint Name") {
					const index = nextSelected.findIndex((ele) => ele.label === "Checkpoint Detail");
					if (index > 0) {
						nextSelected.splice(index, 1);
					}
					nextSelected.push({ label: name, value });
				} else if (name === "Checkpoint Detail") {
					const index = nextSelected.findIndex((ele) => ele.label === "Checkpoint Name");
					if (index > 0) {
						nextSelected.splice(index, 1);
					}
					nextSelected.push({ label: name, value });
				} else {
					nextSelected.push({ label: name, value });
				}
			} else {
				nextSelected = nextSelected.filter((_) => _.value != value);
			}
		}
		// console.log("nextSelected", nextSelected);
		setSelectedFields(nextSelected);
	};

	const [filter, setFilter] = useState(defaultFilter);

	const handleFilterChange = (e, _name) => {
		let name = _name ? _name : e.target.name;
		let value = e?.$d ? new Date(e.$d).toISOString() : _name ? (Array.isArray(e) ? e.map((_) => _.value) : e?.value || "") : e.target.value;
		// console.log('filter-change', name, value);
		if (name == "checkpointsFrom" || name == "checkpointsTo") {
			setFilter((old) => ({ ...old, [name]: value, viaCheckpoints: [] }));
			if (name === "checkpointsTo" && filter.checkpointsFrom) {
				const ViaOption = checkpointOptions?.filter((chk) => chk.checkpointNumber < value).filter((chk) => chk.checkpointNumber > filter.checkpointsFrom);
				// console.log('ViaOption', ViaOption);
				setFilter((old) => ({ ...old, [name]: value, viaCheckpoints: ViaOption.map((ele) => ele.checkpointNumber) }));
			}
			if (name === "checkpointsFrom" && filter.checkpointsTo) {
				const ViaOption = checkpointOptions?.filter((chk) => chk.checkpointNumber < filter.checkpointsTo).filter((chk) => chk.checkpointNumber > value);
				setFilter((old) => ({ ...old, [name]: value, viaCheckpoints: ViaOption.map((ele) => ele.checkpointNumber) }));
			}
		}
		if (name === "productCategory") {
			setFilter((old) => ({ ...old, [name]: value, products: [] }));
		} else if (name === "vehicleId" || name === "assetsId") {
			setFilter((old) => ({ ...old, [name]: value, [`${name}Name`]: e?.label || "" }));
		} else {
			setFilter((old) => ({ ...old, [name]: value }));
		}
	};

	function handleFormValidation(arg = {}) {
		const { type = "html" } = arg;
		// console.log('filter-validation', filter)

		let errorMsg = "";
		// if (!filter.checkpoints?.length) errorMsg = "Please select checkpoints";
		// else if (!filter.startdate) errorMsg = "Please select start date";
		// else if (!filter.enddate) errorMsg = "Please select end date";

		if (!filter.checkpointsFrom && filter.checkpointsTo) {
			errorMsg = "Please select checkpoints";
		}
		if (filter.checkpointsFrom && !filter?.checkpointsTo) {
			errorMsg = "Please select checkpoints";
		}

		const From = checkpointOptions?.filter((chk) => filter.checkpointsFrom == chk.checkpointNumber);
		const To = checkpointOptions?.filter((chk) => filter.checkpointsTo == chk.checkpointNumber);
		const difference = To[0]?.checkpointNumber - From[0]?.checkpointNumber;
		// console.log('difffff', difference, To, From)
		if (difference < 0) {
			errorMsg = "Selected checkpoints are incorrect";
		}
		if (!filter.startdate) errorMsg = "Please select start date";
		else if (!filter.enddate) errorMsg = "Please select end date";

		if (errorMsg) {
			toastMessage(false, errorMsg);
		} else {
			const payload = makePayload(type);
			// console.log(payload, type);
			switch (type) {
				case "html":
					handleGetReport(payload);
					break;
				case "excel":
					handleExcelDownload(payload);
					break;
				case "pdf":
					handlePdfDowload(payload);
					break;
			}
		}
	}
	function makePayload(download_type = "html") {
		return {
			type: reportTypeMap[reportType]?.type,
			enddate: filter.enddate,
			startdate: filter.startdate,
			checkpointFrom: filter.checkpointsFrom,
			checkpointTo: filter.checkpointsTo,
			selected_fields: selectedFields?.map((_) => _.value),
			viaCheckpoints: filter.viaCheckpoints,
			productCategory: filter.productCategory,
			products: filter.products,
			searchBy: "vehicleNumber",
			search: filter?.vehicleIdName,
			// vehicleId: filter.vehicleId,
			download_type,
			deviceId: sessionStorage.getItem("deviceId") || "", // to send excel report / updates to a specific device.
			pagesize: filter.pagesize,
			page_no: filter.page_no,
		};
	}
	const handleGetReport = (payload) => {
		getReport(payload);
	};

	const handleExcelDownload = async (payload) => {
		getReport(payload);
	};
	const handlePdfDowload = async (payload) => {
		const {
			data: { error, message, data },
		} = await fetchReportPdf(payload);

		if (error) {
			toastMessage(false, message);
		} else {
			let byteCharacters = atob(data.toString("base64"));
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);

			let blob = new Blob([byteArray], { type: "application/pdf" });

			let url = URL.createObjectURL(blob);

			let link = document.createElement("a");
			link.href = url;
			link.download = `vehicle_report_${new Date()}.pdf`;

			link.click();

			URL.revokeObjectURL(url);
		}
	};

	const handleDataReset = () => {
		clearData();
		setFilter(defaultFilter);
	};
	let ui;
	switch (screen) {
		case "fieldMapping":
			ui = <FeildMapping selectedFields={selectedFields} onFieldSelect={handleFieldSelect} onScreenChange={handleScreenChange} />;
			break;
		case "filterSelection":
			ui = <FilterSelection reportType={reportType} onScreenChange={handleScreenChange} onFilterChange={handleFilterChange} filter={filter} />;
			break;
		case "downloadReport":
			ui = (
				<DownloadPreview
					reportType={reportType}
					onScreenChange={handleScreenChange}
					onFilterChange={handleFilterChange}
					onSubmit={handleFormValidation}
					filter={filter}
					onReset={handleDataReset}
				/>
			);
			break;
		default:
			ui = <></>;
			break;
	}
	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Report</h1>
				</div>
			</div>
			<hr className="bgg" />
			{ui}
		</div>
	);
}

function FeildMapping({ selectedFields = [], onFieldSelect = () => {}, onScreenChange = () => {} }) {
	const { fieldMapping, isLoading } = useReportFieldMapping();
	const isMobile = useMediaQuery("(max-width:600px)");

	return (
		<>
			<div className="field-mapping-cont" style={{ flexDirection: isMobile ? "column" : "row" }}>
				<section>
					<div className="head">All Fields</div>
					<div className="body">
						<Checkbox
							id="selectAll"
							name="selectAll"
							checked={fieldMapping?.length ? fieldMapping.length - 1 == selectedFields.length : false}
							onChange={onFieldSelect}
							label="Select All"
							value="All"
						/>

						{isLoading ? (
							<Loader height="60%" size="1.75rem" />
						) : (
							fieldMapping?.map((field, index) => {
								const { field_name, field_id } = field;
								return (
									<Checkbox
										key={index}
										id={field_name}
										name={field_name}
										label={field_name}
										value={field_id}
										checked={!!selectedFields.find((_) => _.value == field_id)}
										onChange={onFieldSelect}
									/>
								);
							})
						)}
					</div>
				</section>
				<div className={`field-arrow ${isMobile ? "mobile" : ""}`}></div>
				<section style={{ width: isMobile ? "100%" : "auto" }}>
					<div className="head">Selected Fields</div>
					<div className="body">
						{selectedFields?.map(({ label, value }, index) => {
							return (
								<div key={index} className="field-item">
									<span>{label}</span>
									<i
										className="fa fa-times icon-cross"
										onClick={() =>
											onFieldSelect({
												target: {
													name: label,
													value: value,
													checked: false,
												},
											})
										}
									></i>
								</div>
							);
						})}
					</div>
				</section>
			</div>
			<div className="nav-cont">
				<Button disabled={selectedFields.length ? false : true} text="Next" onClick={() => onScreenChange("filterSelection")} style={buttonClasses.lynkitOrangeFill} />
			</div>
		</>
	);
}

function FilterSelection({ reportType, onScreenChange, onFilterChange, filter }) {
	const [selectedFilter, setSelectedFilter] = useState({
		label: "Vehicle Number",
		value: "vehicleNumber",
	});
	const { checkpointOptions } = useCheckpointWithExtra({ forReport: true });
	const filterRef = useRef({
		page_no: "1",
		pagesize: "25",
		search: filter?.vehicleIdName ? filter?.vehicleIdName : "",
		searchBy: "vehicle",
	});
	const {
		data: vehicleData,
		refetch,
		isLoading: vehicleLoading,
	} = useGetVehiclesQuery(filterRef.current, {
		refetchOnMountOrArgChange: true,
	});

	let { data: vehicles } = vehicleData || { data: [] };

	// console.log('vehicleLoading', filter)
	// const { productCategories, count, isLoading, message, getProdCats } = useProductCategories({});
	// const [getProducts, { data }] = useGetProductsMutation({ fixedCacheKey: "getProducts" });
	// let { data: products } = data || { data: [] };
	const handleProduct = (category) => {
		// console.log('category', category.value);
		getProducts({ productCategoryId: category.value });
	};
	// console.log('filter-checkpoint', filter)
	// let checkpointValues = checkpointOptions?.filter((chk) => filter.checkpoints?.find((fchk) => fchk == chk.value));
	let vehicleValue = filter?.vehicleId ? vehicles?.filter((veh) => filter?.vehicleId === veh._id).map((ele) => ({ label: ele.truck_no, value: ele._id }))[0] : "";
	// console.log('vehicleValue', filter.vehicleNumber, vehicleValue);
	let productCategoryValue = filter?.productCategory ? productCategories?.filter((cat) => filter.productCategory == cat._id).map((ele) => ({ label: ele.productCategory, value: ele._id })) : "";
	let productsValue = filter?.products ? products?.filter((prod) => filter.products.includes(prod._id)).map((ele) => ({ label: ele.productName, value: ele._id })) : [];
	// console.log('productsValue', productsValue);
	let checkpointsFromValue = filter?.checkpointsFrom ? checkpointOptions?.filter((chk) => filter.checkpointsFrom == chk.value) : "";
	let checkpointsToValue = filter?.checkpointsTo ? checkpointOptions?.filter((chk) => filter.checkpointsTo == chk.value) : "";
	let viaTo = filter?.viaCheckpoints ? checkpointOptions?.filter((chk) => filter?.viaCheckpoints.includes(chk.checkpointNumber)) : [];
	let disableOption = [];
	let disableViaOption = [];
	if (checkpointsFromValue[0]?.checkpointNumber) {
		disableOption = checkpointOptions?.filter((chk) => chk.checkpointNumber < checkpointsFromValue[0]?.checkpointNumber);
	}

	if (checkpointsFromValue[0]?.checkpointNumber && checkpointsToValue[0]?.checkpointNumber) {
		disableViaOption = checkpointOptions?.filter((chk) => chk.checkpointNumber <= checkpointsFromValue[0]?.checkpointNumber || chk.checkpointNumber >= checkpointsToValue[0]?.checkpointNumber);
	}
	// console.log('checkpointValues===', disableViaOption);

	const onhandlefilterChange = (value, name) => {
		// console.log('value', value, name);
		setSelectedFilter(value);
	};

	const onVehicleChange = (e, _name) => {
		let value = e?.$d ? new Date(e.$d).toISOString() : _name ? (Array.isArray(e) ? e.map((_) => _.value) : e?.value || "") : e.target.value;
		if (value == null || value == undefined || value == "") {
			// console.log('onVehicleChange', name, value);
			filterRef.current = {
				page_no: "1",
				pagesize: "25",
				search: "",
				searchBy: "vehicle",
			};
			refetch();
		}
		onFilterChange(e, _name);
	};

	const onInputChange = debounce((e) => {
		// console.log('e.target.name', e.target.name, e.target.value);
		filterRef.current = {
			page_no: "1",
			pagesize: "25",
			search: e.target.value,
			searchBy: "vehicle",
		};
		refetch();
	}, 500);

	return (
		<>
			<div className="fitler-selection-cont " style={{ marginTop: "15px" }}>
				<Dropdown id="checkpoints" name="checkpointsFrom" label="Checkpoints From" options={checkpointOptions} onChange={onFilterChange} value={checkpointsFromValue[0] || null} />
				<Dropdown
					id="viaTo"
					name="viaCheckpoints"
					label="Via To"
					options={checkpointOptions}
					onChange={onFilterChange}
					disabled={checkpointsFromValue[0] && checkpointsToValue[0] ? false : true}
					getOptionDisabled={(option) => {
						return disableViaOption?.length > 0 ? disableViaOption.some((filterItem) => option.checkpointNumber === filterItem.checkpointNumber) : false;
					}}
					value={viaTo || null}
					multiple={true}
				/>
				<Dropdown
					id="checkpoints"
					name="checkpointsTo"
					label="Checkpoints To"
					options={checkpointOptions}
					onChange={onFilterChange}
					getOptionDisabled={(option) => {
						return disableOption?.length > 0 ? disableOption.some((filterItem) => filterItem.checkpointNumber === option.checkpointNumber) : false;
					}}
					value={checkpointsToValue[0] || null}
				/>
				<Dropdown
					id="filterBy"
					name="filterBy"
					label="Filter By"
					options={[
						{
							label: "Vehicle Number",
							value: "vehicleNumber",
						},
						// {
						// 	label: "Asset Id",
						// 	value: "assetID"
						// }
					]}
					onChange={onhandlefilterChange}
					value={selectedFilter || null}
				/>
				<Dropdown
					// readonly={updateData}
					id={selectedFilter?.value === "vehicleNumber" ? "vehicleId" : "assetID"}
					name={selectedFilter?.value === "vehicleNumber" ? "vehicleId" : "assetID"}
					label={selectedFilter?.value === "vehicleNumber" ? "Search Vehicle Number" : "Search Asset Id"}
					onChange={onVehicleChange}
					onInputChange={(e) => onInputChange(e)}
					options={vehicles ? vehicles?.map((ele) => ({ label: ele.truck_no, value: ele._id })) : []}
					value={vehicleValue || null}
				/>
			</div>
			<div className="nav-cont">
				<Button text="Previous" onClick={() => onScreenChange("fieldMapping")} style={buttonClasses.lynkitOrangeEmpty} />
				<Button text="Next" onClick={() => onScreenChange("downloadReport")} style={buttonClasses.lynkitOrangeFill} />
			</div>
		</>
	);
}

function DownloadPreview({ reportType, onScreenChange, onFilterChange, onSubmit, filter, onReset }) {
	const { reportData, isReportLoading, totalCount } = useGetReport();
	const { isPDFLoading } = useGetReportPdf();

	useEffect(() => {
		if (!reportData) return;
		onSubmit();
	}, [filter.page_no, filter.pagesize]);
	return (
		<>
			<div className="fitler-selection-cont">
				<DateTimePicker label="Start Date" name="startdate" onChange={(val) => onFilterChange(val, "startdate")} required value={filter.startdate} maxDATE={dayjs()} />
				<DateTimePicker
					label="End Date"
					name="enddate"
					onChange={(v) => onFilterChange(v, "enddate")}
					required
					value={filter.enddate}
					minDATE={filter?.startdate ? dayjs(filter?.startdate) : null}
					maxDATE={dayjs()}
				/>
			</div>

			<Grid container spacing="0.5rem" justifyContent={"center"} mt="1rem">
				<Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
					<Button
						text="Previous"
						onClick={() => {
							onReset();
							onScreenChange("filterSelection");
						}}
						style={buttonClasses.lynkitOrangeEmpty}
					/>
				</Grid>
				<Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
					<Button text="Get Report" onClick={() => !isReportLoading && onSubmit()} disabled={isReportLoading || isPDFLoading} style={buttonClasses.lynkitOrangeFill} />
				</Grid>
				<Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
					<Button text="Export To Excel" onClick={() => !isReportLoading && onSubmit({ type: "excel" })} disabled={isReportLoading || isPDFLoading} style={buttonClasses.lynkitGreenFill} />
				</Grid>
				{/* <Grid item xl={2} lg={2} md={2} sm={3} xs={6}>
					<Button text="Export To PDF" onClick={() => !isReportLoading && onSubmit({ type: "pdf" })} disabled={isReportLoading || isPDFLoading} style={buttonClasses.lynkitBlackFill} />
				</Grid> */}
			</Grid>

			{isReportLoading || isPDFLoading ? (
				<Loader />
			) : reportData?.includes("<html>") ? (
				<div>
					<div
						dangerouslySetInnerHTML={{
							__html: reportData?.replace(/\n/g, ""),
						}}
						className="html-cont"
					/>
					<TablePagination
						component="div"
						count={totalCount}
						page={filter.page_no - 1}
						onPageChange={(newPage) => {
							// console.log(newPage + 1)
							onFilterChange({ target: { value: newPage + 1, name: "page_no" } });
						}}
						rowsPerPage={filter.pagesize}
						onRowsPerPageChange={(e) => {
							onFilterChange({ ...e, target: { ...e.target, name: "pagesize" } });
						}}
						ActionsComponent={TablePaginationActions}
						classes={{
							paper: {
								backgroundColor: "#212529",
							},
						}}
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							color: "var(--text-color)",
							backgroundColor: "var(--table-pagination-bg)",
							// mt: "0.5em",
							"& .MuiTablePagination-selectLabel ": {
								margin: "0",
							},
							"& .MuiTablePagination-displayedRows": {
								margin: "0",
							},

							"& .MuiTablePagination-selectIcon": {
								color: "var(--text-color)",
							},
						}}
					/>
				</div>
			) : null}
		</>
	);
}

//hooks
function useReportFieldMapping(payload) {
	const [getFieldMapping, { data, isLoading, reset: resetReportData }] = useGetReportFieldsMutation({ fixedCacheKey: "fielMapping" });

	useEffect(() => {
		if (payload) getFieldMapping({ type: payload.type, sub_type: payload.sub_type });
	}, []);

	let { data: fieldMapping, count, message } = data || { data: [] };
	return {
		fieldMapping,
		isLoading,
		resetReportData,
	};
}

function useGetReport() {
	const [fetchReport, { data, isLoading: isReportLoading, reset }] = useGetReportMutation({ fixedCacheKey: "getReport" });
	let { data: reportData, totalCount } = data || { data: "" };
	async function getReport(args) {
		const {
			data: { data, error, message },
		} = await fetchReport(args);
		if (error) {
			toastMessage(false, message);
		} else if (args?.download_type == "excel") {
			// let byteCharacters = atob(data.toString("base64"));
			// let byteNumbers = new Array(byteCharacters.length);
			// for (let i = 0; i < byteCharacters.length; i++) {
			// 	byteNumbers[i] = byteCharacters.charCodeAt(i);
			// }
			// let byteArray = new Uint8Array(byteNumbers);
			// saveAsExcel(byteArray, args.type || "");
		}
	}
	function clearData() {
		reset();
	}
	return {
		getReport,
		reportData,
		isReportLoading,
		clearData,
		totalCount,
		fetchReport,
	};
}
function useGetReportPdf() {
	const [fetchReportPdf, { data, isLoading: isPDFLoading, reset }] = useGetReportMutation({ fixedCacheKey: "getReportPDF" });
	let { data: pdfData } = data || { data: "" };

	function clearData() {
		reset();
	}
	return { fetchReportPdf, pdfData, isPDFLoading, clearData };
}
