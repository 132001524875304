import { useEffect, useRef, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { ActionMenu, Button, Dialog, Dropdown, GenericTable, Input, tCell, toastMessage } from "../utils";
import { useDynamicModuleEntries, useUserDetail, useUserPermissions } from "../../services/hooks";
import { buttonClasses } from "../utils/theme";
import { fakeFileInput, findDeepValue, formatDate } from "../../services/functions";
import { useCreateDynamicModuleEntryMutation, useUpdateDynamicModuleEntryMutation, useDeleteDynamicModuleEntryMutation } from "../../api/vmsSlice";
import DynamicFormRender from "../Dynamic/DynamicForm";

// const SAMPLE_EXCEL_PATH = "/sample_excels/supplier_sample.xlsx";
const EXCEL_TYPE = "supplier";
const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};
export default function DynamicModule() {
	const location = useLocation();
	const { data: currentModule = {} } = location.state || {};
	const { moduleName = "", hasExcelUpload = false, templateId = "", orgId } = currentModule;
	const { userDetail } = useUserDetail();
	const { userPermissions, isFetched } = useUserPermissions();

	const canAdd = userDetail?.userType == "superAdmin" || userPermissions[`module-add${moduleName.replaceAll(" ", "")}`]?.value;
	const canGet = userDetail?.userType == "superAdmin" || userPermissions[`module-get${moduleName.replaceAll(" ", "")}`]?.value;
	const canUpdate = userDetail?.userType == "superAdmin" || userPermissions[`module-update${moduleName.replaceAll(" ", "")}`]?.value;
	const canDelete = userDetail?.userType == "superAdmin" || userPermissions[`module-delete${moduleName.replaceAll(" ", "")}`]?.value;

	const [isModal, setIsModal] = useState(false);
	const updateDataRef = useRef(null);

	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;

	const { dynamicModuleEntries, count, isLoading, getDynamicModuleEntries, tableHeader } = useDynamicModuleEntries();
	useEffect(() => {
		filterRef.current = { ...filterRef.current, templateId, orgId, moduleName };
		getDynamicModuleEntries(filterRef.current);
	}, [templateId, orgId, moduleName]);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onUpdateSuccess = () => {
		getDynamicModuleEntries(filterRef.current);
	};

	const [handleDelete] = useDeleteDynamicModuleEntryMutation();
	let actionHandlers = {};
	if (canUpdate) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = dynamicModuleEntries[id];
			// console.log(updateDataRef.current, dynamicModuleEntries , id)
			setIsModal(true);
		};
	}
	if (canDelete) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = dynamicModuleEntries[id];
			const payload = { deleteId: data._id, moduleName };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}
	let header = ["S.no", ...tableHeader.map((_) => _.label)];
	if (userDetail?.userType == "superAdmin") {
		header.push("Org Name");
	}
	if (canUpdate || canDelete) {
		header.push("Action");
	}
	const rows =
		dynamicModuleEntries?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1));

			for (let _ of tableHeader) {
				row.push(tCell(findDeepValue(d, _.name.split("."))));
			}
			if (userDetail?.userType == "superAdmin") {
				row.push(tCell(d.orgName));
			}
			if (canUpdate || canDelete) {
				row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			}

			return row;
		}) || [];
	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getDynamicModuleEntries(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getDynamicModuleEntries(filterRef.current);
	};
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			// const payload = {
			// 	file,
			// 	type: EXCEL_TYPE,
			// };

			// const {
			// 	data: { error, message },
			// } = await handleUploadExcel(formData);
			// if (error) {
			// 	toastMessage(false, message);
			// } else {
			// 	toastMessage(true, message);
			// 	onUpdateSuccess();
			// }
		});
	};

	if (isFetched && !canGet) return <Navigate to="/not-authorized" replace />;

	return (
		<>
			<div className="contentpanel">
				<Grid container spacing="0.5rem" mb={2}>
					<Grid item xl={6} lg={6} md={5} sm={1} xs={12}>
						<h1 className="title">{moduleName}</h1>
					</Grid>
					<Grid item xl={6} lg={6} md={7} sm={1} xs={12}>
						<Grid container spacing="0.5rem" justifyContent={"flex-end"}>
							{canAdd ? (
								<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
									<Button onClick={onModalOpen} text={`Add ${moduleName}`} style={buttonClasses.lynkitOrangeEmpty} />
								</Grid>
							) : null}
							{/* {canAdd ? (
								<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
									<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
								</Grid>
							) : null}
							{canAdd ? (
								<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
									<a href={SAMPLE_EXCEL_PATH} download>
										<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
									</a>
								</Grid>
							) : null} */}
						</Grid>
					</Grid>
				</Grid>
				<hr className="bgg" />
				<GenericTable
					header={header}
					rows={rows}
					pageCount={count || dynamicModuleEntries?.length}
					pageNo={page_no}
					limit={pagesize}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					isLoading={isLoading}
				/>
				{isModal && <AddForm open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
			</div>
		</>
	);
}

function AddForm({ onClose, open, updateData, onUpdateSuccess }) {
	const location = useLocation();
	const { data: currentModule = {} } = location.state || {};
	const { moduleName = "", templateId = "", orgId = "", orgName = "", templateName = "" } = currentModule;

	const [handleAdd, { isLoading: isAdding }] = useCreateDynamicModuleEntryMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateDynamicModuleEntryMutation();
	const handleFormSubmit = async (formData) => {
		const { data } = formData;
		let payload = { data, moduleName, templateId, orgId, orgName };
		if (updateData) {
			payload.updateId = updateData._id;
		}
		// console.log(payload);
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			onClose();
		}
	};
	// console.log(updateData);
	return (
		<Dialog size="md" open={open} handleClose={onClose} title={`${updateData ? "Update" : "Add"} ${moduleName}`} noBtn>
			<DynamicFormRender
				templateId={templateId}
				templatePayload={{
					id: templateId,
					organization_id: orgId,
					isUpdate: !!updateData,
				}}
				onClose={onClose}
				onSubmit={handleFormSubmit}
				isSubmitDisabled={isAdding || isUpdating}
				updateData={updateData}
			/>
		</Dialog>
	);
}
