import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import { Box, Paper, Grid, Typography } from "@mui/material";
import { Input, Dropdown, Button, SearchDropdown, DatePicker, DateTimePicker } from ".";
import theme, { buttonClasses } from "../utils/theme";
import { debounce } from "../../services/functions";

//sapmle Array
// [
//     {
// 		inputType: "textfield",
// 		id: "2",
// 		name: "deviceName",
// 		type: "text",
// 		label: "Device Name",
// 		placeholder: "Device Name",
// 	},
// 	{
// 		inputType: "dropdown",
// 		id: "3",
// 		name: "menu",
// 		label: "menu",
// 		option: [{ label: "aaa", value: "aaa" }, { label: "bbb", value: "bbb" }, { label: "ccc", value: "ccc" }]
// 	},
// 	{
// 		inputType: "searchDropdown",
// 		id: "3",
// 		name: "menu",
// 		label: "User Search by",
// 		placeholder: "Enter Value",
// 		option: [{ label: "name", value: "name" }]
// 	}
// ]

function Genericfilter({ filterFields, onFilterChange, addOnClear = false, addOnSubmit = false }) {
	const [filter, setFilter] = useState({});
	const location = useLocation();

	const handleChange = (value, key) => {
		// console.log('filter on change', value, key)
		setFilter((previous) => {
			return { ...previous, [key]: value === null ? "" : value };
		});
	};
	const onhandleClear = () => {
		// console.log('clear filter', filter)
		if (Object.keys(filter).length > 0) {
			setFilter({});
			onFilterChange({});
		}
	};

	const handleSubmit = () => {
		onFilterChange(filter);
	};

	useEffect(() => {
		if (addOnSubmit === false && Object.keys(filter).length > 0) {
			onFilterChange(filter);
		}
	}, [filter]);

	useEffect(() => {
		if (location.pathname) {
			setFilter({});
		}
	}, [location.pathname]);

	return (
		<>
			<Grid container mb={2} mt={1}>
				<Grid item xs={12}>
					<Grid container spacing={2}>
						{filterFields &&
							filterFields?.length > 0 &&
							filterFields?.map((ele, index) => {
								if (ele.inputType === "textfield") {
									return (
										<Grid item xl={2} lg={2} md={2} sm={3} xs={12} key={ele.name}>
											<Input
												id={ele.id}
												name={ele.name}
												value={filter[ele.name] ? filter[ele.name] : ""}
												onChange={(e) => {
													debounce(handleChange(e.target.value, ele.name), 500);
												}}
												type={ele.type}
												label={ele.label}
												placeholder={ele.placeholder}
												min={ele?.min || null}
												max={ele?.max || null}
												step={ele?.step || null}
												error={ele.error}
												sx={{ width: "100%" }}
											/>
										</Grid>
									);
								}
								if (ele.inputType === "datePicker") {
									return (
										<Grid item xl={2} lg={2} md={2} sm={3} xs={12} key={ele.name}>
											<DatePicker
												name={ele.name}
												minDATE={ele.minDATE ? ele.minDATE : null}
												maxDATE={ele.maxDATE ? ele.maxDATE : null}
												value={filter[ele.name] ? filter[ele.name] : null}
												required
												onChange={(val) => handleChange(val.toISOString(), ele.name)}
												label={ele.label}
											/>
										</Grid>
									);
								}
								if (ele.inputType === "dropdown") {
									return (
										<Grid item xl={2} lg={3} md={3} sm={3} xs={12} key={ele.name}>
											<Dropdown
												id={ele.id}
												name={ele.name}
												value={filter[ele.name] ? filter[ele.name] : null}
												options={ele.option}
												label={ele.label}
												onChange={(option, key) => handleChange(option, key)}
												error={ele.error}
												Debounce={true}
											/>
										</Grid>
									);
								}
								if (ele.inputType === "searchDropdown") {
									return (
										<Grid item xl={3} lg={3} md={5} sm={5} xs={12} key={ele.label}>
											<SearchDropdown
												id={ele.id}
												options={ele.options}
												preSelectedOption={ele.options[0]}
												label={ele.label}
												placeholder={ele.placeholder}
												value={filter}
												error={ele.error}
												Debounce={true}
												onChange={handleChange}
												onDropdownChange={onhandleClear}
												onhandleClear={onhandleClear}
											/>
										</Grid>
									);
								}
							})}

						{addOnClear === true && (
							<Grid item xl={1} lg={1} md={1} sm={1} xs={12}>
								<Button
									testId={"Clear"}
									text={"Clear"}
									style={buttonClasses.outlined}
									// disabled={false}
									onClick={() => onhandleClear()}
								/>
							</Grid>
						)}
						{addOnSubmit === true && (
							<Grid item xl={1} lg={1} md={1} sm={4} xs={12}>
								<Button
									testId={"Submit"}
									text={"Submit"}
									style={buttonClasses.outlined}
									// disabled={false}
									onClick={() => handleSubmit()}
								/>
							</Grid>
						)}
						{/* <Grid item sx={{ display: "flex", alignItems: "center" }}>
							<Grid container spacing={1} sx={{ display: "flex", justifyContent: "end" }}></Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

Genericfilter.propTypes = {
	filterFields: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			name: PropTypes.string.isRequired,
			value: PropTypes.any,
			inputType: PropTypes.oneOf(["textfield", "dropdown", "searchDropdown", "datePicker"]).isRequired,
			type: PropTypes.string,
			label: PropTypes.string.isRequired,
			placeholder: PropTypes.string,
			option: PropTypes.array,
			min: PropTypes.number,
			max: PropTypes.number,
			step: PropTypes.number,
			readOnly: PropTypes.bool,
			disabled: PropTypes.bool,
			required: PropTypes.bool,
			error: PropTypes.string,
		})
	).isRequired,
	onFilterChange: PropTypes.func.isRequired,
	addOnClear: PropTypes.bool,
	addOnSubmit: PropTypes.bool,
};

export default Genericfilter;
