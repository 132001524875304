import { apiSlice } from "./api";

const slice = apiSlice.injectEndpoints({
	endpoints: (build) => ({
		addVehicle: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/add-vehicle",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vehicle"],
		}),
		getVehicles: build.query({
			query: (body) => {
				return {
					url: "vms/vehicle/get-vehicle",
					method: "post",
					body,
				};
			},
			providesTags: ["vehicle"],
		}),
		getVehiclesMutation: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/get-vehicle",
					method: "post",
					body,
				};
			},
		}),
		updateVehicle: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/update-vehicle",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vehicle"],
		}),
		deleteVehicle: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/delete-vehicle",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["vehicle"],
		}),
		vehicleDetails: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/vehicle-entry-data-list",
					method: "post",
					body,
				};
			},
			// providesTags: ["vehicle"],
		}),
		addSupplier: build.mutation({
			query: (body) => {
				return {
					url: "vms/supplier/add-supplier",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["supplier"],
		}),
		uploadBulkSupplier: build.mutation({
			query: (body) => {
				return {
					url: "vms/supplier/bulkUpload-supplier",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["supplier"],
		}),
		getSuppliers: build.mutation({
			query: (body) => {
				return {
					url: "vms/supplier/get-supplier",
					method: "post",
					body,
				};
			},
			providesTags: ["supplier"],
		}),
		updateSupplier: build.mutation({
			query: (body) => {
				return {
					url: "vms/supplier/update-supplier",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["supplier"],
		}),
		deleteSupplier: build.mutation({
			query: (body) => {
				return {
					url: "vms/supplier/delete-supplier",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["supplier"],
		}),

		addProduct: build.mutation({
			query: (body) => {
				return {
					url: "vms/product/add-product",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productType"],
		}),
		uploadBulkProduct: build.mutation({
			query: (body) => {
				return {
					url: "vms/product/bulkUpload-product",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productType"],
		}),
		getProducts: build.mutation({
			query: (body) => {
				return {
					url: "vms/product/get-product",
					method: "post",
					body,
				};
			},
			providesTags: ["productType"],
		}),
		updateProduct: build.mutation({
			query: (body) => {
				return {
					url: "vms/product/update-product",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productType"],
		}),
		deleteProduct: build.mutation({
			query: (body) => {
				return {
					url: "vms/product/delete-product",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productType"],
		}),

		addProductCategory: build.mutation({
			query: (body) => {
				return {
					url: "vms/product-category/add-product-category",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productCategory"],
		}),
		uploadProductCategory: build.mutation({
			query: (body) => {
				return {
					url: "vms/product-category/bulkUpload-product-category",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productCategory"],
		}),
		getProductCategories: build.mutation({
			query: (body) => {
				return {
					url: "vms/product-category/get-product-category",
					method: "post",
					body,
				};
			},
			providesTags: ["productCategory"],
		}),
		updateProductCategory: build.mutation({
			query: (body) => {
				return {
					url: "vms/product-category/update-product-category",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productCategory"],
		}),
		deleteProductCategory: build.mutation({
			query: (body) => {
				return {
					url: "vms/product-category/delete-product-category",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["productCategory"],
		}),

		addCheckPoint: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/add-checkpoint",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["CheckPoint"],
		}),
		getCheckPoint: build.query({
			query: (body) => {
				return {
					url: "vms/checkpoint/checkpoint-list",
					method: "post",
					body,
				};
			},
			providesTags: ["CheckPoint"],
		}),
		getCheckPointOnCall: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/checkpoint-list",
					method: "post",
					body,
				};
			},
			providesTags: ["CheckPoint"],
		}),
		getCheckPointDetail: build.query({
			query: (body) => {
				return {
					url: "vms/checkpoint/checkpoint-detail",
					method: "post",
					body,
				};
			},
		}),
		getCheckPointType: build.query({
			query: (body) => {
				return {
					url: "vms/checkpoint/get-checkpoint-type",
					method: "post",
					body,
				};
			},
		}),
		updateCheckPoint: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/update-checkpoint",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["CheckPoint"],
		}),
		deleteCheckPoint: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/delete-checkpoint",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["CheckPoint"],
		}),

		getTemplateList: build.query({
			query: (body) => {
				return {
					url: "builder/template_list",
					method: "post",
					body,
				};
			},
		}),
		getTemplates: build.mutation({
			query: (body) => {
				return {
					url: "builder/template_list",
					method: "post",
					body,
				};
			},
		}),
		getTemplateDetail: build.query({
			query: (body) => {
				return {
					url: "builder/template_detail",
					method: "post",
					body,
				};
			},
		}),
		getTemplateDetailOnCall: build.mutation({
			query: (body) => {
				return {
					url: "builder/template_detail",
					method: "post",
					body,
				};
			},
		}),
		uploadOfflineData: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/create-vehicle-entry",
					method: "post",
					body,
				};
			},
		}),
		saveCheckpointForm: build.mutation({
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				// console.log(arg);
				const [url, body] = arg;

				try {
					const { data } = await baseQuery({ url, method: "post", body }, api, extraOptions);
					return { data };
				} catch (error) {
					return { error };
				}
			},
			invalidatesTags: ["checkpoint_datalist"],
		}),

		getCheckpointDataList: build.query({
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				// console.log(arg);
				// const [url, body] = arg;
				let url = "vms/vehicle/checkpoint-data-list";
				let body = arg;

				try {
					const { data } = await baseQuery({ url, method: "post", body }, api, extraOptions);
					return { data };
				} catch (error) {
					return { error };
				}
			},
			providesTags: ["checkpoint_datalist"],
		}),

		saveCheckoutForm: build.mutation({
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				// console.log(arg);
				const [url, body] = arg;

				try {
					const { data } = await baseQuery({ url, method: "post", body }, api, extraOptions);
					return { data };
				} catch (error) {
					return { error };
				}
			},
			invalidatesTags: ["checkpoint_datalist"],
		}),

		getCheckpointEntryDetail: build.query({
			queryFn: async (arg, api, extraOptions, baseQuery) => {
				// console.log(arg);
				const [url, body] = arg;

				try {
					const { data } = await baseQuery({ url, method: "post", body }, api, extraOptions);
					return { data };
				} catch (error) {
					return { error };
				}
			},
		}),

		getDashboardStats: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/get-dashboard-stats",
					method: "post",
					body,
				};
			},
			providesTags: ["dashboard_stats"],
		}),

		getDashboardStatsV2: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/dashboard-stats-v2",
					method: "post",
					body,
				};
			},
			providesTags: ["dashboard_stats"],
		}),

		getApiListDetail: build.query({
			query: (body) => {
				return {
					url: "builder/api_list",
					method: "post",
					body,
				};
			},
		}),

		getWorkflowListing: build.query({
			query: (body) => {
				return {
					url: "vms/vehicle/workflow-listing",
					method: "post",
					body,
				};
			},
			providesTags: ["workflowlist"],
		}),

		getWorkflowList: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/workflow-listing",
					method: "post",
					body,
				};
			},
			providesTags: ["workflowlist"],
		}),

		createWorkflow: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/create-workflow",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["workflowlist"],
		}),

		updateWorkflow: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/update-workflow",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["workflowlist"],
		}),

		getApprovalListing: build.query({
			query: (body) => {
				return {
					url: "vms/approval/Get-Approval-listing",
					method: "post",
					body,
				};
			},
			providesTags: ["approvallist"],
		}),

		updateApproval: build.mutation({
			query: (body) => {
				return {
					url: "vms/approval/approve",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["approvallist"],
		}),

		addCheckpointType: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/add-checkpoint-type",
					method: "post",
					body,
				};
			},
		}),
		getCheckpointType: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/get-checkpoint-type",
					method: "post",
					body,
				};
			},
		}),
		updateCheckpointType: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/update-checkpoint-type",
					method: "post",
					body,
				};
			},
		}),
		getTripcheckpoint: build.mutation({
			query: (body) => {
				return {
					url: "vms/checkpoint/get-trip-checkpoints",
					method: "post",
					body,
				};
			},
		}),
		getDataFromUlip: build.mutation({
			query: (body) => {
				return {
					url: "vms/vehicle/get-data-from-ulip",
					method: "post",
					body,
				};
			},
		}),
		mapVehicleTag: build.mutation({
			query: (body) => {
				return {
					url: "devices/tag/map-vehicle-tag",
					method: "post",
					body,
				};
			},
		}),
		createDynamicModule: build.mutation({
			query: (body) => {
				return {
					url: "vms/module/create-module",
					method: "post",
					body,
				};
			},
		}),
		getDynamicModule: build.mutation({
			query: (body) => {
				return {
					url: "vms/module/get-modules",
					method: "post",
					body,
				};
			},
		}),
		updateDynamicModule: build.mutation({
			query: (body) => {
				return {
					url: "vms/module/update-module",
					method: "post",
					body,
				};
			},
		}),
		deleteDynamicModule: build.mutation({
			query: (body) => {
				return {
					url: "vms/module/delete-module",
					method: "post",
					body,
				};
			},
		}),

		createDynamicModuleEntry: build.mutation({
			query: (body) => {
				return {
					url: "vms/dynamic-module/create-entry",
					method: "post",
					body,
				};
			},
		}),
		getDynamicModuleEntry: build.mutation({
			query: (body) => {
				return {
					url: "vms/dynamic-module/get-entries",
					method: "post",
					body,
				};
			},
		}),
		updateDynamicModuleEntry: build.mutation({
			query: (body) => {
				return {
					url: "vms/dynamic-module/update-entry",
					method: "post",
					body,
				};
			},
		}),
		deleteDynamicModuleEntry: build.mutation({
			query: (body) => {
				return {
					url: "vms/dynamic-module/delete-entry",
					method: "post",
					body,
				};
			},
		}),
		getApprovalConfig: build.query({
			query: (body) => {
				return {
					url: "vms/approval-config/get-approval-config",
					method: "post",
					body,
				};
			},
			providesTags: ["approvalConfig"],
		}),
		addApprovalConfig: build.mutation({
			query: (body) => {
				return {
					url: "vms/approval-config/add-approval-config",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["approvalConfig"],
		}),
		updateApprovalConfig: build.mutation({
			query: (body) => {
				return {
					url: "vms/approval-config/update-approval-config",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["approvalConfig"],
		}),
		deleteApprovalConfig: build.mutation({
			query: (body) => {
				return {
					url: "vms/approval-config/delete-approval-config",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["approvalConfig"],
		}),
		getVariableListing: build.mutation({
			query: (body) => {
				return {
					url: "vms/common/get-org-variables",
					method: "post",
					body,
				};
			},
		}),
		getApprovalConfigg: build.mutation({
			query: (body) => {
				return {
					url: "vms/approval-config/get-approval-config",
					method: "post",
					body,
				};
			},
		}),

		getOrgSetting: build.query({
			query: (body) => {
				return {
					url: "vms/common/get-org-setting",
					method: "post",
					body,
				};
			},
			providesTags: ["orgSetting"],
		}),
		updateOrgSetting: build.mutation({
			query: (body) => {
				return {
					url: "vms/common/update-org-setting",
					method: "post",
					body,
				};
			},
			invalidatesTags: ["orgSetting"],
		}),
	}),
	overrideExisting: false,
});

// console.log(slice);
export const {
	useAddVehicleMutation,
	useGetVehiclesQuery,
	useGetVehiclesMutationMutation,
	useUpdateVehicleMutation,
	useDeleteVehicleMutation,
	useVehicleDetailsMutation,
	useUploadOfflineDataMutation,

	useAddSupplierMutation,
	useUploadBulkSupplierMutation,
	useGetSuppliersMutation,
	useUpdateSupplierMutation,
	useDeleteSupplierMutation,

	useAddProductMutation,
	useUploadBulkProductMutation,
	useGetProductsMutation,
	useUpdateProductMutation,
	useDeleteProductMutation,

	useAddProductCategoryMutation,
	useUploadProductCategoryMutation,
	useGetProductCategoriesMutation,
	useUpdateProductCategoryMutation,
	useDeleteProductCategoryMutation,

	useAddCheckPointMutation,
	useGetCheckPointQuery,
	useGetCheckPointTypeQuery,
	useGetCheckPointOnCallMutation,
	useGetCheckPointDetailQuery,
	useUpdateCheckPointMutation,
	useDeleteCheckPointMutation,
	useGetTripcheckpointMutation,

	useGetTemplateDetailQuery,
	useGetTemplateListQuery,
	useGetTemplatesMutation,

	useSaveCheckpointFormMutation,
	useLazyGetCheckpointDataListQuery,

	useSaveCheckoutFormMutation,
	useLazyGetCheckpointEntryDetailQuery,

	useGetDashboardStatsMutation,
	useGetDashboardStatsV2Mutation,

	useGetApiListDetailQuery,

	useCreateWorkflowMutation,
	useUpdateWorkflowMutation,
	useGetWorkflowListMutation,
	useGetWorkflowListingQuery,

	useUpdateApprovalMutation,
	useGetApprovalListingQuery,

	useAddCheckpointTypeMutation,
	useGetCheckpointTypeMutation,
	useUpdateCheckpointTypeMutation,

	useGetDataFromUlipMutation,
	useMapVehicleTagMutation,

	useGetDynamicModuleMutation,
	useCreateDynamicModuleMutation,
	useUpdateDynamicModuleMutation,
	useDeleteDynamicModuleMutation,

	useGetDynamicModuleEntryMutation,
	useCreateDynamicModuleEntryMutation,
	useUpdateDynamicModuleEntryMutation,
	useDeleteDynamicModuleEntryMutation,

	useGetApprovalConfigQuery,
	useAddApprovalConfigMutation,
	useUpdateApprovalConfigMutation,
	useDeleteApprovalConfigMutation,

	useGetVariableListingMutation,
	useGetApprovalConfiggMutation,
	useGetTemplateDetailOnCallMutation,

	useGetOrgSettingQuery,
	useUpdateOrgSettingMutation,
} = slice;
