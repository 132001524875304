import React, { useState, useRef, useEffect } from "react";
import "./WorkFlowIndex.css";
import { Dropdown, Input, Button, ActionMenu, GenericTable, tCell, AddRemove } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useOrgs } from "../../services/hooks";
import { useGetCheckPointOnCallMutation } from "../../api/vmsSlice";
import { useCreateWorkflowMutation, useUpdateWorkflowMutation, useGetWorkflowListingQuery } from "../../api/vmsSlice";
import { toastMessage } from "../utils";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Checkbox } from "../utils";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";

const filterFields = [
	{
		inputType: "textfield",
		id: "1",
		name: "name",
		type: "text",
		label: "Display Name",
		placeholder: "Display Name",
		readOnly: false,
		error: "",
		value: "",
	},
];

export default function WorkFlowIndex() {
	const [addWorkflow, setAddWorkflow] = useState(false);
	const [updatedData, setUpdatedData] = useState({});
	const [displayName, setDisplayName] = useState("");
	const [orgId, setOrgId] = useState(null);
	const [checkPointOption, setCheckPointOption] = useState([]);
	const [checkPointRes, setCheckPointRes] = useState([]);
	const [selectedCheckpoint, setSelectedCheckpoints] = useState([{}]);
	const [workflow, setWorkFlow] = useState([
		{
			order: "",
			checkpoints: [],
			optional: false,
		},
	]);
	const [error, setError] = useState({});
	const [filter, setFilter] = useState({
		pagesize: 10,
		page_no: 1,
	});

	const { orgOptions } = useOrgs();
	const [getCheckPointTriggier] = useGetCheckPointOnCallMutation();
	const [createWorkflowTrigger] = useCreateWorkflowMutation();
	const [updateWorkflowTrigger] = useUpdateWorkflowMutation();
	const { data : workflowListData = {}, error : workflowError, isLoading: isUpdating, refetch } = useGetWorkflowListingQuery(filter, {refetchOnMountOrArgChange: true,});
	const {data : workflowListing, count } = workflowListData;

	/////// add work flow //////

	const addWorkflowListing = () => {
		const workflowList = [...workflow];
		workflowList.push({
			order: "",
			checkpoints: [],
			optional: false,
		});
		setWorkFlow(workflowList);
	};
	const removeWorkflowListing = (index) => {
		const workflowList = [...workflow];
		const selectedCheckpointList = [...selectedCheckpoint];
		workflowList.splice(index, 1);
		selectedCheckpointList.splice(index, 1);
		setWorkFlow(workflowList);
		setSelectedCheckpoints(selectedCheckpointList);
	};

	const onHandleCheckpointChange = (value, name, index) => {
		const workflowList = [...workflow];
		const selectedCheckpointList = [...selectedCheckpoint];

		if (name === "Order") {
			workflowList[index] = {
				order: value,
				checkpoints: workflowList[index].checkpoints,
				optional: workflowList[index].optional || false,
			};
			selectedCheckpointList[index] = {
				order: value,
				checkpoints: selectedCheckpointList[index]?.checkpoints || [],
				optional: selectedCheckpointList[index]?.optional || false,
			};
		} else if (name === "optional") {
			workflowList[index] = {
				optional: value,
				order: workflowList[index].order || "",
				checkpoints: workflowList[index].checkpoints || [],
			};
			selectedCheckpointList[index] = {
				optional: value,
				order: selectedCheckpointList[index]?.order || "",
				checkpoints: selectedCheckpointList[index]?.checkpoints || [],
			};
		} else {
			const result = value.map((ele) => checkPointRes.length > 0 && checkPointRes.find((checkpoint) => checkpoint._id === ele.value));
			workflowList[index] = {
				order: workflowList[index].order || "",
				checkpoints: value,
				optional: workflowList[index].optional || false,
			};
			selectedCheckpointList[index] = {
				order: selectedCheckpointList[index]?.order || "",
				checkpoints: result,
				optional: selectedCheckpointList[index]?.optional || false,
			};
		}

		// console.log('selected====', workflowList, selectedCheckpointList)

		setSelectedCheckpoints(selectedCheckpointList);
		setWorkFlow(workflowList);
	};

	const handleSubmit = (action) => {
		setError({});
		let payload = {
			name: displayName,
			orgId: orgId?.value || "",
			orgName: orgId?.label || "",
			checkpoints: workflow,
		};
		const valid = checkValidation(payload);
		// console.log('payload', payload)
		if (!valid.success) {
			setError(valid.error);
		} else {
			payload['checkpoints'] = selectedCheckpoint;
			if (action === "submit") {
				createWorkflowTrigger(payload)
					.unwrap()
					.then((res) => {
						// console.log('create-workflow', res)
						if (res.error == true) {
							toastMessage(false, res.message);
							setAddWorkflow(!addWorkflow);
						} else {
							toastMessage(true, res.message);
							setAddWorkflow(!addWorkflow);
						}
					})
					.catch((err) => {
						toastMessage(false, err.message);
					});
			} else {
				if (updatedData._id != "") {
					const updatedPayload = { ...payload, _id: updatedData._id };
					updateWorkflowTrigger(updatedPayload)
						.unwrap()
						.then((res) => {
							// console.log('update-workflow', res)
							if (res.error == true) {
								toastMessage(false, res.message);
								setAddWorkflow(!addWorkflow);
							} else {
								toastMessage(true, res.message);
								setAddWorkflow(!addWorkflow);
							}
						})
						.catch((err) => {
							toastMessage(false, err.message);
						});
				} else {
					toastMessage(false, "_id is missing");
				}
			}
		}

		// console.log('payload-submit', payload)
		// console.log('payload-submit-error', valid)
	};

	////table ////

	let actionHandlers = {};
	actionHandlers.edit = (id) => {
		if (workflowListing?.length > 0) {
			setUpdatedData(workflowListing[id]);
		}
		setAddWorkflow(!addWorkflow);
	};
	let header = [
		"S.no",
		"Display Name",
		"Org Name",
		"Action",
	];
	const rows = fetchRow( workflowListing, filter, ActionMenu, actionHandlers)

	const onPageChange = (page_no) => {
		let filterRef = { ...filter, page_no: page_no + 1 };
		setFilter(filterRef);
		refetch;
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		let filterRef = { ...filter, pagesize: value };
		setFilter(filterRef);
		refetch;
	};

	const handleFilterChange = (filterr) => {
		let filterRef = { ...filter, filter: { ...filterr } };
		setFilter(filterRef);
		refetch;
	};

	useEffect(() => {
		if (Object.keys(updatedData)?.length > 0) {
			setDisplayName(updatedData.name || "");
			const selectedOrgId = orgOptions.filter((ele) => ele.value === updatedData.orgId)[0];
			// console.log("updatedData", checkPointOption);
			setOrgId(selectedOrgId || "");
			setSelectedCheckpoints(updatedData.checkpoints || [{}]);
			let temp = [];
			if (updatedData.checkpoints.length > 0) {
				updatedData.checkpoints.forEach((ele, index) => {
					const result = ele?.checkpoints?.map((ele) => ({ label: ele.displayName, value: ele._id })) || [];
					temp.splice(index, 1, {
						checkpoints: result,
						order: ele.order,
						optional: ele.optional,
					});
				});
			}
			// console.log('updating=====>', temp);
			setWorkFlow(temp);
			fetchCheckPointListing(updatedData.orgId, "update");
		}
	}, [updatedData]);
	
	const fetchCheckPointListing = (orgid, action = "add") => {
		if (orgid) {
			getCheckPointTriggier({ orgId: orgid })
				.unwrap()
				.then((res) => {
					// console.log('checkpointData', res);
					if (res?.error === true) {
						toastMessage(false, res.message);
						setCheckPointOption([]);
					}
					if (res?.data?.length > 0) {
						const checkpoints = [...res.data].sort((a, b) => a.checkpointNumber - b.checkpointNumber);
						const checkpointOptions = checkpoints.map((_) => ({ label: _.displayName, value: _._id }));
						// console.log('checkpointOptions', checkpointOptions)
						setCheckPointOption(checkpointOptions);
						setCheckPointRes([...res.data]);
						if (action === "add") {
							setSelectedCheckpoints([{}]);
							setWorkFlow([
								{
									order: "",
									checkpoints: [],
								},
							]);
						}
					} else {
						setCheckPointOption([]);
						setSelectedCheckpoints([{}]);
						setWorkFlow([
							{
								order: "",
								checkpoints: [],
								optional: false,
							},
						]);
					}
				})
				.catch((err) => {
					toastMessage(false, err.message);
				});
		}
	};

	return (
		<>
			<div className="Workflow_panel">
				<Grid container spacing="1rem">
					<Grid item xl={11} lg={10} md={10} sm={9} xs={6}>
						<div className="workflow_heading">{addWorkflow && Object.keys(updatedData)?.length > 0 ? "Update Workflow" : addWorkflow ? "Add New Workflow" : "Workflow"}</div>
					</Grid>
					<Grid item xl={1} lg={2} md={2} sm={3} xs={6} display={'flex'} justifyContent={'flex-end'}>
						<Button
							onClick={() => {
								setAddWorkflow(!addWorkflow);
								setUpdatedData({});
								setDisplayName("");
								setOrgId("");
								setSelectedCheckpoints([{}]);
								setWorkFlow([
									{
										order: "",
										checkpoints: [],
									},
								]);
								setError({});
							}}
							text={addWorkflow ? "Go Back" : "Add Workflow"}
							style={{...buttonClasses.lynkitOrangeEmpty, width : 'fit-content'}}
						/>
					</Grid>
				</Grid>
			</div>
			{addWorkflow ? (
				<div className="workflow-container">
					<Grid container spacing="1rem">
						<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
							<Input
								id={"displayName"}
								name={"displayName"}
								value={displayName}
								type={"text"}
								label={"Display Name"}
								onChange={(e) => setDisplayName(e.target.value)}
								error={error?.displayName || ""}
								sx={{ width: "100%" }}
								required
							/>
						</Grid>
						<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
							<Dropdown
								id={"orgId"}
								name={"orgId"}
								value={orgOptions.find(_=> _.value == orgId?.value) || null}
								options={orgOptions}
								label={"Select Organisation"}
								onChange={(option, key) => {
									setOrgId(option);
									fetchCheckPointListing(option?.value);
								}}
								error={error?.orgId || ""}
								required
								readonly={addWorkflow && Object.keys(updatedData)?.length > 0 ? true : false}
							/>
						</Grid>
					</Grid>

					<div className="workflow-field-container  boder-rad-10p mt-3">
						{workflow.map((ele, index) => {
							return (
								<div className="workflow-fields  boder-rad-10p" key={`workflow_${index}`}>
									<Grid container spacing="1rem">
										<Grid item xl={2} lg={2} md={3} sm={3} xs={12}>
											<Input
												id={"Order No"}
												name={"Order"}
												value={ele.order}
												type={"number"}
												label={"Order No."}
												onChange={(e) => onHandleCheckpointChange(e.target.value, e.target.name, index)}
												error={error?.checkpoints && error?.checkpoints.length > 0 && error?.checkpoints[index] ? error?.checkpoints[index].order : ""}
												sx={{ width: "100%" }}
												required
											/>
										</Grid>
										<Grid item xl={2} lg={2} md={3} sm={4} xs={12}>
											<Dropdown
												id={"checkpoint"}
												name={"checkpoint"}
												value={ele.checkpoints || []}
												options={checkPointOption}
												label={"Checkpoint"}
												multiple={true}
												required
												onChange={(option, key) => onHandleCheckpointChange(option, key, index)}
												error={error?.checkpoints && error?.checkpoints.length > 0 && error?.checkpoints[index] ? error?.checkpoints[index].checkpoints : ""}
											/>
										</Grid>
										<div className="Workflow-checkpoint-div">
											<Checkbox
												id={index}
												name={"optional"}
												checked={ele.optional || false}
												onChange={(e) => onHandleCheckpointChange(e.target.checked, e.target.name, index)}
												label={"Optional"}
											/>
										</div>
										<Grid item xl={1} lg={1} md={1} sm={2} xs={6} sx={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
											<AddRemove list={workflow} filterMethod={(_)=> _.order == ""} index={index} outerIndex={index} onAdd={addWorkflowListing} onRemove={removeWorkflowListing}/>
										</Grid>
									</Grid>
								</div>
							);
						})}
					</div>
					<div className="d-flex justify-content-center my-4">
						<Button
							testId={"Submit"}
							text={Object.keys(updatedData)?.length > 0 ? "Update" : "Submit"}
							style={{ ...buttonClasses.lynkitOrangeFill, width: "10%" }}
							// disabled={false}
							onClick={() => handleSubmit(Object.keys(updatedData)?.length > 0 ? "update" : "submit")}
						/>
					</div>
				</div>
			) : (
				<>
					<GenericstatsV2 statsFor={"workflow"} />
					<div className="cust-row flex-algn-cent">
						<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
					</div>
					<div>
						<GenericTable
							header={header}
							rows={rows}
							pageCount={count}
							pageNo={filter.page_no}
							limit={filter.pagesize}
							onPageChange={onPageChange}
							onPageSizeChange={onPageSizeChange}
							isLoading={isUpdating}
						/>
					</div>
				</>
			)}
		</>
	);
}


const fetchRow = (workflowListing, filter, ActionMenu, actionHandlers) => {
	const { page_no = 1, pagesize = 10 } = filter;
	return workflowListing?.map((d, i) => {
		let row = [];
		row.push(
			tCell((page_no - 1) * pagesize + i + 1),
			tCell(d.name),
			tCell(d.orgName || "-")
			// tCell("-"),
		);
		row.push(<ActionMenu id={i} handlers={actionHandlers} />);

		return row;
	}) || [];
}



export const checkValidation = (payload) => {
	let success = true; // Initialize success to true
	let error = {};

	if (payload.name === "") {
		error.displayName = "This field is required";
		success = false; // Set success to false if there's an error
	}
	if (payload.orgId === "") {
		error.orgId = "This field is required";
		success = false; // Set success to false if there's an error
	}

	error.checkpoints = []; // Initialize checkpoints array outside the loop

	payload.checkpoints.forEach((ele, index) => {
		if (ele.order === undefined || ele.order === "") {
			error.checkpoints[index] = { ...error?.checkpoints[index] };
			error.checkpoints[index].order = "This field is required";
			success = false; // Set success to false if there's an error
		}
		if (!ele.checkpoints || ele.checkpoints.length === 0) {
			error.checkpoints[index] = { ...error?.checkpoints[index] };
			error.checkpoints[index].checkpoints = "This field is required";
			success = false; // Set success to false if there's an error
		}
	});

	// console.log('payload==>', payload);
	// console.log('error', error)


	return { error, success };
};
