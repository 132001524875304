import { left } from "@popperjs/core";
import { Button } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useNavigate } from "react-router";
export default function NotAuthorized() {
	let navigate = useNavigate();
	const onLogOut = () => {
		localStorage.removeItem("primaryColor");
		localStorage.removeItem("selectedTheme");
		localStorage.removeItem("secretkey");
		localStorage.removeItem("favicon");
		localStorage.removeItem("title");
		localStorage.removeItem("logo");
		localStorage.removeItem("project");
		window.location.reload();
	};
	return (
		<div style={{ height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor: "var(--background-color)" }}>
			<h1 style={{ margin: "2rem auto", width: "50%", color: "var(--text-color)" }}>You are not authorized to access this page, kindly contact your admin to enable permissions.</h1>

			<div className="d-flex" style={{ gap: "1.5rem", width: "30%" }}>
				<Button onClick={() => navigate(-1)} text={"Go Back"} style={{ ...buttonClasses.lynkitOrangeEmpty }} />
				<Button onClick={onLogOut} text={"Log Out"} style={{ ...buttonClasses.lynkitOrangeEmpty }} />
			</div>
		</div>
	);
}
