import { createTheme, ThemeProvider } from '@mui/material/styles';

export const autocompleteTheme = createTheme({
	components: {
		MuiAutocomplete: {
			styleOverrides: {
				input: {
					color: 'var(--text-color)',
				},
				clearIndicator: {
					color: 'var(--text-color)',
				},
				popupIndicator: {
					color: 'var(--text-color)',
				},
				listbox: {
					borderRadius: '5px',
					backgroundColor: 'var(--background-color)',
				},
				tag : {
					backgroundColor: '#e7e8e9',
				},
				option: {

					'&[aria-selected="true"]': {
						color: 'var(--text-color)',
						backgroundColor: 'var(--option-hover-color) !important',
					},
					'&:hover': {
						backgroundColor: 'var(--option-hover-color) !important',
					},
					backgroundColor: 'var(--background-color)',
				},
			},
		},
	},
});

export const DateTimePickerTheme = createTheme({
	components: {
		MuiPickersLayout: {
			styleOverrides: {
				root: {
					backgroundColor: 'var(--table-body-bg-color)',
					color: 'var(--text-color)',
					borderRadius: "10px",
					// border: '1px solid white',
					boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',

					'& .MuiDayCalendar-weekDayLabel': {
						color: 'var(--text-color)',
					},

					'& .MuiPickersDay-root': {
						color: 'var(--text-color)',
					},
					'& .MuiPickersDay-root.MuiPickersDay-today': {
						color: 'var(--text-color)',
						border: '1px solid #aaabaf'
					},
					'& .MuiPickersYear-yearButton.Mui-selected': {
						backgroundColor: 'var(--primary-color) !important',
					},
					'& .MuiPickersDay-root.Mui-selected': {
						backgroundColor: 'var(--primary-color) !important',
					},
					'& .MuiPickersDay-root.Mui-selected:hover': {
						backgroundColor: 'var(--primary-color) !important',
					},

					'& .MuiMultiSectionDigitalClockSection-item.Mui-selected': {
						backgroundColor: 'var(--primary-color)'
					},
					'& .MuiMultiSectionDigitalClockSection-item.Mui-selected:hover': {
						backgroundColor: 'var(--primary-color)'
					},
					'& .MuiSvgIcon-root': {
						color: '#aaabaf',
					}
				},
			}
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					'& .MuiInputBase-root': {
						color: 'var(--text-color)',
						borderRadius: "10px",
						// border: '1px solid var(--input-border-color)'
					},
					'& .MuiInputLabel-root': {
						color: '#aaabaf',
						// backgroundColor : 'var(--background-color)'
					},
					'& .MuiSvgIcon-root': {
						color: '#aaabaf',
					},
					"& label": {
						color: "#aaabaf",
					},
					"& label.Mui-focused": {
						color: "var(--primary-color)",
					},
					"& .MuiOutlinedInput-root": {
						"& fieldset": {
							borderColor: "var(--input-border-color)",
						},
						"&:hover > fieldset ": {
							borderColor: "var(--input-border-hover-color)",
							borderWidth: 1,
						},
						"&.Mui-focused fieldset": {
							borderColor: "var(--primary-color)",
							borderWidth: 1,
						},
						"& input::placeholder": {
							color: "var(--input-placeholder-color)", // Placeholder color
						},
					},
				},
			},
		},
		MuiDialogActions: {
			styleOverrides: {
				root: {
					'& .MuiButtonBase-root' : {
						color: 'var(--primary-color)'
					}
				}
			}
		}
	},
});


export const inputClasses = {
	textField: {
		// boxShadow: "0px 4px 4px 0px #00000040",
		borderRadius: "14px",
		fontSize: "16px",
		lineHeight: "22px",
		fontWeight: "400",
		color: 'var(--text-color)',
		"& label": {
			color: "#aaabaf",
		},
		"& label.Mui-focused": {
			color: "var(--primary-color)",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset" : {
				borderColor: "var(--input-border-color)",
			},
			"&:hover > fieldset ": {
				borderColor: "var(--input-border-hover-color)",
				borderWidth: 1,
			},
			"&.Mui-focused fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
			"& input::placeholder": {
				color: "var(--input-placeholder-color)", // Placeholder color
			},
		},
	},
	checkbox: {
		color: "var(--primary-color)",
		"&.Mui-checked": {
			color: "var(--primary-color)",
		},
		
	},
	shadowField: {
		// boxShadow: "0px 4px 4px 0px #00000040",
		borderRadius: "12px",
		borderColor: "var(--input-border-color)",
		fontSize: "14px",
		lineHeight: "19px",
		fontWeight: "400",
		color: 'var(--text-color)',
		"& label.Mui-focused": {
			color: "var(--primary-color)",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
		},
	},
	themeBoundary: {
		"& label.Mui-focused": {
			color: "var(--primary-color)",
		},
		"& .MuiFormLabel-root": {
			fontSize: "14px",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
			"&:hover fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
		},
	},
	filterField: {
		boxShadow: "0px 4px 4px 0px #00000040",
		borderRadius: "12px",
		borderColor: "var(--input-border-color)",
		fontSize: "14px",
		lineHeight: "19px",
		fontWeight: "400",
		color: "#455A64",
		"& input::placeholder": {
			fontSize: "14px",
		},
		"& label.Mui-focused": {
			color: "var(--primary-color)",
		},
		"& .MuiOutlinedInput-root": {
			"&.Mui-focused fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
			"&:hover fieldset": {
				borderColor: "var(--primary-color)",
				borderWidth: 1,
			},
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: "var(--primary-color)",
		},
		"& .MuiFilledInput-underline:after": {
			borderBottomColor: "var(--primary-color)",
		},
		"&:hover > .MuiOutlinedInput-notchedOutline ": {
			borderColor: "var(--primary-color)",
			borderWidth: 1,
		},
		"&:focused > .MuiOutlinedInput-notchedOutline ": {
			borderColor: "var(--primary-color)",
			borderWidth: 1,
		},
	},
};

export const cardClasses = {
	basic: {
		borderRadius: "15px",
		border: "1px solid #0000002B",
		boxShadow: "0px 4px 4px 0px #00000040",
	},
};

export const textClasses = {
	t12n: {
		fontSize: "12px",
		fontWeight: "400",
		lineHeight: "16px",
	},
	t13n: {
		fontSize: "13px",
		fontWeight: "400",
		lineHeight: "16px",
		margin: 'auto'
	},
	t14n: {
		fontSize: "13px",
		fontWeight: "400",
		lineHeight: "16px",
		fontFamily: "Nunito",
	},
	normalText: {
		fontSize: "14px",
		fontWeight: "400",
		lineHeight: "17px",
	},
	boldText: {
		fontSize: "14px",
		fontWeight: "600",
		lineHeight: "20px",
		color: "var(--gray-color)",
	},
	t16n: {
		fontSize: "18px",
		fontWeight: "400",
		lineHeight: "24px",
		fontFamily: "Nunito",
	},
	t20n: {
		fontSize: "20px",
		fontWeight: "400",
		lineHeight: "27px",
	},
	cardTitle: {
		fontSize: "24px",
		lineHeight: "33px",
		fontFamily: "Nunito",
		fontWeight: "600",
	},
	pageTitle: {
		fontSize: "32px",
		lineHeight: "44px",
		fontWeight: "600",
		color: "#FF7200",
	},
	td: {
		whiteSpace: "nowrap !important",
		padding: "7px",
	},
};

export const baseButton = {
	border: "1px solid #D9D9D9",
	borderRadius: "12px",
	boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
	fontWeight: "600",
	fontSize: "16px",
	lineHeight: "22px",
	minHeight: "48px",
	textTransform: "none",
};

export const buttonClasses = {
	small: {
		borderRadius: "8px",
		fontSize: "12px",
		fontWeight: "400",
		lineHeight: "16px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#f36c00",
			color: "white",
			borderColor: "#f36c00",
		},
		cursor: "pointer",
	},
	normal: {
		border: "1px solid #D9D9D9",
		borderRadius: "12px",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		fontWeight: "600",
		fontSize: "16px",
		lineHeight: "22px",
		minHeight: "48px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#f36c00",
			color: "white",
			borderColor: "#f36c00",
		},
	},
	orange: {
		...baseButton,
		backgroundColor: "var(--primary-color)",
		color: "white",
		"&:hover": {
			backgroundColor: "#f36c00",
			color: "white",
		},
	},
	smallOrange: {
		border: "1px solid #D9D9D9",
		borderRadius: "4px",
		boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		fontWeight: "400",
		fontSize: "10px",
		lineHeight: "27px",
		maxHeight: "24px",
		maxWidth: "24px",
		textTransform: "none",
		backgroundColor: "var(--primary-color)",
		color: "white",
		padding: "2px",
		"&:hover": {
			backgroundColor: "#f36c00",
			color: "white",
			borderColor: "#f36c00",
		},
	},
	outlined: {
		border: "1px solid var(--primary-color)",
		color: "var(--primary-color)",
		borderRadius: "12px",
		backgroundColor: "#FFFFFF",
		fontSize: "14px",
		lineHeight: "19px",
		fontWeight: "400",
		textTransform: "none",
		minHeight: "40px",
		"&:hover": {
			border: "1px solid var(--primary-color)",
			backgroundColor: "white",
			boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
		},
	},
	lynkitOrangeEmpty: {
		backgroundColor: "transparent",
		borderRadius: "8px",
		color: "var(--primary-color)",
		border: `var(--outlinebtn-border-width) solid var(--primary-color)`,
		// fontSize: "14px",
		// fontWeight: 'bold',
		textTransform: "none",
		"&:hover": {
			border: `1px solid var(--primary-color)`,
			backgroundColor: "var(--primary-color)",
			color: "#fff",
		},
	},
	lynkitOrangeFill: {
		backgroundColor: "var(--primary-color)",
		borderRadius: "8px",
		border: `1px solid var(--primary-color)`,
		// border: `1px solid #fff`,
		textTransform: "none",
		color: "#fff",
		"&:hover": {
			backgroundColor: "var(--primary-color)",
			border: `1px solid var(--primary-color)`,
			// color: "#FF7200",
		},
		"&:disabled": {
			border: 0,
		},
	},
	lynkitBlackEmpty: {
		backgroundColor: "var(--background-color)",
		borderRadius: "8px",
		color: "var(--lynkitBlackEmpty-color)",
		border: `var(--outlinebtn-border-width) solid var(--lynkitBlackEmpty-border-color)`,
		// fontSize: "14px",
		// fontWeight: 'bold',
		textTransform: "none",
		"&:hover": {
			backgroundColor: "var(--lynkitBlackEmpty-border-Hvr-bg-Color)",
			border: `var(--outlinebtn-border-width) solid var(--lynkitBlackEmpty-border-Hvr-color)`,
			color: "var(--lynkitBlackEmpty-Hvr-color)",
		},
	},
	lynkitBlackFill: {
		backgroundColor: 'var(--lynkitBlackFill-bg-color)',
		borderRadius: "8px",
		color: "#ffffff",
		border: `1px solid #000`,
		height: '35px',
		textTransform: "none",
		"&:hover": {
			backgroundColor: "var(--lynkitBlackFill-bg-Hvr-color)",
			// border: `1px solid #000`,
			color: "#fff",
		},
	},
	lynkitGreenEmpty: {
		backgroundColor: "#fff",
		borderRadius: "8px",
		color: "green",
		border: `1px solid green`,
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#fff",
			border: `1px solid green`,
		},
	},
	lynkitGreenFill: {
		backgroundColor: "green",
		borderRadius: "8px",
		border: `1px solid green`,
		textTransform: "none",
		color: "#fff",
		"&:hover": {
			backgroundColor: "green",
			border: `1px solid green`,
		},
		"&:disabled": {
			border: 0,
		},
	},
	lynkitRedFill: {
		backgroundColor: "red",
		borderRadius: "8px",
		border: `1px solid red`,
		textTransform: "none",
		color: "#fff",
		"&:hover": {
			backgroundColor: "red",
			border: `1px solid red`,
		},
		"&:disabled": {
			border: 0,
		},
	},
	lynkitYellowFill: {
		backgroundColor: "#dbdc00",
		borderRadius: "8px",
		border: `1px solid #dbdc00`,
		textTransform: "none",
		color: "#fff",
		"&:hover": {
			backgroundColor: "#dbdc00",
			border: `1px solid #dbdc00`,
		},
		"&:disabled": {
			border: 0,
		},
	},
	viewFile: {
		...textClasses.t14n,
		textDecoration: "underline",
		color: "#FF7200",
	},
};

export default {
	themeOrange: "var(--primary-color)", // primary
	themeLightOrange: "#f3d7c0", //primarylight
	themeOrangeShadow: "#c95c03", //primaryshadow
	themeBlack: "#000000", //black
	themeGray: "#D9D9D9", // gray
	themeGreen: "#3E621E", // green
	themeRed: "#FF0101", // red
};
