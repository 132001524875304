import React, { useState, useRef } from "react";
import CreateCheckpoint from "./CreateCheckpoint";
import "./CheckpointIndex.css";
import { Button, ActionMenu, GenericTable, tCell, toastMessage } from "../utils";
import theme, { buttonClasses } from "../utils/theme";
import Genericfilter from "../utils/Genericfilter";
import { useCheckpoints, useOrgs, useUserDetail } from "../../services/hooks";
import { debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

export default function CheckpointIndex() {
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();

	let filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "displayName",
			type: "text",
			label: "Display Name",
			placeholder: "Display Name",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields = [
			{
				inputType: "dropdown",
				id: "orgId",
				name: "orgId",
				label: "Organization",
				option: orgOptions,
			},
		];
	}

	const [addCheckPoint, setAddCheckPoint] = useState(false);

	const [updatedData, setUpdatedData] = useState({});

	const isDuplicateRef = useRef(false);

	const filterRef = useRef(defaultFilter);
	const { page_no = 1, pagesize = 10 } = filterRef.current;

	const { checkpoints, count, isLoading, getCheckpoints } = useCheckpoints(filterRef.current);

	const handleDeviceView = (e, index) => {
		const { latitude, longitude } = checkpoints[index];
		if (latitude != null && longitude != null && latitude !== undefined && longitude !== undefined) {
			// Redirect to Google Maps with the specified latitude and longitude
			window.open(`https://www.google.com/maps?q=${latitude},${longitude}`, "_blank");
		} else {
			toastMessage(false, "Location is not assigned to this checkpoint.");
		}
	};

	let actionHandlers = {};
	actionHandlers.edit = (id) => {
		setUpdatedData(checkpoints[id]);
		setAddCheckPoint(!addCheckPoint);
	};
	actionHandlers.duplicate = (index) => {
		setUpdatedData(checkpoints[index]);
		setAddCheckPoint(!addCheckPoint);
		isDuplicateRef.current = true;
	};

	let header = ["S.no", "Checkpoint Number", "Display Name", "Organization", "Checkpoint Type", "Send Approval", "Dependent Approval", "Action"];

	const rows =
		checkpoints?.map((_, i) => {
			let row = [];
			row.push(
				tCell((page_no - 1) * pagesize + i + 1),
				tCell(_.checkpointNumber),
				tCell(_.displayName, theme.themeOrange, "pointer", handleDeviceView, i),
				tCell(_.orgName),
				tCell(_.checkpointType),
				tCell(_.sendApproval ? "Yes" : "No"),
				tCell(_.dependentApproval ? "Yes" : "No")
			);
			row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getCheckpoints(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getCheckpoints(filterRef.current);
	};

	const handleFilterChange = (filter) => {
		if (Object.keys(filter).length == 0) {
			return;
		}
		const allowed = ["displayName"];
		let clearFlag = true;
		if (filter?.orgId?.value) {
			filterRef.current = { ...filterRef.current, orgId: filter.orgId.value };
			clearFlag = false;
		} else {
			for (let key in filter) {
				if (allowed.includes(key) && filter[key] !== "") {
					clearFlag = false;
					filterRef.current = { ...filterRef.current, searchBy: key, search: filter[key] };
				}
			}
		}
		if (clearFlag) {
			let { page_no, pagesize } = filterRef.current;
			filterRef.current = { pagesize, page_no };
		}
		getCheckpoints(filterRef.current);
		return;
	};

	return (
		<>
			<div className="checkpoint_panel">
				<div className="checkpoint_heading">{addCheckPoint && Object.keys(updatedData)?.length > 0 ? "Update Checkpoint" : addCheckPoint ? "Add New Checkpoint" : "Checkpoint"}</div>
				<div>
					<Button
						onClick={() => {
							setAddCheckPoint(!addCheckPoint);
							setUpdatedData({});
							if (addCheckPoint) {
								isDuplicateRef.current = false;
							}
						}}
						text={addCheckPoint ? "Go Back" : "Add Checkpoint"}
						style={buttonClasses.lynkitOrangeEmpty}
					/>
				</div>
			</div>
			{addCheckPoint ? (
				<CreateCheckpoint
					updatedData={updatedData}
					isDuplicate={isDuplicateRef.current}
					addCheckPoint={addCheckPoint}
					setAddCheckPoint={setAddCheckPoint}
					reset={() => {
						isDuplicateRef.current = false;
						getCheckpoints(filterRef.current);
					}}
				/>
			) : (
				<>
					<GenericstatsV2 statsFor={"checkpoint"} />
					<div className="cust-row flex-algn-cent">
						<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
					</div>
					<div>
						<GenericTable
							header={header}
							rows={rows}
							pageCount={count}
							pageNo={page_no}
							limit={pagesize}
							onPageChange={onPageChange}
							onPageSizeChange={onPageSizeChange}
							isLoading={isLoading}
						/>
					</div>
				</>
			)}
		</>
	);
}
