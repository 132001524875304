import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import {
	Dropdown,
	GenericTable,
	Input,
	toastMessage,
	Button,
	tCell,
	ActionMenu,
	Dialog,
} from "../utils/index";
import theme, { buttonClasses } from "../utils/theme";
import { useVehicles } from "../../services/hooks";
import { formatDate } from "../../services/functions";
import { useAddVehicleMutation } from "../../api/vmsSlice";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const VehicleManagement = () => {
	const [isModal, setIsModal] = useState(false);
	const onModalClose = () => setIsModal(false);
	const onModalOpen = () => setIsModal(true);

	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { vehicles, count, isLoading, message } = useVehicles(
		filterRef.current
	);

	const actionHandlers = {
		// edit: (id) => {
		// 	console.log(id, "edit");
		// },
		// view: (id) => {
		// 	console.log(id, "view");
		// },
		// delete: (id) => {
		// 	console.log(id, "delete");
		// },
	};
	const header = ["S.no", "Vehicle Name", "Vehicle No.", "Action"];

	const rows =
		vehicles?.map((d, i) => {
			let row = [];
			row.push(
				tCell((page_no - 1) * pagesize + i + 1),
				tCell(d.vehicle_name, theme.themeOrange, "pointer"),
				tCell(d.vehicle_no),
				<ActionMenu id={i} handlers={actionHandlers} />
			);
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		refetch();
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		refetch();
	};

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Vehicle Management</h1>
				</div>
				<div className="cust-col-5 flex-jc-end">
					<Link className="a_tag_no">
						<Button
							onClick={onModalOpen}
							text="Add Vehicle"
							style={buttonClasses.lynkitOrangeEmpty}
						/>
					</Link>
				</div>
			</div>
			<hr className="bgg" />
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count || vehicles?.length}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			<AddVehicle open={isModal} onClose={onModalClose} />
		</div>
	);
};

export default VehicleManagement;

const defaultForm = {
	vehicleNumber: "",
	vehicleType: "",
	driverName: "",
	driverNumber: "",
};
function AddVehicle({ onClose, open }) {
	const [formData, setFormData] = useState(defaultForm);
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	// const onDropdownChange = (option) => {
	// 	let inputData = { ...formData };
	// 	inputData.orgId = option.value;
	// 	setFormData(inputData);
	// };
	const [handleSubmit] = useAddVehicleMutation();
	const handleFormSubmit = async () => {
		const {
			data: { error, message },
		} = await handleSubmit(formData);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			handleClose();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	return (
		<Dialog
			open={open}
			handleClose={handleClose}
			handleSubmit={handleFormSubmit}
			title={"Add Vehicle"}
		>
			<form className="">
				<div className="form-inputs mt-2">
					<Input
						label="Vehicle Number"
						placeholder="Enter Vehicle Number"
						name="vehicleNumber"
						value={formData.vehicleNumber}
						onChange={handleInput}
					/>
					{/* could be dropdown */}
					<Input
						label="Vehicle Type"
						placeholder="Enter Vehicle Type"
						name="vehicleType"
						value={formData.vehicleType}
						onChange={handleInput}
					/>
					{/* could be dropdown */}
					<Input
						label="Driver Name"
						placeholder="Enter Driver Name"
						name="driverName"
						value={formData.driverName}
						onChange={handleInput}
					/>
					<Input
						type="number"
						label="Driver Number"
						placeholder="Enter Driver Number"
						name="driverNumber"
						value={formData.driverNumber}
						onChange={handleInput}
					/>
					{/* <Dropdown
						id="state"
						name="state"
						label="Organization Name"
						onChange={onDropdownChange}
						options={[]}
						// value={{
						// 	value: formData.orgId,
						// 	label:
						// 		orgs.find((_) => _._id == formData.orgId)
						// 			?.name || "",
						// }}
					/> */}
				</div>
			</form>
		</Dialog>
	);
}
