import React from 'react'

export default function PreviewFile({
    file
}) {
    const fileType = getFileTypeFromBase64(file);
    // console.log('getFileTypeFromBase64', file, fileType);
    if (fileType === 'jpeg' || fileType === 'png' || fileType === 'gif' || fileType === 'webp' || fileType === 'bmp') {
        return (
            <div style={{ marginTop: '10px', width: '100%', height: '100px', border: '2px solid #ddd', borderRadius: '10px', position: 'relative' }}>
                <div style={{ backgroundColor: 'var(--primary-color)', color: 'white', borderRadius: '10px 10px 0px 0px', paddingLeft: '10px', position: 'absolute', top: 0, left: 0, right: 0 }}>Preview</div>
                <div style={{width: '100%', height: '60px',}}>
                    <img src={file} alt="img" style={{ width: '100%', height: '100%', objectFit: 'contain', borderRadius: '10px', marginTop: '30px' }} />
                </div>
            </div>

        );
    } else if (fileType === 'pdf') {
        return (
            <div style={{ marginTop: '10px', border: '2px solid #ddd', borderRadius: '10px' }}>
                <div style={{ backgroundColor: 'var(--primary-color)', color: 'white', borderRadius: '10px 10px 0px 0px', paddingLeft: '10px' }}>Preview</div>
                <object
                    class="pdf"
                    data={file}
                    overFlowX="hidden"
                    width="100%"
                    height="100">
                </object>
            </div >
        );
    } else {
        return (
            <div style={{ fontSize: '14px', marginTop: '10px', width: '100%', minHeight: '40px', border: '2px solid #ddd', borderRadius: '10px', textAlign : 'center' }}>
                Preview : File type not supported
            </div>
        );

    }
}




function getFileTypeFromBase64(base64String) {
    // Extract the data URL prefix and content
    const matches = base64String.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/) || [];


    // console.log('match', matches)

    if( matches.length > 0){
        if (matches?.length !== 3) {
            throw new Error('Invalid base64 string format');
        }
    
        const contentType = matches[1]; // Extract the content type from the match
        return contentType.split('/')[1]; // Extract the file extension from the content type
    }else{
        return "unknown";
    }

   
}


