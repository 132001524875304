import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "../api/api";


export const setUpStore = (preLoadedState = {}) => {
	return configureStore({
		reducer: {
			[apiSlice.reducerPath]: apiSlice.reducer,
		},
        middleware: (gDM) => gDM().concat(apiSlice.middleware),
		// preLoadedState,
	});
};

export const store = setUpStore();
