import { useProducts, useUserPermissions } from "../../services/hooks";
import { useEffect, useState } from "react";
import { Dropdown, DataNotFound, GenericTable, Loader, SearchDropdown } from "../utils/index";
import { ChartIcon } from "../../services/icons";
import { useDashboardStats } from "../../services/hooks";
import { Navigate } from "react-router-dom";

function Dashboard() {
	const { userPermissions, isFetched } = useUserPermissions();
	// const { userDetail } = useUserDetail();

	const { products } = useProducts({}); // all
	const [productsFilter, setProductsFilter] = useState("");
	const [filter, setFilter] = useState({});
	const { stats, isLoading, getDashboardStats } = useDashboardStats({}); // filter applied here

	// const handleProductFilterChange = (val) => {
	//     setProductsFilter(val);
	//     if (!val) {
	//         getDashboardStats({});
	//     } else {
	//         getDashboardStats({ productId: val.value });
	//     }
	// };
	const handleChange = (value, key) => {
		// console.log('filter on change', { [key]: value })
		setFilter((previous) => {
			return { [key]: value === null ? "" : value };
		});
		if (!value) {
			getDashboardStats({});
		} else {
			getDashboardStats({ [key]: value === null ? "" : value });
		}
	};

	const onhandleClear = () => {
		setFilter({});
		getDashboardStats({});
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getDashboard?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Dashboard (Internal Truck Management System)</h1>
				</div>
				<div className="cust-col-5 flex-jc-end">
					<div className="dashboard-filter-cont">
						{/* <Dropdown
                            id="dashboard-product-filter-dropdown"
                            name="dashboard-product-filter"
                            label="Product"
                            options={products.map((prod) => {
                                return { label: prod.productName, value: prod._id };
                            })}
                            onChange={handleProductFilterChange}
                            value={productsFilter || null}
                        /> */}
						<SearchDropdown
							id={"dashboard-product-filter-dropdown"}
							name="dashboard-product-filter"
							options={[
								{ label: "Product Name", value: "productName" },
								{ label: "Product Categories", value: "productCategory" },
							]}
							label={"Search by"}
							placeholder={"Enter value"}
							value={filter}
							Debounce={true}
							onChange={(value, key) => handleChange(value, key)}
							onhandleClear={onhandleClear}
						/>
					</div>
				</div>
			</div>
			<hr className="bgg" />

			{isLoading ? (
				<Loader size="3rem" height="70vh" />
			) : Object.keys(stats).length ? (
				<div className="dashboard-main">
					{Object.keys(stats)?.map((prodCatId, idx) => (
						<CategoryBox key={idx} productCategory={stats[prodCatId].productCategory} data={stats[prodCatId].stats} />
					))}
				</div>
			) : (
				<DataNotFound />
			)}
		</div>
	);
}

const CategoryBox = ({ productCategory, data }) => {
	let header = ["Product Name", "Target", "Actual"];
	const rows = [];
	data?.forEach((prod) => {
		rows.push([prod.productName, prod.target, prod.actual]);
	});
	return (
		<div className="box">
			<div className="dashboard-box-header">
				<h3 className="dashboard-title" style={{ color: "var(--subheading-text-color)" }}>
					{productCategory}
				</h3>
				<ChartIcon fontSize="large" style={{ color: "var(--text-color)" }} className="sidebar_icon" />
			</div>
			<hr className="bgg" />
			<GenericTable header={header} rows={rows} />
		</div>
	);
};

export default Dashboard;
