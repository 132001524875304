import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ActionMenu, GenericTable, tCell, Button, Dialog, DetailCard, generateDetailRows, toastMessage } from "../utils";
import AddUser from "./AddUser";
import theme, { buttonClasses } from "../utils/theme";
import { useUserDetail, useUserPermissions, useUsers, useOrgs } from "../../services/hooks";
import { capitalizeCamelCase } from "../../services/functions";
import { Navigate } from "react-router-dom/dist";
import { useGetUserDetailMutation } from "../../api/userSlice";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";

const initUserFilter = {
	pagesize: 10,
	page_no: 1,
};

function getUserDetails(data) {
	const dataToShow = ["name", "email", "mobile", "orgName", "userRole", "status"];
	const res = dataToShow.map((key) => ({ label: capitalizeCamelCase(key), value: data[key] || "-" }));
	return res;
}
const UserManagement = () => {
	const { userPermissions, isFetched } = useUserPermissions();
	const [fetchUserDetail] = useGetUserDetailMutation();
	const [isModal, setIsModal] = useState(false);
	const [filterApplied, setFilterApplied] = useState(false);
	// const [filterField, setfilterField] = useState(filterFields);
	const onModalClose = () => setIsModal(false);
	const onModalOpen = () => setIsModal(true);

	const updateDateRef = useRef(null);

	const { userDetail } = useUserDetail();
	const { userType } = userDetail || {};

	//  make into lazy query
	const { orgOptions } = useOrgs();
	let filterFields = [
		{
			inputType: "textfield",
			id: "2",
			name: "name",
			type: "text",
			label: "Name",
			placeholder: "Name",
			readOnly: false,
			error: "",
		},
		{
			inputType: "textfield",
			id: "3",
			name: "email",
			type: "text",
			label: "Email",
			placeholder: "Email",
			readOnly: false,
			error: "",
		},
		{
			inputType: "textfield",
			id: "4",
			name: "mobile",
			type: "text",
			label: "Mobile number",
			placeholder: "Mobile number",
			readOnly: false,
			error: "",
		},
	];

	if (userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}
	// console.log('userdetail', useUserDetail());
	// console.log('userpermission', useUserPermissions());

	const filterRef = useRef(initUserFilter);
	const { page_no, pagesize } = filterRef.current;
	const { users, count, isLoading, getUsers } = useUsers(filterRef.current);

	const [isOpen, setIsOpen] = useState(false);
	const [detail, setDetail] = useState(null);
	const handleDetailClose = () => {
		setIsOpen(false);
		setDetail(null);
	};

	const handleDeviceView = (e, index) => {
		// console.log(index, "view", devices[index]);
		setDetail(generateDetailRows(getUserDetails(users[index])));
		setIsOpen(true);
	};

	let actionHandlers = {};
	if (userPermissions?.updateUser?.value) {
		actionHandlers.edit = async (id) => {
			// console.log(id, "edit");
			const { _id } = users[id];

			const {
				data: { data, error, message },
			} = await fetchUserDetail({ userId: _id });
			if (error) {
				toastMessage(error, message);
			} else {
				setIsModal(true);
				updateDateRef.current = data;
			}
		};
	}
	// console.log(updateDateRef);
	if (userPermissions?.deleteUser?.value) {
		// actionHandlers.delete = async (id) => {
		// 	// console.log(id, "delete");
		// };
	}
	let header = ["S.no", "Customer Name", "Email", "User Role", "Status"];
	if (userType == "superAdmin") {
		header.splice(2, 0, "Org Name");
	}
	if (userPermissions?.updateUser?.value || userPermissions?.deleteUser?.value) {
		header.push("Action");
	}

	const rows =
		users?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.name, theme.themeOrange, "pointer", handleDeviceView, i), tCell(d.email), tCell(d.userRole), tCell(d.status));
			if (userType == "superAdmin") {
				row.splice(2, 0, tCell(d.orgName));
			}
			if (userPermissions?.updateUser?.value || userPermissions?.deleteUser?.value) {
				row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getUsers(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getUsers(filterRef.current);
	};
	const onUpdateSuccess = () => {
		getUsers(filterRef.current);
	};
	const handleFilterChange = (filter) => {
		// console.log(filter);
		if ((filter?.orgId != '' || filter?.name != '' || filter?.email != '' || filter?.mobile != '') && Object.keys(filter).length > 0) {
			// console.log("filter on change-device", filter);
			// Update orgId property safely
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getUsers(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.orgId === '' && filter?.name === '' && filter?.email === '' && filter?.mobile === '' && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log("without-filter on change-device", filter);
			getUsers(initUserFilter);
			setFilterApplied(false);
		}
	};
	

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getUsers?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">User Management</h1>
				</div>
				{userPermissions?.addUser?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link className="a_tag_no ">
							<Button onClick={() => {
								updateDateRef.current = null
								onModalOpen()
							}}
								text="Add User"
								style={buttonClasses.lynkitOrangeEmpty}
							/>
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={'users'}/>
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>

			<div className="main_content">
				<GenericTable
					header={header}
					rows={rows}
					pageCount={count}
					pageNo={filterRef.current.page_no}
					limit={filterRef.current.pagesize}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					isLoading={isLoading}
				/>
				<DetailCard isOpen={isOpen} onClose={handleDetailClose} title={"User Details"}>
					{detail}
				</DetailCard>
			</div>

			{isModal ? <AddUser open={isModal} onClose={onModalClose} updateData={updateDateRef.current} onUpdateSuccess={onUpdateSuccess} /> : null}
		</div>
	);
};

export default UserManagement;
