import { Button } from "../utils";
import { buttonClasses } from "../utils/theme";

export default function NotFound() {
	const onLogOut = () => {
		localStorage.removeItem("primaryColor");
		localStorage.removeItem("selectedTheme");
		localStorage.removeItem("secretkey");
		localStorage.removeItem("favicon");
		localStorage.removeItem("title");
		localStorage.removeItem("logo");
		localStorage.removeItem("project");
		window.location.reload();
	};

	return (
		<div style={{ border: "1px solid red", height: "100vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
			<h1 style={{ margin: "2rem auto", width: "60%" }}>Page Not Found. Please check the entered URL.</h1>
			<Button onClick={onLogOut} text={"Log Out"} style={{ ...buttonClasses.lynkitOrangeEmpty, width:"10%" }} />
		</div>
	);
}
