import React, { useRef, useState } from "react";

import { GenericTable, Input, toastMessage, Button, tCell, ActionMenu, Dialog, Dropdown } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { useOrgs, useSuppliers, useUserDetail, useUserPermissions } from "../../services/hooks";
import { fakeFileInput, formatDate } from "../../services/functions";
import { useAddSupplierMutation, useDeleteSupplierMutation, useUpdateSupplierMutation, useUploadBulkSupplierMutation } from "../../api/vmsSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";

const SAMPLE_EXCEL_PATH = "/sample_excels/supplier_sample.xlsx";
const EXCEL_TYPE = "supplier";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const SupplierManagement = () => {
	const { userPermissions, isFetched } = useUserPermissions();

	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [isModal, setIsModal] = useState(false);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};

	const updateDataRef = useRef(null);
	const [filterApplied, setFilterApplied] = useState(false);
	const filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "supplierName",
			type: "text",
			label: "Supplier Name",
			placeholder: "Supplier Name",
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "textfield",
			id: "2",
			name: "supplierId",
			type: "text",
			label: "Supplier id",
			placeholder: "Supplier id",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { suppliers, count, isLoading, getSuppliers } = useSuppliers(filterRef.current);

	const [handleDelete] = useDeleteSupplierMutation();

	let actionHandlers = {};
	if (userPermissions?.updateProductCategory?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = suppliers[id];
			setIsModal(true);
		};
	}
	if (userPermissions?.deleteProductCategory?.value) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = suppliers[id];
			const payload = { deleteId: data._id };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}

	let header = ["S.no", "Supplier Name", "Supplier Id", "Created On"];
	if (userDetail?.userType == "superAdmin") {
		header.push("Org Name");
	}
	if (userPermissions?.updateSupplier?.value || userPermissions?.deleteSupplier?.value) {
		header.push("Action");
	}

	const rows =
		suppliers?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.supplierName), tCell(d.supplierId), tCell(formatDate(d.created_on)));

			if (userDetail?.userType == "superAdmin") {
				row.push(tCell(d.orgName));
			}
			if (userPermissions?.updateSupplier?.value || userPermissions?.deleteSupplier?.value) {
				row.push(
					<div style={{ paddingLeft: "0.5rem" }}>
						<ActionMenu id={i} handlers={actionHandlers} />
					</div>
				);
			}

			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getSuppliers(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getSuppliers(filterRef.current);
	};
	const onUpdateSuccess = () => {
		getSuppliers(filterRef.current);
	};
	const [handleUploadExcel] = useUploadBulkSupplierMutation();
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			const {
				data: { error, message },
			} = await handleUploadExcel(formData);
			if (error) {
				toastMessage(false, message);
			} else {
				toastMessage(true, message);
				onUpdateSuccess();
			}
		});
	};
	const handleFilterChange = (filter) => {
		if ((filter?.supplierName != "" || filter?.supplierId != "") && Object.keys(filter).length > 0) {
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getSuppliers(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.supplierName === "" && filter?.supplierId === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			getSuppliers(defaultFilter);
			setFilterApplied(false);
		}
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getSupplier?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem">
				<Grid item xl={6} lg={6} md={5} sm={12} xs={12}>
					<h1 className="title">Supplier Management</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={12} xs={12}>
					<Grid container spacing="0.5rem">
						{userPermissions?.addSupplier?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onModalOpen} text="Add Supplier" style={buttonClasses.lynkitOrangeEmpty} />
							</Grid>
						) : null}
						{userPermissions?.addSupplier?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
							</Grid>
						) : null}
						{userPermissions?.addSupplier?.value ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<a href={SAMPLE_EXCEL_PATH} download>
									<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
								</a>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>

			<hr className="bgg" />
			<GenericstatsV2 statsFor={"supplier"} />
			<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count || suppliers?.length}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>

			{isModal && <AddSupplier open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</div>
	);
};

export default SupplierManagement;

const defaultForm = {
	supplierName: "",
	supplierId: "",
	updateId: undefined, //_id for update case
	orgId: undefined,
};

function fillUpdateData(data) {
	return {
		supplierName: data.supplierName,
		supplierId: data.supplierId,
		updateId: data._id,
		orgId: data.orgId,
	};
}

function AddSupplier({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();

	const orgValue = orgOptions.find((_) => _.value == formData.orgId);

	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const onDropdownChange = (option, name) => {
		handleInput({ target: { name, value: option?.value || "" } });
	};

	const [handleAdd, { isLoading: isAdding }] = useAddSupplierMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateSupplierMutation();
	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label };

		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};
	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.supplierName) error.supplierName = "Supplier name is required";
		if (!formData.supplierId) error.supplierId = "Supplier ID is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	return (
		<Dialog size="xs" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={updateData ? "Update Supplier" : "Add Supplier"} isSubmitDisabled={isAdding || isUpdating}>
			<form className="">
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} error={error.orgId} required />
					) : null}
					<Input label="Supplier Name" placeholder="Enter Supplier Name" name="supplierName" value={formData.supplierName} onChange={handleInput} error={error.supplierName} required />
					<Input label="Supplier ID" placeholder="Enter Supplier ID" name="supplierId" value={formData.supplierId} onChange={handleInput} error={error.supplierId} required />
				</div>
			</form>
		</Dialog>
	);
}
