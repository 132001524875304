import React, { useEffect, useState, useRef } from "react";
import { Input, Dropdown, Button, Checkbox } from "../utils";
import { buttonClasses } from "../utils/theme";
import { useOrgs, useUserDetail } from "../../services/hooks";
import { useProductCategories, useProducts, useSuppliers } from "../../services/hooks";
import { useAddCheckPointMutation, useGetApiListDetailQuery, useUpdateCheckPointMutation, useGetCheckpointTypeMutation } from "../../api/vmsSlice";
import { toastMessage } from "../utils";
import "./CheckpointIndex.css";
import { useGetTemplateListQuery } from "../../api/vmsSlice";
import { capitalizeCamelCase } from "../../services/functions";
import { act } from "react";

const defaultFilter = {
	// pagesize: 40,
	// page_no: 1,
};


const defaultSubfilterObj = {
	key: "",
	selfFilter: true,
	formula: {
		operator: "object",
		eval: [
			{
				key: "",
				isDynamic: false,
				value: "",
			},
		],
	},
}

const defaultEvalObj = {
	"key": "",
	"isDynamic": false,
	"value": "",
}

const defaultFilterObj = {
	key: "",
	selfFilter: true,
	formula: {
		operator: "object",
		eval: [
			{
				key: "",
				isDynamic: false,
				value: "",
			},
		],
	},
}

const itemTypeOption = [
	{ label: "Product", value: "product" },
	{ label: "Product Category", value: "productCategory" },
	{ label: "Supplier", value: "supplier" },
	{ label: "Custom", value: "custom" },
];

export default function ListingConfigration({ listingConfig, setListingConfig, productCategories, products, suppliers }) {
	// const filterRef = useRef(defaultFilter);
	// const { productCategories, count, isLoading, message, getProdCats } = useProductCategories(filterRef.current);
	// const { products, count: productCount, getProducts } = useProducts(filterRef.current);
	// const { suppliers, count: supplierCount, getSuppliers } = useSuppliers(filterRef.current);



	const setValueAtPath = (obj, path, value, actionFor, keyName) => {
		// console.log('newConfig-obj-parent', obj, path, value, actionFor, keyName);
		const [head, ...rest] = path;
		if (rest.length === 0) {
			// console.log('newConfig-obj', obj, head, value, actionFor, keyName);
			if (!actionFor) {
				if (head === 'isDynamic' && value === true) {
					obj[head] = value;
					obj["value"] = [],
						obj["itemType"] = null
				} else if (head === 'operator') {
					obj[head] = value;
					if (value === 'object' || value === false) {
						obj["eval"] = [defaultEvalObj]
						delete obj["subFilter"]
					}
					if (value === 'array' || value === true) {
						delete obj["eval"];
						obj["subFilter"] = [defaultSubfilterObj]
					}
				} else {
					obj[head] = !value ? "" : value;
				}
			} else {
				if (actionFor === 'eval' && keyName === 'isDynamic' && value === false) {
					// console.log('aaaaaaa===>', obj, head, value);
					obj[head]["isDynamic"] = value;
					obj[head]["value"] = "";
					delete obj[head]["itemType"];
				}
				if (actionFor === 'eval' && keyName === 'itemType') {
					// console.log('aaaaaaa===>', obj, head, value);
					obj[head]["itemType"] = value;
					obj[head]["value"] = []
					if (value?.value === 'custom') {
						obj[head]["value"] = '';
					}
				}
				if (actionFor === 'eval' && keyName === 'value') {
					// console.log('aaaaaaa===>', obj, head, value);
					if (obj[head]["itemType"].value !== 'custom') {
						obj[head]["value"] = value.map(item => item.value);
					} else {
						obj[head]["value"] = value;
					}

				}
				if (actionFor === 'eval' && keyName === 'key') {
					// console.log('aaaaaaa===>', obj, head, value);
					obj[head]["key"] = !value ? "" : value;
				}
			}

		} else {
			if (!obj[head]) obj[head] = {};
			setValueAtPath(obj[head], rest, value, actionFor, keyName);
		}
	};

	const handleInputChange = (path, newValue, index, actionFor, keyName) => {
		// console.log(path, newValue, index, actionFor, keyName);
		setListingConfig((prevConfig) => {
			const newConfig = JSON.parse(JSON.stringify(prevConfig)); // Deep copy
			// console.log('newConfig==1', newConfig);
			setValueAtPath(newConfig[index], path, newValue, actionFor, keyName);
			// console.log('newConfig==2', newConfig);
			return newConfig;
		});
	};

	const addListing = () => {
		const logList = [...listingConfig];
		logList.push({
			order: "",
			label: "",
			type: "",
			filter: [
				{
					key: "",
					selfFilter: true,
					formula: {
						operator: "object",
						eval: [
							{
								key: "",
								isDynamic: false,
								value: "",
							},
						],
					},
				},
			],
		});
		setListingConfig(logList);
	}

	const RemoveListing = (index) => {
		const logList = [...listingConfig];
		logList.splice(index, 1);
		setListingConfig(logList);
	};

	const setArrayAtPath = (obj, path) => {
		// console.log('newConfig-obj', obj, path, path.length);
		const [head, ...rest] = path;
		if (rest.length === 0) {

			if (head === 'filter' || head === 'subFilter') {
				// console.log('newConfig-head==>', head, obj[head]);
				obj[head].push(defaultFilterObj);
			}
			if (head === 'eval') {
				// console.log('newConfig-head==>', head, obj[head]);
				obj[head].push(defaultEvalObj);
			}
		} else {
			if (!obj[head]) obj[head] = {};
			setArrayAtPath(obj[head], rest);
		}
	};

	const removeArrayAtPath = (obj, path) => {
		// console.log('newConfig-obj', path, path.length);
		const [head, ...rest] = path;
		if (rest.length === 0) {
			// console.log('newConfig-head==>', head, obj);
			obj.splice(head, 1)
		} else {
			if (!obj[head]) obj[head] = {};
			removeArrayAtPath(obj[head], rest);
		}
	};

	const addHandleListFromChild = (parentIndex, path) => {
		const newPath = path.map((ele, index) => {
			if (index < path.length - 1) {
				return ele;
			}
		}).filter(ele => ele !== undefined);

		setListingConfig((prevConfig) => {
			const newConfig = JSON.parse(JSON.stringify(prevConfig)); // Deep copy
			setArrayAtPath(newConfig[parentIndex], newPath);
			// console.log('final value', newConfig);
			return newConfig;
		});
	};

	const removeHandleListFromChild = (parentIndex, path) => {
		setListingConfig((prevConfig) => {
			const newConfig = JSON.parse(JSON.stringify(prevConfig)); // Deep copy
			removeArrayAtPath(newConfig[parentIndex], path);
			// console.log('final value', newConfig);
			return newConfig;
		});
	}




	return (
		<>
			<div key={"listing"} className="listing-config-container border boder-rad-10p">
				{listingConfig.length > 0 && listingConfig?.map((item, index) => (
					<div className="d-flex" key={`listing_${index}`}>
						<div className="p-2 border boder-rad-10p" key={`item-${index}`}>
							{/* <h3>Item {index + 1}</h3> */}
							<RecursiveRender
								parentIndex={index}
								data={item}
								onChange={(path, newValue, actionFor, keyName) => handleInputChange(path, newValue, index, actionFor, keyName)}
								addListFromChild={addHandleListFromChild}
								removeListFromChild={removeHandleListFromChild}
								productCategories={productCategories}
								products={products}
								suppliers={suppliers}
							/>
						</div>
						<div className="p-4" key={`listing_button_${index}`}>
							{index === listingConfig.length - 1 && (
								<div className="add_circ_md" onClick={() => addListing()}>
									<i className="fa fa-plus-circle"></i>
								</div>
							)}
							{listingConfig.length > 0 && listingConfig.length > 1 ? (
								<div className="remove_circ_md" onClick={() => RemoveListing(index)}>
									<i className="fa fa-times-circle"></i>
								</div>
							) : null}
						</div>
					</div>
				))}

				{/* <button onClick={() => console.log('submit', listingConfig)}>send</button> */}
			</div>
		</>
	)
}

const RecursiveRender = ({ parentIndex, data, path = [], onChange, addListFromChild, removeListFromChild, productCategories, products, suppliers }) => {

	const [collapsing, setCollapsing] = useState([])

	const addListingChild = (path, value) => {
		// console.log('parentIndex===', parentIndex);
		// console.log('path===', path);
		// console.log('value===', value);
		// console.log('data===', data);
		addListFromChild(parentIndex, path);
	}

	const removeListChild = (path, value) => {
		// console.log('parentIndex===', parentIndex);
		// console.log('path===', path);
		// console.log('data===', data);
		removeListFromChild(parentIndex, path);
	}

	const handleCollapsing = (path) => {
		setCollapsing((prev) => {
			const isPathExists = prev.includes(path);
			if (isPathExists) {
				// Remove the path
				return prev.filter(item => item !== path);
			} else {
				// Add the path
				return [...prev, path];
			}
		});
	};
	return (
		<div key={`${path.join('-')}` || 'parent'} style={{ display: 'flex', flexWrap: 'wrap' }}>
			{Object.keys(data).map((key, index) => {
				const value = data[key];
				const newPath = [...path, key];

				if (typeof value === 'object' && value !== null) {
					// console.log('value==>', key, typeof value, value, newPath);
					// Recursively render nested objects
					if (key === 'eval' && Array.isArray(value)) {

						return value.map((evalItem, evalIndex) => {
							const evalPath = [...newPath, evalIndex];
							const evalUniqueKey = `${evalPath.join('-')}`;
							// console.log('value==>', key, evalItem);
							if (evalItem.isDynamic && (evalItem.itemType?.value === 'custom' || Array.isArray(evalItem.value))) {
								const ItemOption =
									evalItem.itemType?.value === "productCategory"
										? productCategories.map((ele) => ({ label: ele.productCategory, value: ele._id }))
										: evalItem.itemType?.value === "product"
											? products.map((ele) => ({ label: `${ele.productName}(${ele.productCategory})`, value: ele._id }))
											: evalItem.itemType?.value === "supplier"
												? suppliers.map((ele) => ({ label: ele.supplierName, value: ele._id }))
												: [];
								return (
									<div key={evalUniqueKey} style={{ display: 'flex', alignItems: 'center', gap: '20px', margin: '8px 10px', }}>
										{/* <Input
											label="Key"
											placeholder="Enter value"
											name={'key'}
											value={evalItem.key}
											onChange={(e) => onChange(evalPath, e.target.value, 'eval', 'key')}
										/> */}
										<div key={`${evalUniqueKey}-key`} style={{ display: 'flex', margin: '10px 0', width: '200px' }}>
											<Dropdown
												name={evalPath}
												value={evalItem.key ? operators.find((ele) => ele.value == evalItem.key) : null}
												options={operators}
												label={"Key"}
												onChange={(option, key) => {
													onChange(evalPath, (option?.value || ""), 'eval', 'key')
												}}
											/>
										</div>
										<Checkbox
											key={`${evalUniqueKey}-isDynamic`}
											name="isDynamic"
											label="Is Dynamic"
											checked={evalItem.isDynamic}
											onChange={(e) => onChange(evalPath, e.target.checked, 'eval', 'isDynamic')}
										/>
										<div key={`${evalUniqueKey}-itemType`} style={{ display: 'flex', margin: '10px 0', width: '200px' }}>
											<Dropdown
												name={evalPath}
												value={evalItem.itemType}
												options={itemTypeOption}
												label={"Item Type"}
												onChange={(option, key) => {
													onChange(evalPath, option, 'eval', 'itemType')
												}}
											/>
										</div>
										{
											evalItem.itemType?.value !== 'custom' ?
												<div key={`${evalUniqueKey}-value`} style={{ display: 'flex', margin: '10px 0', width: '300px' }}>
													<Dropdown
														name={evalPath}
														value={evalItem?.value?.length > 0 ? filterOption(ItemOption, evalItem?.value) : []}
														options={ItemOption || []}
														label={"Value"}
														onChange={(option, key) => {
															onChange(evalPath, option, 'eval', 'value')
														}}
														multiple={true}
													/>
												</div> :
												<Input
													key={`${evalUniqueKey}-input`}
													label="value"
													placeholder="Enter value"
													name={'value'}
													value={evalItem?.value}
													onChange={(e) => onChange(evalPath, e.target.value, 'eval', 'value')}
												/>
										}

										{Array.isArray(value) && <div key={`${evalUniqueKey}-actions`} className="d-flex c_gap_h ">
											{evalIndex === value.length - 1 && <div className="add_circ_md" onClick={() => addListingChild(evalPath, value)}>
												<i className="fa fa-plus-circle"></i>
											</div>}
											{value.length > 0 && value.length > 1 ? <div className="remove_circ_md" onClick={() => removeListChild(evalPath)}>
												<i className="fa fa-times-circle"></i>
											</div> : null}
										</div>}
									</div>
								);
							} else {
								return (
									<div key={evalUniqueKey} style={{ display: 'flex', gap: "20px", }}>
										<RecursiveRender
											key={evalUniqueKey}
											data={evalItem}
											path={evalPath}
											onChange={onChange}
											addListFromChild={addListFromChild}
											removeListFromChild={removeListFromChild}
											productCategories={productCategories}
											products={products}
											suppliers={suppliers}
										/>
										{Array.isArray(value) && <div key={`${evalUniqueKey}-child-actions`} className="d-flex c_gap_h ">
											{evalIndex === value.length - 1 && <div className="add_circ_md" onClick={() => addListingChild(evalPath, value)}>
												<i className="fa fa-plus-circle"></i>
											</div>}
											{value.length > 0 && value.length > 1 ? <div className="remove_circ_md" onClick={() => removeListChild(evalPath)}>
												<i className="fa fa-times-circle"></i>
											</div> : null}
										</div>}
									</div>
								);
							}
						})
					} else {
						return (
							<div key={`${newPath.join('-')}-${index}`}>
								{/* <br /> */}
								<div style={{ width: '100%', display: 'flex', gap: '20px', marginLeft: '10px', marginBottom: '5px' }}>
									<strong>{['formula', 'filter', 'subFilter'].includes(key) && capitalizeCamelCase(key)}</strong>
									<strong className="collapsing-icon" onClick={() => handleCollapsing(`${newPath.join('-')}-${index}`)}>{
										['filter', 'subFilter'].includes(key) && !collapsing.includes(`${newPath.join('-')}-${index}`) ? close()
											: ['filter', 'subFilter'].includes(key) && collapsing.includes(`${newPath.join('-')}-${index}`) ? open() : null}
									</strong>
								</div>
								<div key={`${newPath.join('-')}-${index}`} className={collapsing.includes(`${newPath.join('-')}-${index}`) ? "collapsing-container" : ""} style={{ display: 'flex', gap: "20px", marginLeft: '10px', border: '1px solid var(--bs-border-color)', borderRadius: '10px', padding: '5px' }}>
									<RecursiveRender
										parentIndex={parentIndex}
										data={value}
										path={newPath}
										onChange={onChange}
										addListFromChild={addListFromChild}
										removeListFromChild={removeListFromChild}
										productCategories={productCategories}
										products={products}
										suppliers={suppliers}
									/>
									{/* {console.log('value==>', key, ['filter'].includes(key) ? value : "")} */}
									{!['formula', 'filter', 'subFilter'].includes(key) && <div className="d-flex c_gap_h ">
										{index === data.length - 1 && <div className="add_circ_md" onClick={() => addListingChild(newPath, value)}>
											<i className="fa fa-plus-circle"></i>
										</div>}
										{data.length > 0 && data.length > 1 ? <div className="remove_circ_md" onClick={() => removeListChild(newPath)}>
											<i className="fa fa-times-circle"></i>
										</div> : null}
									</div>}
								</div>
							</div>
						);
					}
				} else {
					// Render primitive values as input fields
					return (
						(['isDynamic'].includes(newPath[newPath.length - 1])) ?
							(
								<div key={`${newPath.join('-')}-${index}`} style={{ display: 'flex', width: ['operator'].includes(newPath[newPath.length - 1]) ? '100%' : '100px', gap: "2px", margin: '10px 10px', }}>
									<Checkbox
										key={newPath.join('-')}
										name={newPath.join('-')}
										label={checkLabel(newPath[newPath.length - 1])}
										checked={value}
										onChange={(e) => onChange(newPath, e.target.checked)}
									/>
								</div>
							)
							:
							(['operator'].includes(newPath[newPath.length - 1])) ?
								(
									<div key={`${newPath.join('-')}-${index}`} style={{ display: 'flex', width: ['operator'].includes(newPath[newPath.length - 1]) ? '100%' : '100px', gap: "2px", margin: '10px 10px', }}>
										<Checkbox
											key={newPath.join('-')}
											name={newPath.join('-')}
											label={`${checkLabel(newPath[newPath.length - 1])} ( For Array )`}
											checked={value === 'object' ? false : true}
											onChange={(e) => onChange(newPath, e.target.checked === true ? 'array' : 'object')}
										/>
									</div>
								)
								:
								(!['subFilter'].includes(newPath[newPath.length - 3]) && ['formula'].includes(newPath[newPath.length - 4]) && ['key'].includes(newPath[newPath.length - 1]))
									?
									(
										<div key={`${newPath.join('-')}-${index}`} style={{ display: 'flex', width: '200px', gap: "2px", margin: '10px 10px', }}>
											<Dropdown
												name={newPath[newPath.length - 1]}
												value={value ? filterValue(operators, value, false) : null}
												options={operators}
												label={checkLabel(newPath[newPath.length - 1])}
												onChange={(option, key) => {
													onChange(newPath, (option?.value || ""),)
													// console.log(newPath)
												}}
											// handleCustomKeyDown={(e) => onChange(newPath, (e.target.value || ""))}
											// enableCustomValue={!['subFilter'].includes(newPath[newPath.length - 3]) && ['formula'].includes(newPath[newPath.length - 4]) && ['key'].includes(newPath[newPath.length - 1])? false : true}
											// helperText={!['subFilter'].includes(newPath[newPath.length - 3]) && ['formula'].includes(newPath[newPath.length - 4]) && ['key'].includes(newPath[newPath.length - 1])? "" : "Note: Custom input accepted"}
											/>
										</div>
									)
									:
									(['key'].includes(newPath[newPath.length - 1]))
										?
										(
											<>
												<div key={`${newPath.join('-')}-${index}`} style={{ display: 'flex', width: '200px', gap: "2px", margin: '10px 10px', }}>
													<Dropdown
														name={newPath[newPath.length - 1]}
														value={value ? filterValue(minOpertaors, value, true) : null}
														options={minOpertaors}
														label={checkLabel(newPath[newPath.length - 1])}
														onChange={(option, key) => {
															onChange(newPath, (option?.value || ""),)
															// console.log(newPath)
														}}
													/>
												</div>
												{!['$and', '$or', ''].includes(value) && <div key={`${newPath.join('-')}-${index}-value`} style={{ display: 'flex', width: '200px', gap: "2px", margin: '10px 10px', }}>
													<Input
														label={`${checkLabel(newPath[newPath.length - 1])}-value`}
														placeholder="Enter value"
														name={newPath[newPath.length - 1]}
														value={value == 'custom' ? "" : value}
														onChange={(e) => onChange(newPath, e.target.value)}
														disabled={['selfFilter'].includes(newPath[newPath.length - 1])}
													/>
												</div>}
											</>
										)
										:
										(
											<div key={`${newPath.join('-')}-${index}`} style={{ display: 'flex', width: '200px', gap: "2px", margin: '10px 10px', }}>
												<Input
													label={checkLabel(newPath[newPath.length - 1])}
													placeholder="Enter value"
													name={newPath[newPath.length - 1]}
													value={value}
													onChange={(e) => onChange(newPath, e.target.value)}
													disabled={['selfFilter'].includes(newPath[newPath.length - 1])}
												/>
											</div>
										)
					)
				}
			})}
		</div>
	);
};



const filterOption = (rawOption, selectedOption) => {
	// console.log('item option', rawOption, selectedOption)
	return rawOption?.filter((chk) => selectedOption?.find((fchk) => fchk == chk.value));
};

const filterValue = (rawOption, value, enableCustomValue) => {
	// console.log('item option', rawOption, value)
	if (enableCustomValue) {
		if (Array.isArray(value)) {
			return value.map((item) => {
				return { label: item, value: item };
			});
		} else {
			const findResult = rawOption?.find((chk) => chk.value === value) || {};
			// console.log('findResult', findResult);
			return Object.keys(findResult)?.length > 0 ? findResult : { label: 'Custom', value: 'custom' };
		}

	} else {
		return rawOption?.find((chk) => chk.value === value);
	}


};


const checkLabel = (checkLabel) => {
	const labelCollection = [
		{ label: 'order', value: 'List Order No.' },
		{ label: 'label', value: 'Filter Name' },
		{ label: 'type', value: 'Type' }
	];
	return labelCollection.find(item => item.label === checkLabel)?.value || checkLabel;
}

const close = () => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
		<path fill="currentColor" d="M18 3a3 3 0 0 1 2.995 2.824L21 6v12a3 3 0 0 1-2.824 2.995L18 21H6a3 3 0 0 1-2.995-2.824L3 18V6a3 3 0 0 1 2.824-2.995L6 3zm1 6H5v9a1 1 0 0 0 .883.993L6 19h12a1 1 0 0 0 .993-.883L19 18zm-6.387 3.21l.094.083l2 2a1 1 0 0 1-1.32 1.497l-.094-.083L12 14.415l-1.293 1.292a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1-.083-1.32l.083-.094l2-2a1 1 0 0 1 1.32-.083" />
	</svg>
}

const open = () => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 0 24 24">
		<path fill="currentColor" d="M18 3a3 3 0 0 1 2.995 2.824L21 6v12a3 3 0 0 1-2.824 2.995L18 21H6a3 3 0 0 1-2.995-2.824L3 18V6a3 3 0 0 1 2.824-2.995L6 3zm0 2H6a1 1 0 0 0-.993.883L5 6v9h14V6a1 1 0 0 0-.883-.993zm-7.387 3.21l.094.083L12 9.585l1.293-1.292a1 1 0 0 1 1.32-.083l.094.083a1 1 0 0 1 .083 1.32l-.083.094l-2 2a1 1 0 0 1-1.32.083l-.094-.083l-2-2a1 1 0 0 1 1.32-1.497" />
	</svg>
}

const operators = [
	{ label: 'Equals', value: '$eq' },
	{ label: 'Not Equals', value: '$ne' },
	{ label: 'Greater Than', value: '$gt' },
	{ label: 'Greater Than or Equal', value: '$gte' },
	{ label: 'Less Than', value: '$lt' },
	{ label: 'Less Than or Equal', value: '$lte' },
	{ label: 'In', value: '$in' },
	{ label: 'Not In', value: '$nin' },
	{ label: 'And', value: '$and' },
	{ label: 'Or', value: '$or' },
	{ label: 'Not', value: '$not' },
	{ label: 'Nor', value: '$nor' },
	{ label: 'Exists', value: '$exists' },
	{ label: 'Type', value: '$type' }
];

const minOpertaors = [
	{ label: 'And', value: '$and' },
	{ label: 'Or', value: '$or' },
	{ label: 'Custom', value: 'custom' },
];
