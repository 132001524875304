import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../services/hooks";

const PublicRoute = ({ children }) => {
	const redirectUrl = useAuth();
	if (!localStorage.getItem("secretkey")) {
		return children ? children : <Outlet />;
	}
	return <Navigate to={redirectUrl} />;
};

export default PublicRoute;
