import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { GenericTable, Input, toastMessage, Button, tCell, Dialog, ActionMenu, Dropdown } from "../utils/index";
import { buttonClasses } from "../utils/theme";
import { useCheckpointTypes, useOrgs, useUserDetail, useUserPermissions } from "../../services/hooks";
import { useAddCheckpointTypeMutation, useUpdateCheckpointTypeMutation } from "../../api/vmsSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { GenericstatsV2 } from "../utils/Genericstats";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const CheckpointTypes = () => {
	const { userPermissions, isFetched } = useUserPermissions();
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [filterApplied, setFilterApplied] = useState(false);
	const [isModal, setIsModal] = useState(false);
	const updateDataRef = useRef(null);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};

	const filterFields = [
	];

	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}

	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
    const { checkpointTypes, count, isLoading, getCheckpointTypes } = useCheckpointTypes(filterRef.current);

	// const [handleDelete] = useDeleteRoleMutation();
	let actionHandlers = {};
	if (userPermissions?.updateCheckpointType?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = checkpointTypes[id];
			setIsModal(true);
		};
	}
	// if (userPermissions?.deleteUserRole?.value) {
	// 	actionHandlers.delete = async (id) => {
	// 		// console.log(id, "delete");
	// 		const data = roles[id];
	// 		const payload = { deleteId: data._id };
	// 		const {
	// 			data: { error, message },
	// 		} = await handleDelete(payload);
	// 		if (error) {
	// 			toastMessage(false, message);
	// 		} else {
	// 			onUpdateSuccess();
	// 			toastMessage(true, message);
	// 		}
	// 	};
	// }
	let header = ["S.no", "Checkpoint Type"];
	if (userDetail?.userType == "superAdmin") header.push("Org Name");
	if (userPermissions?.updateCheckpointType?.value || userPermissions?.deleteCheckpointType?.value) {
		header.push("Action");
	}
	const rows =
		checkpointTypes?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.checkpointType));

			if (userDetail?.userType == "superAdmin") row.push(tCell(d.orgName));

			if (userPermissions?.updateCheckpointType?.value || userPermissions?.deleteCheckpointType?.value) {
				row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getCheckpointTypes(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getCheckpointTypes(filterRef.current);
	};

	const onUpdateSuccess = () => {
		getCheckpointTypes(filterRef.current);
	};

	const handleFilterChange = (filter) => {
		if (filter?.orgId != "" && Object.keys(filter).length > 0) {
			// console.log('filter on change-device', filter);
			// Update orgId property safely
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getCheckpointTypes(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.orgId === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log('without-filter on change-device', filter);
			filterRef.current = defaultFilter;
			getCheckpointTypes(defaultFilter);
			setFilterApplied(false);
		}
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && (!userPermissions.getUserRoles?.value && userDetail?.userType == "superAdmin")) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Checkpoint Types</h1>
				</div>
				{userPermissions?.createCheckpointType?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link className="a_tag_no">
							<Button onClick={onModalOpen} text="Add Checkpoint Type" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={'checkpointType'}/>
			<div className="cust-row flex-algn-cent">
				<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			</div>
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			{isModal && <AddCheckpointType open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</div>
	);
};

export default CheckpointTypes;

const defaultForm = {
	checkpointType: "",
	updateId: undefined,
	orgId: undefined,
    orgName: undefined,
};

function fillUpdateData(data) {
	return {
		checkpointType: data.checkpointType,
		updateId: data._id,
		orgId: data.orgId,
        orgName: data.orgName
	};
}

function AddCheckpointType({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const filterRef = useRef({ orgId: updateData?.orgId });

	const orgValue = orgOptions.find((_) => _.value == formData.orgId);
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};
	const onDropdownChange = (option, name) => {
		let value = Array.isArray(option) ? option.map((_) => _.value) : option?.value || "";
		handleInput({ target: { name, value } });
		if (name == "orgId") {
			filterRef.current = { ...filterRef.current, orgId: option?.value || "" };
		}
	};

	const [handleAdd, { isLoading: isAdding }] = useAddCheckpointTypeMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateCheckpointTypeMutation();

	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label };
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.checkpointType) error.checkpointType = "Role is required";
		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	return (
		<Dialog size="xs" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={updateData ? "Update Checkpoint Types" : "Add Checkpoint Types"} isSubmitDisabled={isAdding || isUpdating}>
			<form>
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown readonly={updateData} id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} required error={error.orgId} />
					) : null}
					<Input label="Checkpoint Type" placeholder="Enter Checkpoint Type" name="checkpointType" value={formData.checkpointType} onChange={handleInput} required error={error.checkpointType} />
				</div>
			</form>
		</Dialog>
	);
}
