import React, { useEffect, useState, useRef, useMemo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { autocompleteTheme } from "../utils/theme";
import PreviewFile from "../utils/PreviewFile";

import {
	Typography,
	TextField,
	FormControl,
	FormLabel,
	MenuItem,
	FormControlLabel,
	Checkbox,
	RadioGroup,
	Radio,
	FormGroup,
	Grid,
	Autocomplete,
	Tooltip,
	InputAdornment,
	IconButton,
} from "@mui/material";

import theme, { inputClasses, textClasses } from "../utils/theme";
import { getShortText } from "../../services/functions";
import { AddIcon, CloseIcon, CheckIcon } from "../../services/icons";

export const CustomInput = ({ component, index = null, autoFillOnFocus, errors, fetchSourceDropdownData, onDropdownChange, onInputChange }) => {
	const [inputValue, setInputValue] = useState("");
	const inputRefs = useRef([]);
	const inputFileRef = useRef();
	const [focusField, setFocusField] = useState(0);
	useEffect(() => {
		const { dynamic, dontFetch, api, apiMethod, values, apiParameters, apiBindingKey, apiBindingValue, apiDataReturnKey = "data", suffix = "", extraValue = [] } = component;
		if (!dontFetch && dynamic && api && apiMethod && !values?.length) {
			fetchSourceDropdownData(component);
		}
	}, []);

	// useEffect(() => {
	// 	if (component?.value?.length > 0) {
	// 		if (inputRefs.current[component.value.length - 1]) {
	// 			inputRefs.current[focusField].focus();
	// 		}
	// 	}
	// }, [component?.value, focusField]);

	const convertDocsBase64 = (file) => {
		if (file) {
			return new Promise((resolve, reject) => {
				setTimeout(() => {
					const fileReader = new FileReader();
					fileReader.readAsDataURL(file);
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
					fileReader.onerror = (error) => {
						reject(error);
					};
				}, 400);
			});
		} else {
		}
	};

	const handleSelect = (idChecked, values = []) => {
		const newValue = ((Array.isArray(values) && values) || [])?.includes(idChecked) ? values?.filter((id) => id !== idChecked) : [...values, idChecked];

		// console.log("idChecked, values",{idChecked, values,newValue});
		// console.log("newValue",{newValue});
		return newValue;
	};

	let basicProps = {
		size: "small",
		fullWidth: true,
		type: component.type,
		multiline: component.type == "textarea",
		rows: component.type == "textarea" ? 3 : 1,
		label: `${component.label}${component.required ? " *" : ""}`,
		placeholder: component.placeholder,
		onFocus: autoFillOnFocus ? autoFillOnFocus : () => {},
		error: errors && errors[component._id] ? true : false,
		InputLabelProps: { shrink: true },
		id : component._id
		// InputLabelProps: { shrink: Array.isArray(component?.value) ? !!component.value.length : !!component.value },
	};

	if (component.type == "number") {
		basicProps.onWheel = (event) => event.target.blur();
		basicProps.onKeyDown = (e) => {
			if (["e", "E", "+", "-"].includes(e.key)) {
				e.preventDefault();
			} else if (e.key == "0" && (e.target.value == "" || e.target.value[0] == "0")) {
				e.preventDefault();
			}
		};
		basicProps = {
			...basicProps,
		};
	}
	if (component.type == "date") {
		basicProps.InputLabelProps = { shrink: true };
		basicProps = {
			...basicProps,
		};
	}
	if (component.type == "file") {
		basicProps.focused = true;
		basicProps.onChange = async (e) => {
			const file = await convertDocsBase64(e.target.files[0]);
		};
	}

	if (component.type != "file") {
		basicProps = {
			...basicProps,
		};
	}

	if (["input", "number", "textarea", "date", "file", "searchTags", "text"].indexOf(component.type) >= 0) {
		if (component.multiple && Array.isArray(component.value)) {
			const changeParent = (value) => {
				onInputChange({ target: { value } });
			};
			const handleAdd = (index) => {
				changeParent([...component.value, ""]);
				setFocusField(index + 1);
			};

			const handleFocus = (index) => {
				setFocusField(index);
			};
			const handleRemove = (index) => {
				const newVal = [...component.value];
				newVal.splice(index, 1);
				changeParent(newVal);
				inputRefs.current.splice(index, 1);
				setFocusField(newVal.length - 1);
			};
			const handleChange = (e, i) => {
				const newVal = [...component.value];
				newVal.splice(i, 1, e.target.value);
				changeParent(newVal);
			};
			return (
				<FormControl variant="outlined" sx={{ width: "100%", marginTop: "-15px" }}>
					<Typography sx={{ ...textClasses.boldText, fontSize: "12px", lineHeight: "15px" }}>{`${component.label}${component.required ? " *" : ""}`}</Typography>
					{component.value?.map((val, i) => {
						return (
							<TextField
								autoFocus={i == 0}
								key={i}
								size="small"
								variant="outlined"
								inputRef={(ref) => (inputRefs.current[i] = ref)}
								sx={{ ...inputClasses.textField, marginBottom: "0.5rem" }}
								onFocus={() => handleFocus(i)}
								InputProps={{
									sx: { ...inputClasses.textField },
									readOnly: component?.readonly || false,
									endAdornment: (
										<InputAdornment position="end">
											{component.value.length > 1 ? (
												<IconButton aria-label="remove item" onClick={() => handleRemove(i)} edge="end">
													<CloseIcon style={{ fill: "var(--icon-color)" }} />
												</IconButton>
											) : null}
											{i == component.value.length - 1 ? (
												<IconButton aria-label="add item" onClick={() => handleAdd(i)} edge="end">
													<AddIcon style={{ fill: "var(--icon-color)" }} />
												</IconButton>
											) : null}
										</InputAdornment>
									),
								}}
								type={component.type}
								// name={component._id}
								value={val || ""}
								onChange={(e) => {
									handleChange(e, i);
								}}
								onKeyDown={(e) => (e.key == "Enter" || e.keyCode == "13" ? handleAdd(i) : "")}
								label=""
								placeholder=""
								id={`${component._id}-${i}`}
								// disabled={component.disabled}
							/>
						);
					})}
				</FormControl>
			);
		} else {
			const handleClear = () => {
				// console.log('aas', inputFileRef.current.value)
				inputFileRef.current.value = "";
			};
			return (
				<>
					<TextField
						{...basicProps}
						onChange={onInputChange}
						value={component.type != "file" ? (index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || "") : null}
						sx={{ ...inputClasses.textField }}
						// sx={{ ...inputClasses.shadowField }}
						InputProps={{
							sx: { ...inputClasses.textField },
							readOnly: component?.readonly || false,
						}}
						inputRef={(ref) => (inputFileRef.current = ref)}
						// InputProps={inputProps ? { endAdornment: inputProps } : {}}
					/>
					{/* {component.type === 'file' && <button onClick={() => handleClear()}>close</button>} */}
					{component.type === "file" ? component?.value ? <PreviewFile file={component.value} /> : null : null}
				</>
			);
		}
	} else if (component.type == "select") {
		// console.log({ id: component._id, c_value: component.value, values: component.values, isSearching: component.isSearching }); //
		const handleDropdownChange = (event, selectedOption, reason, details) => {
			// console.log("dropdown change", event, selectedOption, reason, details);
			if (component?.multiselect) {
				onDropdownChange(selectedOption.map((ele) => ele.value) || []);
			} else {
				onDropdownChange(selectedOption?.value == undefined ? "" : selectedOption.value);
			}
		};
		// console.log(component)
		const options = getOptionsFromValues(component);
		let value;
		// console.log("c", component._id, component.value);

		// eslint-disable-next-line
		value = useMemo(() => {
			if (component?.multiselect) {
				return options?.filter((chk) => [...component.value]?.find((fchk) => fchk == chk.value));
			} else {
				return options?.find((dl) => dl.value == (index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || "")) || null;
			}
		}, [component?.value, options]);
		// if (component?.multiselect) {
		// 	value = useMemo(() => {
		// 		return options?.filter((chk) => [...component.value]?.find((fchk) => fchk == chk.value));
		// 	}, [component?.value, options]);
		// } else {
		// 	value = options?.find((dl) => dl.value == component.value) || null;
		// }
		// value = options?.find((dl) => dl.value == component.value) || null;

		// console.log({ i: component._id, options, value, c: component.value });

		// console.log({ value, f_value: field.value });
		// console.log('drop-fields=====', {...field}, component)
		return (
			<>
				<FormControl fullWidth={basicProps.fullWidth} size={basicProps.size} error={basicProps.error}>
					<ThemeProvider theme={autocompleteTheme}>
						<Autocomplete
							id={component._id}
							sx={{ ...inputClasses.themeBoundary }}
							onFocus={basicProps.onFocus}
							// filterOptions={(options, state) => {
							// 	console.log(options, state);
							// 	return options;
							// }}
							options={options}
							multiple={component?.multiselect}
							autoComplete
							size="small"
							includeInputInList
							filterSelectedOptions
							noOptionsText={component.isSearching ? "Searching..." : "No Data Found"}
							value={value}
							onChange={handleDropdownChange}
							isOptionEqualToValue={(option, value) => {
								return (option?.value == "" && value == "") || option?.value == value?.value;
							}}
							// inputValue={inputValue}
							onInputChange={(e, newInputValue, reason) => {
								// console.log("dropdown-input-change", { id: component._id, newInputValue, reason, e });
								//e.type = "click" when option is selected
								if (e?.type == "change" || reason == "clear") {
									// console.log("Set", newInputValue);
									onInputChange({ target: { value: newInputValue } });
								}
								// if (reason == "reset" && newInputValue == "") {
								// 	console.log("reset");
								// 	return;
								// } else {
								// 	// setInputValue(newInputValue);
								// }
							}}
							renderInput={(params) => {
								return (
									<TextField
										{...params}
										{...basicProps}
										label={`${component.label}${component.required ? " *" : ""}`}
										fullWidth
										sx={{ ...inputClasses.themeBoundary, ...inputClasses.textField }}
									/>
								);
							}}
							renderOption={({ key, ...props }, option, { selected }) => {
								return (
									<MenuItem value={option.value} sx={{ justifyContent: "space-between", color: "var(--text-color)" }} key={key + props["data-option-index"]} {...props}>
										<Tooltip sx={{ width: "50px" }} title={option.label}>
											<Typography>{getShortText(option.label, 21)}</Typography>
										</Tooltip>
										{selected ? <CheckIcon color="info" /> : null}
									</MenuItem>
								);
							}}
						/>
					</ThemeProvider>
				</FormControl>
			</>
		);
	} else if (component.type == "radio") {
		const innerComp = (
			<RadioGroup row sx={{ mt: 1 }} value={index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value || ""} onChange={onInputChange}>
				{component.values &&
					component.values.map((op, i) => {
						return (
							<FormControlLabel
								key={i}
								label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
								control={<Radio value={op.value} size="small" sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }} />}
							/>
						);
					})}
			</RadioGroup>
		);
		if (component.multiline != null && component.multiline == false) {
			return (
				<Grid container justifyContent={"left"} alignItems="center">
					{innerComp}
				</Grid>
			);
		}
		return (
			<div style={{ ...inputClasses.shadowField, border: `1px solid ${basicProps.error ? "#e74c3c" : "#D9D9D9"}`, width: "100%", padding: "10px" }}>
				<FormControl fullWidth size="small">
					<FormLabel
						sx={{
							color: "rgb(0 0 0 / 60%) !important",
							"& .MuiFormLabel-root.Mui-focused": {
								color: "#F77200",
							},
						}}
					>
						{component.label}
					</FormLabel>
					{innerComp}
				</FormControl>
			</div>
		);
	} else if (component.type == "checkbox") {
		const innerComp = (
			<FormGroup row sx={{ mt: 1 }}>
				{component.values &&
					component.values.map((op, i) => {
						// console.log("oppp",op)
						// console.log("fff",field)

						return (
							<FormControlLabel
								key={i}
								label={<Typography sx={{ fontSize: "13px" }}>{op.label}</Typography>}
								control={
									<Checkbox
										checked={index != null && Array.isArray(component.value) ? component.value[index] || "" : component.value == op.value}
										onClick={() => onInputChange({ target: { value: op.value } })}
										// onChange={() => field.onChange(handleSelect(op.value, field.value || []))}
										size="small"
										sx={{ "&, &.Mui-checked": { color: theme.themeOrange } }}
									/>
								}
							/>
						);
					})}
			</FormGroup>
		);
		if (component.multiline != null && component.multiline == false) {
			return (
				<Grid container justifyContent={"left"} alignItems="center">
					{innerComp}
				</Grid>
			);
		}
		return (
			<div style={{ ...inputClasses.shadowField, border: "1px solid #D9D9D9", width: "100%", padding: "10px" }}>
				<FormControl fullWidth size="small">
					<FormLabel
						sx={{
							color: "rgb(0 0 0 / 60%) !important",
							"& .MuiFormLabel-root.Mui-focused": {
								color: "#F77200",
							},
						}}
					>
						{component.label}
					</FormLabel>
					{innerComp}
				</FormControl>
			</div>
		);
	}
	// else if (component.type == "group_addon") {
	// 	// console.log(component);
	// 	//TODO
	// 	const { label, childrens, hideLabel, required, value = [] } = component;
	// 	// value here will be an array of objects
	// 	// each object will contain key-value pairs of the number of input
	// 	// eg: the group addon component should have one Driver dropdwon and one driver mobile number input
	// 	// so the value = [{dr1: "", dr2:""}];
	// 	// dr1 and dr2 are the references to the actual inputs inside the childrens array (_id or name)
	// 	return (
	// 		<div>
	// 			{!hideLabel ? <InputLabel>{label}</InputLabel> : null}
	// 			{value.map((_, i) => {
	// 				return (
	// 					<Grid key={i}>

	// 						{childrens.map((child) => {
	// 							if (!child.conditionalView || child.conditionSatisfied)
	// 								return (
	// 									<CustomInput
	// 										component={child}
	// 										fetchSourceDropdownData={fetchSourceDropdownData}
	// 										errors={errors}
	// 										onDropdownChange={onDropdownChange}
	// 										onInputChange={onInputChange}
	// 									/>
	// 								);
	// 							return null;
	// 						})}
	// 						<AddRemove list={value} filterMethod={(c) => c.value == ""} onAdd={() => {}} onRemove={() => {}} index={i} outerIndex={i} />
	// 					</Grid>
	// 				);
	// 			})}
	// 		</div>
	// 	);
	// }
};

function getOptionsFromValues(field) {
	const { values, apiBindingKey, apiBindingValue } = field;
	const options = values?.map((el) => ({ label: el[apiBindingKey || "label"], value: el[apiBindingValue || "value"] }));
	return options;
}
