import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { tCell, Button, GenericTable, Dropdown, DatePicker, TimePicker, toastMessage } from "../utils";
import { useDeviceLogs, useUserPermissions } from "../../services/hooks";
import { buttonClasses } from "../utils/theme";
import { formatDate } from "../../services/functions";
import { Navigate } from "react-router-dom/dist";
import dayjs from "dayjs";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const PARSED_LOG = "parsed";
const RAW_LOG = "raw";

const logTypeOptions = [
	{ label: "Parsed Logs", value: PARSED_LOG },
	{ label: "Raw Logs", value: RAW_LOG },
];
const tomorrow = dayjs().add(1);
const today = dayjs();
const startTime = today.subtract(1, "hour");
const endTime = today;

const DeviceLogs = () => {
	const location = useLocation();
	const { deviceId } = location.state || {};
	const { userPermissions, isFetched } = useUserPermissions();
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { logs, count, isLoading, getDeviceLogs } = useDeviceLogs();

	const [error, setError] = useState({});
	const [filter, setFilter] = useState({
		type: PARSED_LOG,
		date: today,
		startTime: startTime,
		endTime: endTime,
	});

	useEffect(() => {
		if (deviceId == undefined || filter.type == undefined) return;
		// Extract the date part from the date field
		const baseDate = dayjs(filter.date).format("YYYY-MM-DD");

		// Extract the time parts from startTime and endTime
		const startHour = dayjs(filter.startTime).format("HH:mm:ss");
		const endHour = dayjs(filter.endTime).format("HH:mm:ss");

		// Combine to form startDate and endDate
		const startDate = dayjs(`${baseDate}T${startHour}`); //.add(330, "minutes").toDate();
		const endDate = dayjs(`${baseDate}T${endHour}`); //.add(330, "minutes").toDate();

		filterRef.current = {
			...filterRef.current,
			deviceId,
			type: filter.type,
			startDate: startDate?.$d == "Invalid Date" ? new Date().toISOString() : startDate.toISOString(),
			endDate: endDate?.$d == "Invalid Date" ? new Date().toISOString() : endDate.toISOString(),
		};
	}, [deviceId, filter]);

	function handleGetLogs() {
		if (!filter.type) {
			setError({ type: "Log Type is required." });
		} else {
			setError({});
			getDeviceLogs(filterRef.current);
		}
	}

	let header;
	let rows;
	if (filter.type == RAW_LOG) {
		header = ["S.no", "Log Time", "Request"];
		rows = logs?.map((_, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(formatDate(_.creation_date)), tCell(_?.request));
			return row;
		});
	} else {
		header = ["S.no", "Log Time", "Device ID", "TID", "Command Code", "RSSI", "Antenna ID"];
		rows = logs?.map((_, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(formatDate(_.creation_date)), tCell(_.deviceId), tCell(_.tId), _.commandCode, tCell(_.rssi), tCell(_.antennaId));
			return row;
		});
	}

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getDeviceLogs(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getDeviceLogs(filterRef.current);
	};
	const handleFilterChange = (e) => {
		const { name, value } = e.target;

		setFilter((o) => ({ ...o, [name]: value }));
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getDevice?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Device Logs</h1>
				</div>
				<div className="cust-col-5 flex-jc-end">
					<Link to="/devices">
						<Button text="Go Back" style={buttonClasses.lynkitOrangeEmpty} />
					</Link>
				</div>
			</div>
			<hr className="bgg" />
			<div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: "1rem", marginBottom: "1rem" }}>
				<Dropdown
					label="Log Type"
					name="type"
					value={logTypeOptions.find((_) => _.value == filter.type)}
					options={logTypeOptions}
					required
					error={error.type}
					onChange={(op, name) => {
						setFilter((o) => ({ ...o, type: op?.value || "" }));
					}}
				/>
				<DatePicker
					label="Date"
					name="date"
					value={filter.date?.$d == "Invalid Date" ? null : filter.date}
					maxDATE={tomorrow}
					onChange={(value) => {
						handleFilterChange({ target: { name: "date", value: value } });
					}}
					isRequired
				/>
				<TimePicker label="Start Time" value={filter.startTime} onChange={(value) => handleFilterChange({ target: { name: "startTime", value: value } })} isRequired />
				<TimePicker label="End Time" value={filter.endTime} onChange={(value) => handleFilterChange({ target: { name: "endTime", value: value } })} isRequired />
				<Button text={`Submit`} onClick={handleGetLogs} style={{ ...buttonClasses.lynkitOrangeFill, width: "fit-content", height: "fit-content" }} disabled={isLoading} />
			</div>
			<div className="main_content">
				<GenericTable header={header} rows={rows} pageCount={count} pageNo={page_no} limit={pagesize} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} isLoading={isLoading} />
			</div>
		</div>
	);
};

export default DeviceLogs;
