import React, { useEffect, useState } from 'react';
import "./util.css";
import { useGetStatsMutation } from '../../api/api';
import {
    DeviceIcon,
    TagIcon,
    UsersIcon,
    CompanyIcon,
    ProductIcon,
    ProductCategoriesIcon,
    SupplierIcon,
    RoleIcon,
    ReportIcon,
    CheckpointTypeIcon,
    WorkFlowIcon
} from "../../services/icons";
import { useGetDeviceStatsQuery, useGetUsersStatsQuery, useGetVMSStatsQuery } from '../../api/api';

export default function Genericstats({
    onhandleFilter,
    checkpointID,
    filterType = "",
}) {

    // typesArr : ["All", "Total Request",  "Total In", "Total Out"]

    const [statData, setStatData] = useState([])

    const filter = {
        "typesArr": ["All"],
        "checkpointId": checkpointID,
        "isCount": true
    }

    const [getStatsTrigger, { data: statsData, error: statsError, isLoading: statsLoading }] = useGetStatsMutation();

    // const { data, refetch, isLoading, isFetching } = useGetStatsQuery(filter, {
    //     refetchOnMountOrArgChange: true,
    // });
    // const { data: statsData } = data || {};





    useEffect(() => {
        // If checkpointID is a string
        if (typeof checkpointID === 'string') {
            // console.log('call for indivisual', checkpointID);
            getStatsTrigger({
                "typesArr": ["All"],
                "checkpointId": checkpointID,
                "type": filterType,
                "isCount": true
            }).unwrap()
                .then((res) => {
                    if (!res?.error) {
                        const UpdatedStats = Object.keys(res?.data).map((key) => ({
                            statName: capitalizeWords(key),
                            statValue: typeof res?.data[key] !== 'object' ? res?.data[key] : 0
                        }));
                        // console.log(UpdatedStats);
                        setStatData(UpdatedStats);
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }

    }, [checkpointID])

    useEffect(() => {
        // Check if checkpointID is an array and has elements
        if (Array.isArray(checkpointID) && checkpointID.length > 0) {
            // console.log('call for dashboard');
            // Create an array to hold promises for each API call
            const promises = checkpointID.map(async (ele, index) => {
                try {
                    // Fetch stats data for each checkpointID
                    const res = await getStatsTrigger({
                        "typesArr": ["All"],
                        "checkpointId": ele,
                        "isCount": true
                    }).unwrap();
                    // If response is successful
                    if (!res?.error) {
                        const UpdatedStats = Object.keys(res?.data).map((key) => ({
                            statName: capitalizeWords(key),
                            statValue: typeof res?.data[key] !== 'object' ? res?.data[key] : 0
                        }));
                        // Return different slices of UpdatedStats based on index
                        if (index === 0) {
                            return UpdatedStats.slice(0, 2);
                        } else {
                            return UpdatedStats.slice(2, 3);
                        }
                    }
                } catch (err) {
                    console.error(err);
                    return null; // Return null for failed promises
                }
            });
            // Resolve all promises
            Promise.all(promises)
                .then((result) => {
                    // console.log('new_updateStats', result);
                    // Flatten the array of arrays
                    const flatResult = result.flat();
                    // Filter out null values
                    const filteredResult = flatResult.filter((item) => item !== null);
                    // Set the state with the filtered result
                    setStatData(filteredResult);
                })
                .catch((err) => {
                    console.error(err);
                });
        }

    }, []);

    return (
        <>
            <div className='genericStats-containter'>
                {statData.length > 0 && statData.map((ele, index) => (
                    <div className='genericStats-tab' key={ele.statName} onClick={(ele) => onhandleFilter ? onhandleFilter(ele) : ""}>
                        <div style={{ color: '#48494a' }}>
                            {ele.statName === 'Total Vehicle Request' ? TotalTruckRequest() :
                                ele.statName === 'Total Today Vehicle In' ? TotalTruckIn() :
                                    ele.statName === 'Total Today Vehicle Out' ? TotalTruckOut() : null}
                        </div>
                        <div>
                            <div className='genericStats-tab-point'>{ele.statValue}</div>
                            <div className='genericStats-tab-title'>{ele.statName}</div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}



export function GenericstatsV2({ statsFor }) {
    const [statData, setStatData] = useState([]);
    const { data: DeviceStatsData = {}, refetch: DeviceStatsRefetch, } = ['devices', 'Tag'].includes(statsFor) ? useGetDeviceStatsQuery({ "statsFor": statsFor }, { refetchOnMountOrArgChange: true }) : {};
    const { data: UsersStatsData = {}, refetch: UsersRefetech, } = ['users', 'company', 'roles'].includes(statsFor) ? useGetUsersStatsQuery({ "statsFor": statsFor }, { refetchOnMountOrArgChange: true }) : {};
    const { data: VMSStatsData = {}, refetch: VMSStatsRefetch, } = ['product', 'productCategories', 'supplier', 'workflow', 'checkpoint', 'checkpointType'].includes(statsFor) ? useGetVMSStatsQuery({ "statsFor": statsFor }, { refetchOnMountOrArgChange: true }) : {};
    const { data: DeviceStats } = DeviceStatsData;
    const { data: UsersStats } = UsersStatsData;
    const { data: VMSStats } = VMSStatsData;

    // console.log('stats', DeviceStats, UsersStats, VMSStats);


    useEffect(() => {
        if (DeviceStats) {
            const UpdatedStats = Object.keys(DeviceStats).map((key) => ({
                statName: capitalizeWords(key),
                statValue: typeof DeviceStats[key] !== 'object' ? DeviceStats[key] : 0
            }));
            setStatData(UpdatedStats);
        }
        if(UsersStats){
            const UpdatedStats = Object.keys(UsersStats).map((key) => ({
                statName: capitalizeWords(key),
                statValue: typeof UsersStats[key] !== 'object' ? UsersStats[key] : 0
            }));
            setStatData(UpdatedStats);
        }
        if(VMSStats){
            const UpdatedStats = Object.keys(VMSStats).map((key) => ({
                statName: capitalizeWords(key),
                statValue: typeof VMSStats[key] !== 'object' ? VMSStats[key] : 0
            }));
            setStatData(UpdatedStats);
        }

    }, [DeviceStats, UsersStats, VMSStats])

    const fetchLogo = (statName) => {
        switch (statName) {
            case 'Total Vehicle Request':
                return TotalTruckRequest();
            case 'Total Today Vehicle In':
                return TotalTruckIn();
            case 'Total Today Vehicle Out':
                return TotalTruckOut();
            case 'Total Devices':
                return <DeviceIcon style={{ fontSize: '3.5em', }} />;
            case 'Total Tags':
                return <TagIcon style={{ fontSize: '3.2em', }} />;
            case 'Total Allocated Tags':
                return AllocatedTag();
            case 'Total Users':
                return <UsersIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Roles':
                return <RoleIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Product Categories':
                return <ProductCategoriesIcon style={{ fontSize: '3.2em', }} />;
            case 'Total Products':
                return <ProductIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Suppliers':
                return <SupplierIcon style={{ fontSize: '3.5em', }} />;
            case 'Total Companies':
                return <CompanyIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Checkpoints':
                return <ReportIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Checkpoint Types':
                return <CheckpointTypeIcon style={{ fontSize: '3.3em', }} />;
            case 'Total Workflows':
                return <WorkFlowIcon style={{ fontSize: '3.3em', }} />;
            default:
                null
        }
    }
    return (
        <>
            <div className='genericStats-containter'>
                {statData.length > 0 && statData.map((ele, index) => (
                    <div className='genericStats-tab' key={ele.statName} onClick={(ele) => onhandleFilter ? onhandleFilter(ele) : ""}>
                        <div style={{ color: '#48494a', marginRight: '5px' }}>
                            {fetchLogo(ele.statName)}
                        </div>
                        <div>
                            <div className='genericStats-tab-point'>{ele.statValue}</div>
                            <div className='genericStats-tab-title'>{ele.statName}</div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const capitalizeWords = (str) => {
    return ((str
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')).split(/(?=[A-Z])/)).join(" ");
}


const TotalTruckRequest = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 8h-3V4H1v13h2c0 1.66 1.34 3 3 3s3-1.34 3-3h6c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-5zM6 18.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M15 7h-2v7h-2V7H9v7H7V7H5v7H3V6h12zm3 11.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5s1.5.67 1.5 1.5s-.67 1.5-1.5 1.5M17 12V9.5h2.5l1.96 2.5z" />
        </svg>
    )
}


const TotalTruckIn = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46zM6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4zM3 6v9h.76c.55-.61 1.35-1 2.24-1s1.69.39 2.24 1H15V6zm2 4.5L6.5 9L8 10.5L11.5 7L13 8.5l-5 5z" />
        </svg>
    )
}

const TotalTruckOut = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="4em" height="4em" viewBox="0 0 24 24">
            <path fill="currentColor" d="M18 4h-5v9H1v4h2c0 .83.3 1.53.89 2.13c.61.59 1.3.87 2.11.87s1.5-.28 2.11-.87c.59-.6.89-1.3.89-2.13h5.5c0 .83.28 1.53.88 2.13c.59.59 1.29.87 2.12.87c.8 0 1.5-.28 2.09-.87c.6-.6.91-1.3.91-2.13H23v-7zM7.08 18.07c-.28.3-.64.43-1.08.43s-.8-.13-1.08-.43s-.42-.65-.42-1.07c0-.39.14-.74.42-1.04s.64-.46 1.08-.46s.8.16 1.08.46s.42.65.42 1.04c0 .42-.14.77-.42 1.07m11.46 0c-.3.3-.65.43-1.04.43c-.42 0-.77-.13-1.07-.43S16 17.42 16 17c0-.39.13-.74.43-1.04s.65-.46 1.07-.46c.39 0 .74.16 1.04.46s.46.65.46 1.04c0 .42-.16.77-.46 1.07M15 10V6h2.06l3.33 4z" />
        </svg>
    )
}

const AllocatedTag = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="3.2em" height="3.2em" viewBox="0 0 24 24">
            <path fill="currentColor" d="m21.41 11.58l-9-9C12.04 2.21 11.53 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .53.21 1.04.59 1.41l.41.4c.9-.54 1.94-.81 3-.81c3.31 0 6 2.69 6 6c0 1.06-.28 2.09-.82 3l.4.4c.37.38.89.6 1.42.6s1.04-.21 1.41-.59l7-7c.38-.37.59-.88.59-1.41s-.21-1.04-.59-1.42M5.5 7C4.67 7 4 6.33 4 5.5S4.67 4 5.5 4S7 4.67 7 5.5S6.33 7 5.5 7m3.13 7.27l-3.87 3.9l-1.35-1.37L2 18.22L4.75 21l5.28-5.32z" />
        </svg>
    )
}
