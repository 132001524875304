import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForgotPasswordMutation, useLoginMutation, useUpdatePasswordMutation } from "../../api/userSlice";
import { Button, Input, toastMessage } from "../utils";
import "./Login.css";
import LynkIdlogo from "../../Images/LynkID_Final.png";
import theme, { buttonClasses, textClasses } from "../utils/theme";
import { IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { valid_email, valid_password } from "../../services/validation";
function Login() {
	useShowExpiredToast();
	const [isForgot, setIsForgot] = useState(false);
	const handleReset = () => {
		setIsForgot(false);
	};
	localStorage.clear();
	// localStorage.removeItem("favicon");
	// localStorage.removeItem("title");
	// localStorage.removeItem("primaryColor");
	// localStorage.removeItem("selectedTheme");
	// localStorage.removeItem("logo");
	// localStorage.removeItem("project");
	return (
		<>
			<div className="login_wrap">
				<section className="bg">
					<img src={LynkIdlogo} alt="" className="" />
				</section>
				<section>
					<div className="form_wrap">
						<div className="heading">
							{isForgot ? (
								<div style={{ display: "flex", alignItems: "center" }}>
									<IconButton onClick={handleReset}>
										<ArrowBack />
									</IconButton>
									<div style={{ flexBasis: "80%", display: "flex", alignItems: "center", justifyContent: "center" }}>Reset Password</div>
								</div>
							) : (
								<>
									<div>Get Started With</div>
									<div>
										<img src={LynkIdlogo} alt="" className="" />
									</div>
									{/* <div>Internal Truck Management System</div> */}
								</>
							)}
						</div>
						{isForgot ? <ForgotPassword onClose={() => setIsForgot(false)} /> : <LoginForm onForgot={() => setIsForgot(true)} />}
					</div>
				</section>
			</div>
			{/* {isForgot ? <ForgotPassword open={isForgot} onClose={() => setIsForgot(false)} /> : null} */}
		</>
	);
}
// {`(${counter})`}
export default Login;

function LoginForm({ onForgot }) {
	const [formData, setFormData] = useState({
		email: " ",
		password: "",
	});
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData((old) => ({ ...old, [name]: value }));
	};
	const navigate = useNavigate();
	const [passwordType, setPasswordType] = useState("password");
	const [handleLogin, { isLoading }] = useLoginMutation();
	const togglePassType = () => {
		if (passwordType == "password") {
			setPasswordType("text");
		} else {
			setPasswordType("password");
		}
	};

	const handleFormSubmit = async () => {
		const {
			data: { error, message, token, permissions, email },
		} = await handleLogin(formData);

		if (error) {
			toastMessage(false, message);
		} else {
			localStorage.setItem("secretkey", token);
			localStorage.setItem("userDetail", email);

			navigate("/loading");
		}
	};
	return (
		<form>
			<Input label="Email" type="email" id="email" placeholder="Enter your email" name="email" value={formData.email} onChange={handleInput} />
			<Input
				label="Password"
				id="password"
				name="password"
				placeholder="Enter your password"
				onChange={handleInput}
				type={passwordType}
				adornment={{
					is: true,
					onClick: togglePassType,
					type: passwordType === "password" ? "eye" : "eyeClose",
				}}
				value={formData.password}
			/>

			<div>
				<span style={{ ...buttonClasses.small, margin: "0" }} onClick={onForgot}>
					Forgot Password?
				</span>
			</div>
			<Button
				text="Sign In"
				onClick={handleFormSubmit}
				style={{
					...buttonClasses.lynkitOrangeFill,
					borderRadius: "12px",
					boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
					height: "48px",
					fontWeight: "600",
					fontSize: "1rem",
					lineHeight: "22px",
					textTransform: "none",
				}}
				disabled={isLoading}
			/>
		</form>
	);
}

function useShowExpiredToast() {
	useEffect(() => {
		if (window.localStorage.getItem("isExpired")) {
			toastMessage(false, "Session Expired! Please login again...");
			window.localStorage.removeItem("isExpired");
		}
	}, []);
}
function ForgotPassword({ open, onClose }) {
	const [formData, setFormData] = useState({
		email: "",
		projectId: "LYNKID",
	});
	const [error, setError] = useState({});
	const [isOtpSent, setIsOtpSent] = useState(false);
	const [seconds, setSeconds] = useState(0);

	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData((old) => ({ ...old, [name]: value }));
	};
	const [handleForgotPass, { isLoading: isHandlingForgot }] = useForgotPasswordMutation();
	const [handleChangePass, { isLoading: isHandlingUpdate }] = useUpdatePasswordMutation();

	const handleFormSubmit = async (resend = false) => {
		let payload = {
			email: formData.email,
			projectId: "LYNKID",
		};

		if (isOtpSent) {
			payload = {
				...payload,
				otp: formData.otp,
				newPassword: formData.newPassword,
			};
		}

		const {
			data: { success, message },
		} = isOtpSent && !resend ? await handleChangePass(payload) : await handleForgotPass(payload);
		if (!success) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			if (!isOtpSent || resend) {
				setIsOtpSent(true);
				setSeconds(60);
			} else {
				handleClose();
			}
		}
	};
	const handleClose = () => {
		setFormData({});
		onClose();
	};

	const handleFormValidation = () => {
		let error = {};

		if (!isOtpSent) {
			if (!formData.email) error.email = "Email is required";
			else if (!valid_email(formData.email)) error.email = "Please enter a valid email";
		} else {
			if (!formData.otp) error.otp = "Please enter the OTP";
			if (!formData.newPassword) error.newPassword = "Password is required";
			else if (!valid_password(formData.newPassword))
				error.newPassword = "Password should contain minimum 8 characters, including at least 1 uppercase letter, 1 lowercase letter, 1 special character, and 1 number.";

			if (!formData.confirmPassword) error.confirmPassword = "Confirm new password";
			else if (formData.confirmPassword !== formData.newPassword) error.confirmPassword = "Passwords do not match";
		}

		if (Object.keys(error).length) {
			setError(error);
		} else {
			setError({});
			handleFormSubmit();
		}
	};

	const [passType, setPassType] = useState("password");
	const togglePassType = () => setPassType(passType == "password" ? "text" : "password");
	const [confPassType, setConfPassType] = useState("password");
	const toggleConfPassType = () => setConfPassType(confPassType == "password" ? "text" : "password");

	useEffect(() => {
		if (!isOtpSent) return;

		// console.log("interval usereffect");

		let interval = setInterval(() => {
			setSeconds((s) => {
				if (s == 0) {
					// console.log("reset to 0");
					clearInterval(interval);
					return 0;
				}
				return s - 1;
			});
		}, 1000);

		return () => clearInterval(interval);
	}, [isOtpSent]);

	return (
		<form className="form-inputs">
			{!isOtpSent ? <Input type="email" name="email" label="Email" placeholder="enter your email" value={formData.email} onChange={handleInput} error={error.email} required /> : null}

			{isOtpSent ? (
				<>
					<Input name="otp" label="OTP" placeholder="enter OTP" value={formData.otp || ""} onChange={handleInput} error={error.otp} required/>
					<Input
						label="New Password"
						id="password"
						name="newPassword"
						placeholder="Enter new password"
						onChange={handleInput}
						type={passType}
						adornment={{
							is: true,
							onClick: togglePassType,
							type: passType === "password" ? "eye" : "eyeClose",
						}}
						value={formData.newPassword || ""}
						error={error.newPassword}
						required
					/>
					<Input
						label="Confirm Password"
						id="passwordConfirm"
						name="confirmPassword"
						placeholder="Confirm new password"
						onChange={handleInput}
						type={confPassType}
						adornment={{
							is: true,
							onClick: toggleConfPassType,
							type: confPassType === "password" ? "eye" : "eyeClose",
						}}
						value={formData.confirmPassword || ""}
						error={error.confirmPassword}
						required
					/>
					{seconds == 0 ? (
						<Button
							variant="text"
							style={{
								...textClasses.boldText,
								color: theme.themeOrange,
								textDecoration: "underline",
								cursor: "pointer",
								textTransform: "none",
							}}
							text="Resend OTP"
							onClick={() => handleFormSubmit(true)}
						/>
					) : (
						<Typography
							sx={{
								...textClasses.boldText,
								textTransform: "none",
							}}
						>
							Resend OTP in {seconds} seconds
						</Typography>
					)}{" "}
				</>
			) : null}

			<Button
				text={isOtpSent ? "Submit" : "Get OTP"}
				onClick={handleFormValidation}
				style={{
					...buttonClasses.lynkitOrangeFill,
					borderRadius: "12px",
					boxShadow: "rgba(0, 0, 0, 0.25) 0px 4px 4px",
					height: "48px",
					fontWeight: "600",
					fontSize: "1rem",
					lineHeight: "22px",
					textTransform: "none",
				}}
				disabled={isHandlingForgot || isHandlingUpdate}
			/>
		</form>
	);
}

// function _Login() {
// 	return (
// 		<>

// 		</>
// 	);
// }
