import React, { useEffect, useRef, useState } from "react";

import { GenericTable, Input, toastMessage, Button, tCell, ActionMenu, Dialog, Dropdown } from "../utils/index";
import theme, { buttonClasses } from "../utils/theme";
import { useOrgs, useTags, useUserDetail, useUserPermissions, useVehicles, useProductCategories, useProducts, useSuppliers } from "../../services/hooks";
import { fakeFileInput, formatDate } from "../../services/functions";
import { useAddTagMutation, useDeleteTagMutation, useUpdateTagMutation, useUploadBulkTagMutation } from "../../api/deviceSlice";
import { useMapVehicleTagMutation } from "../../api/vmsSlice";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { debounce } from "../../services/functions";
import { Grid } from "@mui/material";
import { GenericstatsV2 } from "../utils/Genericstats";

const SAMPLE_EXCEL_PATH = "/sample_excels/tag_sample.xlsx";
const EXCEL_TYPE = "tag";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const TagManagement = () => {
	const { userPermissions, isFetched } = useUserPermissions();

	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const [isModal, setIsModal] = useState(false);
	const [isAttachTagModal, setIsAttachTagModal] = useState(false);
	const [filterApplied, setFilterApplied] = useState(false);
	const filterFields = [
		{
			inputType: "textfield",
			id: "1",
			name: "epc",
			type: "text",
			label: "EPC",
			placeholder: "EPC",
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "textfield",
			id: "2",
			name: "tId",
			type: "text",
			label: "TID",
			placeholder: "TID",
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "textfield",
			id: "3",
			name: "displayName",
			type: "text",
			label: "Tag Name",
			placeholder: "Tag Name",
			readOnly: false,
			error: "",
			value: "",
		},
		{
			inputType: "textfield",
			id: "4",
			name: "allocatedToId",
			type: "text",
			label: "Allocated To",
			placeholder: "Allocated To",
			readOnly: false,
			error: "",
			value: "",
		},
	];
	if (userDetail?.userType == "superAdmin") {
		filterFields.push({
			inputType: "dropdown",
			id: "orgId",
			name: "orgId",
			label: "Organization",
			error: "",
			option: orgOptions,
		});
	}
	const onModalOpen = (type) => {
		if (type === "Attach Tag") {
			tagDataRef.current = {};
			setIsAttachTagModal(true);
		} else {
			setIsModal(true);
		}
	};
	const onModalClose = (type) => {
		if (type === "Attach Tag") {
			setIsAttachTagModal(false);
		} else {
			setIsModal(false);
			if (updateDataRef.current) {
				updateDataRef.current = null;
			}
		}
	};
	const tagDataRef = useRef(null);
	const updateDataRef = useRef(null);
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { tags, count, isLoading, message, getTags } = useTags(filterRef.current);

	const [handleDelete] = useDeleteTagMutation();
	let actionHandlers = {};
	if (userPermissions?.updateTag?.value) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = tags[id];
			setIsModal(true);
		};
	}
	if (userPermissions?.deleteTag?.value) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = tags[id];
			const payload = { deleteId: data._id };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}
	if (userPermissions?.mapVehicleTag?.value) {
		actionHandlers.allocatedTag = (id) => {
			tagDataRef.current = tags[id];
			setIsAttachTagModal(true);
		};
	}

	let header = ["S.no", "Display Name", "TID", "EPC", "Tag Type", "Status", "Allocated To"];
	if (userDetail?.userType == "superAdmin") {
		header.push("Org Name");
	}
	if (userPermissions?.updateTag?.value || userPermissions?.deleteTag?.value) {
		header.push("Action");
	}

	const rows =
		tags?.map((d, i) => {
			let row = [];
			row.push(
				tCell((page_no - 1) * pagesize + i + 1),
				tCell(d.displayName),
				tCell(d.tId),
				tCell(d.epc),
				tCell(d.tagType),
				tCell(d.status),
				tCell(
					d.allocationDetails?.vehicleNumber
						? d.allocationDetails?.vehicleNumber
						: d.allocationDetails?.allocatedToName || d.allocationDetails?.allocationType
						? `${d.allocationDetails?.allocatedToName}(${d.allocationDetails?.allocationType})`
						: d.allocationDetails?.assetName && d.allocationDetails?.assetType
						? `${d.allocationDetails?.assetName} (${d.allocationDetails?.assetType})`
						: ""
				)
			);
			if (userDetail?.userType == "superAdmin") {
				row.push(tCell(d.orgName));
			}
			if ((userPermissions?.updateTag?.value || userPermissions?.deleteTag?.value) && d.status !== "Allocated") {
				row.push(
					<div style={{ paddingLeft: "0.5rem" }}>
						<ActionMenu id={i} handlers={actionHandlers} />
					</div>
				);
			} else {
				row.push("-");
			}
			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getTags(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getTags(filterRef.current);
	};
	const onUpdateSuccess = () => {
		getTags(filterRef.current);
	};

	const [handleUploadExcel] = useUploadBulkTagMutation();
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			// const payload = {
			// 	file,
			// 	type: EXCEL_TYPE,
			// };
			const {
				data: { error, message },
			} = await handleUploadExcel(formData);
			if (error) {
				toastMessage(false, message);
			} else {
				toastMessage(true, message);
				onUpdateSuccess();
			}
		});
	};

	const handleFilterChange = (filter) => {
		if ((filter?.epc != "" || filter?.tId != "" || filter?.displayName != "") && Object.keys(filter).length > 0) {
			// console.log('filter on change-device', filter);
			const updatedFilter = { ...filter };
			const orgIdValue = filter?.orgId?.value || "";
			updatedFilter.orgId = orgIdValue;

			// Update filterRef.current
			filterRef.current = { ...filterRef.current, ...updatedFilter };
			getTags(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.epc === "" && filter?.tId === "" && filter?.displayName === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log('without-filter on change-device', filter);
			getTags(defaultFilter);
			setFilterApplied(false);
		}
	};
	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getTag?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem">
				<Grid item xl={6} lg={6} md={5} sm={3} xs={12}>
					<h1 className="title">Tag Management</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={9} xs={12}>
					<Grid container spacing="0.5rem">
						{userPermissions?.mapVehicleTag?.value ? (
							<Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
								<Button onClick={() => onModalOpen("Attach Tag")} text="Allocate Tag" style={buttonClasses.lynkitOrangeEmpty} />{" "}
							</Grid>
						) : null}
						{userPermissions?.addTag?.value ? (
							<Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
								<Button onClick={onModalOpen} text="Add Tag" style={buttonClasses.lynkitOrangeEmpty} />{" "}
							</Grid>
						) : null}
						{userPermissions?.addTag?.value ? (
							<Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
								<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
							</Grid>
						) : null}
						{userPermissions?.addTag?.value ? (
							<Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
								<a href={SAMPLE_EXCEL_PATH} download>
									<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
								</a>
							</Grid>
						) : null}
					</Grid>
				</Grid>
			</Grid>

			<hr className="bgg" />
			<GenericstatsV2 statsFor={"Tag"} />
			<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			<GenericTable
				header={header}
				rows={rows}
				pageCount={count || tags?.length}
				pageNo={filterRef.current?.page_no}
				limit={filterRef.current?.pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			{isModal && <AddTag open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
			{isAttachTagModal && (
				<AttachTag
					open={isAttachTagModal}
					selectedTag={tagDataRef.current}
					onClose={() => onModalClose("Attach Tag")}
					onUpdateSuccess={onUpdateSuccess}
					multiTagSupport={userPermissions?.multiTagSupport?.value}
					assetMappingSupport={userPermissions?.assetMappingSupport?.value}
				/>
			)}
		</div>
	);
};

export default TagManagement;

const defaultForm = {
	displayName: "", // required
	tagType: "", // required
	tId: "", // required
	epc: "", // required
	updateId: undefined,
	orgId: undefined,
};
function fillUpdateData(data) {
	return {
		displayName: data.displayName,
		tagType: data.tagType,
		tId: data.tId,
		epc: data.epc,
		updateId: data._id,
		orgId: data.orgId,
	};
}

const tagTypeOptions = [
	{ label: "--select--", value: "" },
	{ label: "Fast Tag", value: "Fast Tag" },
	{ label: "Fixed Tag", value: "Fixed" },
	{ label: "Temporary Tag", value: "Temporary" },
];

function AddTag({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});

	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();

	const orgValue = orgOptions.find((_) => _.value == formData.orgId);

	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData((old) => ({ ...old, [name]: value }));
	};
	const onDropdownChange = (option, name) => {
		handleInput({ target: { name, value: option?.value || "" } });
	};

	const [handleAdd, { isLoading: isAdding }] = useAddTagMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateTagMutation();
	const tagTypeValue = tagTypeOptions.find((option) => option.value === formData.tagType);

	const handleFormSubmit = async () => {
		const payload = { ...formData, orgName: orgValue?.label };
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization name is required";
		if (!formData.displayName) error.displayName = "Display name is required";
		if (!formData.tagType) error.tagType = "Tag type is required";
		if (!formData.tId && !formData.epc) error.tId = "TID or EPC is required";
		if (!formData.epc && !formData.tId) error.epc = "EPC or TID is required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		setError({});
		onClose();
	};
	return (
		<Dialog size="xs" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={updateData ? "Update Tag" : "Add Tag"} isSubmitDisabled={isAdding || isUpdating}>
			<form className="">
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown id="orgId" name="orgId" label="Organization Name" onChange={onDropdownChange} options={orgOptions} value={orgValue} error={error.orgId} required={true} />
					) : null}
					<Input label="Display Name" placeholder="Enter Display Name" name="displayName" value={formData.displayName} onChange={handleInput} required error={error.displayName} />
					<Dropdown id="tagType" name="tagType" label="Tag Type" onChange={onDropdownChange} options={tagTypeOptions} value={tagTypeValue} required error={error.tagType} />

					<Input label="Tag TID" placeholder="Enter Tag TID" name="tId" value={formData.tId} onChange={handleInput} required error={error.tId} />
					<Input label="Tag EPC" placeholder="Enter Tag EPC" name="epc" value={formData.epc} onChange={handleInput} required error={error.epc} />
				</div>
			</form>
		</Dialog>
	);
}

const defaultAttachTagForm = {
	assetType: "vehicle",
	assetId: "",
	tagData: [],
};

let assetTypeOptions = [{ label: "Vehicle", value: "vehicle" }];

let multiAssetTypeOptions = [
	{ label: "Vehicle", value: "vehicle" },
	{ label: "Product", value: "product" },
	{ label: "Product Category", value: "productCategory" },
	{ label: "Supplier", value: "supplier" },
	// { label: "Asset", value: "asset" },
];

function AttachTag({ onClose, selectedTag, open, onUpdateSuccess, multiTagSupport, assetMappingSupport }) {
	const filterRef = useRef({});
	const { productCategories, count, isLoading, message, getProdCats } = useProductCategories(filterRef.current);
	const { products, count: productCount, getProducts } = useProducts(filterRef.current);
	const { suppliers, count: supplierCount, getSuppliers } = useSuppliers(filterRef.current);
	const [formData, setFormData] = useState(
		selectedTag && Object.keys(selectedTag).length > 0
			? { ...defaultAttachTagForm, tagData: [{ tag_tid: selectedTag.tId, tag_epc: selectedTag.epc, tagType: selectedTag.tagType, displayName: selectedTag.displayName }] }
			: defaultAttachTagForm
	);
	const [error, setError] = useState({});
	const handleInput = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
		// console.log(formData, name, value);
	};
	const onDropdownChange = (option, name) => {
		let value = Array.isArray(option) ? option.map((_) => _.value) : option?.value || "";
		handleInput({ target: { name, value } });
	};
	const vehicleRef = useRef({
		page_no: "1",
		pagesize: "25",
		search: "",
		searchBy: "vehicle",
	});
	const tagRef = useRef({
		displayName: "",
	});
	const { vehicles, isLoading: isVehicleLoading, refetch } = useVehicles(vehicleRef.current);
	const [assetOptions, setAssetOptions] = useState(vehicles ? vehicles?.map((ele) => ({ label: ele.truck_no, value: ele._id })) : []);

	// Vehicle query, options and value builder
	let vehicleOptions = vehicles ? vehicles?.map((ele) => ({ label: ele.truck_no, value: ele._id })) : [];
	let vehicleValue = vehicleOptions.find((obj) => obj.value === formData.assetId) ?? null;

	useEffect(() => {
		// console.log("Useeffect ran --> ", formData.assetType);
		switch (formData.assetType) {
			case "vehicle": {
				setAssetOptions(vehicles ? vehicles?.map((ele) => ({ label: ele.truck_no, value: ele._id })) : []);
				break;
			}
			case "supplier": {
				setAssetOptions(suppliers ? suppliers?.map((ele) => ({ label: ele.supplierName, value: ele._id })) : []);
				break;
			}
			case "product": {
				setAssetOptions(products ? products?.map((ele) => ({ label: ele.productName, value: ele._id })) : []);
				break;
			}
			case "productCategory": {
				setAssetOptions(productCategories ? productCategories?.map((ele) => ({ label: ele.productCategory, value: ele._id })) : []);
				break;
			}
		}
		// console.log(assetOptions);
	}, [formData.assetType, vehicles]);

	// Tag query, options and value builder
	const { tags } = useTags(tagRef.current, "getModalTags");
	let tagOptions = tags
		? tags.map((ele) => ({
				label: ele.displayName,
				value: {
					tag_tid: ele.tId,
					tag_epc: ele.epc,
					tagType: ele.tagType,
					displayName: ele.displayName,
				},
		  }))
		: [];
	let tagValue = tagOptions.filter((options) => formData.tagData.find((tag) => tag.displayName === options?.value?.displayName));
	const [handleMapVehicleTag, { isLoading: isAttaching }] = useMapVehicleTagMutation();
	const handleFormSubmit = async () => {
		const payload = { ...formData };
		const {
			data: { error, message },
		} = await handleMapVehicleTag(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};

	const handleFormValidation = () => {
		let error = {};
		if (!formData.assetType) error.assetType = "Asset Type is required";
		if (!formData.assetId) error.assetId = "Vehicle Number is required";
		if (formData.tagData.length === 0) error.tagData = "Tag is required";
		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		setError({});
		onClose();
	};

	const onInputChange = debounce((e) => {
		vehicleRef.current = {
			page_no: "1",
			pagesize: "25",
			search: e.target.value,
			searchBy: "vehicle",
		};
		refetch();
	}, 500);

	return (
		<Dialog size="sm" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={"Attach Tag"} isSubmitDisabled={isAttaching}>
			<form className="form-inputs flex-col">
				<Dropdown
					id={"assetType"}
					name={"assetType"}
					label={"Select Asset Type"}
					onChange={onDropdownChange}
					options={assetMappingSupport ? multiAssetTypeOptions : assetTypeOptions}
					value={multiAssetTypeOptions.find((ele) => ele.value === formData.assetType)}
					error={error.assetType}
					required={true}
				/>
				<Dropdown
					id={"vehicleId"}
					name={"assetId"}
					label={"Select Asset"}
					onChange={onDropdownChange}
					onInputChange={(e) => onInputChange(e)}
					options={isVehicleLoading ? [{ label: "Loading...", value: "loading" }] : assetOptions || []}
					value={assetOptions.find((ele) => ele.value === formData.assetId)}
					error={error.assetId}
					required={true}
				/>
				<Dropdown
					id={"tag"}
					name={"tagData"}
					label={"Tag"}
					onChange={onDropdownChange}
					options={tagOptions}
					value={tagValue}
					error={error.tagData}
					multiple={true}
					getOptionDisabled={() => (!multiTagSupport && tagValue?.length == 1 ? true : false)}
					required={true}
				/>
			</form>
		</Dialog>
	);
}
