import React, { useLayoutEffect, useRef, useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dark from '@amcharts/amcharts4/themes/dark'; // Import the dark theme

am4core.useTheme(am4themes_animated);


export default function NewBarChart({ dataSet = [], chartFor, chartOnClick, chartWidth }) {
    localStorage.getItem('selectedTheme') === 'darkMode' ?  am4core.useTheme(am4themes_dark) : null // Apply the dark theme
    useEffect(() => {
        // Create chart instance
        let chart = am4core.create("chartdiv", am4charts.XYChart);

        // Add data

        // console.log('dataSet==>', chartFor, dataSet)
        chart.data = dataSet;

        chart.padding(40, 40, 40, 40);
        chart.logo.disabled = true;


        // Create category axis
        let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        if (chartFor === 'today' || chartFor ===  'custom') {
            categoryAxis.dataFields.category = "checkpointName";
            categoryAxis.renderer.labels.template.tooltipText = "{checkpointName}"; // Display full category on hover
        } else {
            categoryAxis.dataFields.category = "_id";
            categoryAxis.renderer.labels.template.tooltipText = "{_id}"; // Display full category on hover
        }
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.tooltip.disabled = true;
        categoryAxis.renderer.labels.template.maxWidth = 100; // Set maximum width for labels
        categoryAxis.renderer.labels.template.wrap = true; // Enable text wrapping
        // categoryAxis.renderer.labels.template.truncate = true; // Truncate long text if necessary

        // categoryAxis.renderer.labels.template.rotation = -25; // Rotate labels if needed
        // categoryAxis.renderer.labels.template.horizontalCenter = "right"; // Align labels to the right
        categoryAxis.renderer.labels.template.verticalCenter = "middle";
        categoryAxis.fontSize = 12;

        categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
            if (target.dataItem && target.dataItem.index & 2 == 1) {
                return dy + 25;
            }
            return dy;
        });

        // Create value axis
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.fontSize = 12;
        valueAxis.tooltip.disabled = true;


        if (chartFor === 'weekend') {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "_id";
            series.dataFields.valueY = "totalIn";
            series.tooltipText = "{name}: {totalIn}";
            series.name = "Gate In";
            series.columns.template.focusable = true;
            series.columns.template.hoverOnFocus = true;
            series.columns.template.tooltipText = "xx {valueY}";
            series.columns.template.fill = am4core.color('#228b22');

            let series2 = chart.series.push(new am4charts.ColumnSeries());
            series2.dataFields.categoryX = "_id";
            series2.dataFields.valueY = "totalOut";
            series2.name = "Gate Out";
            series2.tooltipText = "{name}: {totalOut}";
            series2.columns.template.fill = am4core.color('#FF0101');
        }
        if (chartFor === 'today' || chartFor ===  'custom') {
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.categoryX = "checkpointName";
            series.dataFields.valueY = "count";
            series.tooltipText = "{name}: {valueY}";
            series.name = "Vehicle Count";
            series.columns.template.focusable = true;
            series.columns.template.hoverOnFocus = true;
            series.columns.template.tooltipText = "xx {valueY}";
            let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-color').trim();
            series.columns.template.fill = am4core.color(primaryColor);
        }

        // Create series

        chart.dragGrip.disabled = true;

        // Add scrollbar
        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.parent = chart.bottomAxesContainer;
        chart.scrollbarX.align = "center";

        // Add legend
        chart.legend = new am4charts.Legend();
        chart.legend.position = "top";
        chart.legend.scrollable = true;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();

        // Enable responsive features
        chart.responsive.enabled = true;
        chart.responsive.rules.push({
            relevant: function (target) {
                return target.pixelWidth <= 600; // Adjust this value based on your mobile breakpoint
            },
            state: function (target, stateId) {
                if (target instanceof am4charts.AxisRendererY) {
                    target.labels.template.inside = false;
                    target.labels.template.paddingTop = 10; // Adjust padding as needed
                    target.labels.template.paddingBottom = 10; // Adjust padding as needed
                }
            }
        });

        // Add cursor effect on hover
        chart.series.each((series) => {
            series.columns.template.cursorOverStyle = am4core.MouseCursorStyle.pointer;
            series.columns.template.cursorDownStyle = am4core.MouseCursorStyle.grabbing;
        });


        // Add action on click of bar
        chart.series.each((series) => {
            series.columns.template.events.on("hit", (event) => {
                // Access data associated with the clicked bar
                const clickedDataItem = event.target.dataItem;
                chartOnClick(clickedDataItem)
                // Here you can define your action on click, for example:
                // window.open("https://example.com", "_blank");
            });
        });


        return () => {
            chart.dispose();
        };
    }, [chartFor, dataSet]);

    return (
        <div id="chartdiv" style={{ width: chartWidth, height: "500px" }}></div>
    );
};
