import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { tCell, Button, GenericTable, ActionMenu, DetailCard } from "../utils";
import { useDevices, useUserPermissions } from "../../services/hooks";
import theme, { buttonClasses, cardClasses, textClasses } from "../utils/theme";
import { Card, CardContent, CardHeader, Grid, IconButton, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { capitalizeCamelCase, debounce } from "../../services/functions";
import { Navigate } from "react-router-dom/dist";
import Genericfilter from "../utils/Genericfilter";
import { GenericstatsV2 } from "../utils/Genericstats";

const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};

const nestedHeaders = {
	logPattern: ["Command Code", "Log Time", "Key", "Value"],
	antenna: ["Antenna Number", "Status", "Action"],
	valueConfig: ["Key", "Value"],
};

function createDeviceDetailData(data) {
	let dataToShow;
	if (data["deviceType"] == "led") {
		dataToShow = ["displayName", "imei", "deviceType", "ledMsgType", "ledStaticMsg", "characterEncoding"];
	} else if (data.deviceType == "speaker") {
		dataToShow = ["displayName", "imei", "deviceType", "characterEncoding"];
	} else {
		dataToShow = ["displayName", "tidPattern", "startSeparator", "endSeparator", "dataSeparator", "commandIndex", "power", "imei", "logPattern", "valueConfig", "antenna", "characterEncoding"];
	}

	// console.log({ data });

	const result = (
		<>
			{dataToShow.map((key, i) => {
				if (["logPattern", "antenna"].includes(key)) {
					// console.log(key, data[key]);
					let items = [];
					for (let item of data[key]) {
						// console.log({ item });

						let row = [];
						for (let key in item) {
							row.push(
								<div className="detail_inner_row" key={i + key}>
									<div>{capitalizeCamelCase(key)} : </div>
									<div>{item[key]}</div>
								</div>
							);
						}

						items.push(
							<div className="device_detail_nest_wrap" key={i + key}>
								<div style={{ fontWeight: "bold" }}>{capitalizeCamelCase(key)} :</div>
								<div className="device_inner_row_wrap">{row}</div>
							</div>
						);
					}
					// console.log(items);
					return (
						<div className="device_nested_wrap" key={i}>
							{items}
						</div>
					);
				}
				if (key == "valueConfig") {
					let row = [];
					for (let item in data[key]) {
						row.push(
							<div className="detail_inner_row" key={i + key}>
								<div>{capitalizeCamelCase(item)} : </div>
								<div>{data[key][item]}</div>
							</div>
						);
					}

					return (
						<div className="device_nested_wrap" key={i}>
							<div className="device_detail_nest_wrap">
								<div style={{ fontWeight: "bold" }}>{capitalizeCamelCase(key)} :</div>
								<div className="device_inner_row_wrap">{row}</div>
							</div>
						</div>
					);
				}
				return (
					<div className="detail_row" key={i}>
						<div className="detail_row_title">{capitalizeCamelCase(key)}</div>
						<div className="detail_row_value">{data[key]}</div>
					</div>
				);
			})}
		</>
	);

	return result;

	// console.log({ result });
}

const filterFields = [
	{
		inputType: "textfield",
		id: "deviceUniqueID",
		name: "imei",
		type: "text",
		label: "Device Unique ID",
		placeholder: "Device Unique ID",
		readOnly: false,
		error: "",
		value: "",
	},
	{
		inputType: "textfield",
		id: "deviceName",
		name: "displayName",
		type: "text",
		label: "Device Name",
		placeholder: "Device Name",
		readOnly: false,
		error: "",
		value: "",
	},
];

const DeviceManagement = () => {
	const navigate = useNavigate();
	const { userPermissions, isFetched } = useUserPermissions();
	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { devices, count, isLoading, getDevices } = useDevices(filterRef.current);

	const [isOpen, setIsOpen] = useState(false);
	const [detail, setDetail] = useState(null);
	const [filterApplied, setFilterApplied] = useState(false);
	const handleDetailClose = () => {
		setIsOpen(false);
		setDetail(null);
	};

	const handleDeviceView = (e, index) => {
		// console.log(index, "view", devices[index], index);
		setDetail(createDeviceDetailData(devices[index == undefined ? e : index]));
		setIsOpen(true);
	};

	const handleViewLogs = (index) => {
		if (index == undefined || !devices[index] || devices[index].imei == undefined) return;
		navigate("/devices/logs", { state: { deviceId: devices[index].imei } });
	};
	let header = ["S.no", "Device Name", "Device Type", "IMEI", "No. of Antenna"];

	// if permission
	//TODO device detail permission
	header.push("Actions");
	// if (userPermissions?.updateDevice?.value || userPermissions?.deleteDevice?.value) {
	// }

	const actionHandlers = {
		view: handleDeviceView,
	};
	if (userPermissions?.updateDevice?.value) {
		actionHandlers.edit = (i) => {
			// console.log(i, "edit");
			navigate("/devices/edit-device", { state: { data: devices[i] } });
		};
	}
	if (userPermissions?.addDevice?.value) {
		actionHandlers.duplicate = (i) => {
			navigate("/devices/add-device", { state: { data: devices[i] } });
		};
	}
	if (userPermissions?.deleteDevice?.value) {
		// actionHandlers.delete = (id) => {
		// 	console.log(id, "delete");
		// };
	}

	const rows = devices?.map((d, i) => {
		let row = [];
		row.push(
			tCell((page_no - 1) * pagesize + i + 1),
			tCell(d?.Displayname || d?.displayName, theme.themeOrange, "pointer", handleDeviceView, i),
			tCell(`${capitalizeCamelCase(d.deviceType)} ${d.deviceSubType ? `(${d.deviceSubType})` : ""}`),
			tCell(d?.imei),
			tCell(d?.antenna.filter((ele) => ele.antennanumber !== "").length ? d?.antenna.filter((ele) => ele.antennanumber !== "").length : "-")
		);
		//TODO device detail permission
		let _actionHandlers = { ...actionHandlers };
		if (!["led", "speaker"].includes(d.deviceType)) {
			_actionHandlers.viewLogs = handleViewLogs;
		}
		row.push(<ActionMenu id={i} handlers={_actionHandlers} />);
		return row;
	});

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getDevices(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getDevices(filterRef.current);
	};
	const handleFilterChange = (filter) => {
		if ((filter?.imei != "" || filter?.displayName != "") && Object.keys(filter).length > 0) {
			// console.log("filter on change-device", filter);
			filterRef.current = { ...filterRef.current, ...filter };
			getDevices(filterRef.current);
			setFilterApplied(true);
		}
		if (filter?.imei === "" && filter?.displayName === "" && Object.keys(filter).length > 0 && filterApplied === true) {
			// console.log("without-filter on change-device", filter);
			getDevices(defaultFilter);
			setFilterApplied(false);
		}
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !userPermissions.getDevice?.value) return <Navigate to="/not-authorized" replace />;

	return (
		<div className="contentpanel">
			<div className="cust-row flex-algn-cent">
				<div className="cust-col-5">
					<h1 className="title">Device Management</h1>
				</div>
				{userPermissions?.addDevice?.value ? (
					<div className="cust-col-5 flex-jc-end">
						<Link to="/devices/add-device" className="a_tag_no">
							<Button text="Add Device" style={buttonClasses.lynkitOrangeEmpty} />
						</Link>
					</div>
				) : null}
			</div>
			<hr className="bgg" />
			<GenericstatsV2 statsFor={"devices"} />
			<Genericfilter filterFields={filterFields} onFilterChange={debounce((filter) => handleFilterChange(filter), 500)} />
			<div className="main_content">
				<GenericTable
					header={header}
					rows={rows}
					pageCount={count}
					pageNo={filterRef.current.page_no}
					limit={filterRef.current.pagesize}
					onPageChange={onPageChange}
					onPageSizeChange={onPageSizeChange}
					isLoading={isLoading}
				/>

				<DetailCard isOpen={isOpen} onClose={handleDetailClose} title={"Device Details"}>
					{detail}
				</DetailCard>
			</div>
		</div>
	);
};

export default DeviceManagement;
