import { useEffect, useRef, useState } from "react";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { ActionMenu, Button, Checkbox, Dialog, Dropdown, GenericTable, Input, tCell, toastMessage } from "../utils";
import { useDynamicModules, useOrgs, useTemplateList, useUserDetail, useUserPermissions, useUsers } from "../../services/hooks";
import { buttonClasses } from "../utils/theme";
import { fakeFileInput, formatDate } from "../../services/functions";
import { useCreateDynamicModuleMutation, useDeleteDynamicModuleMutation, useUpdateDynamicModuleMutation } from "../../api/vmsSlice";

// const SAMPLE_EXCEL_PATH = "/sample_excels/supplier_sample.xlsx";
const EXCEL_TYPE = "supplier";
const defaultFilter = {
	pagesize: 10,
	page_no: 1,
};
export default function DynamicModuleCreater() {
	const { userPermissions, isFetched } = useUserPermissions();
	const { userDetail } = useUserDetail();

	const canAdd = userDetail?.userType == "superAdmin" || userPermissions?.addModule?.value;
	const canGet = userDetail?.userType == "superAdmin"; //|| userPermissions?.getModule?.value;
	const canUpdate = userDetail?.userType == "superAdmin" || userPermissions?.updateModule?.value;
	const canDelete = userDetail?.userType == "superAdmin" || userPermissions?.deleteModule?.value;

	const [isModal, setIsModal] = useState(false);
	const updateDataRef = useRef(null);

	const filterRef = useRef(defaultFilter);
	const { page_no, pagesize } = filterRef.current;
	const { dynamicModules, count, isLoading, getDynamicModules } = useDynamicModules(filterRef.current);
	const onModalOpen = () => setIsModal(true);
	const onModalClose = () => {
		setIsModal(false);
		if (updateDataRef.current) {
			updateDataRef.current = null;
		}
	};
	const onUpdateSuccess = () => {
		getDynamicModules(filterRef.current);
	};

	const [handleDelete] = useDeleteDynamicModuleMutation();
	let actionHandlers = {};
	if (canUpdate) {
		actionHandlers.edit = (id) => {
			updateDataRef.current = dynamicModules[id];
			setIsModal(true);
		};
	}
	if (canDelete) {
		actionHandlers.delete = async (id) => {
			// console.log(id, "delete");
			const data = dynamicModules[id];
			const payload = { deleteId: data._id, orgId: data.orgId };
			const {
				data: { error, message },
			} = await handleDelete(payload);
			if (error) {
				toastMessage(false, message);
			} else {
				onUpdateSuccess();
				toastMessage(true, message);
			}
		};
	}
	let header = ["S.no", "Module Name", "Template Name", "Created On"];
	if (userDetail?.userType == "superAdmin") {
		header.push("Org Name");
	}
	if (canUpdate || canDelete) {
		header.push("Action");
	}
	const rows =
		dynamicModules?.map((d, i) => {
			let row = [];
			row.push(tCell((page_no - 1) * pagesize + i + 1), tCell(d.moduleName), tCell(d.templateName), tCell(formatDate(d.createdOn)));

			if (userDetail?.userType == "superAdmin") {
				row.push(tCell(d.orgName));
			}
			if (canUpdate || canDelete) {
				row.push(<ActionMenu id={i} handlers={actionHandlers} />);
			}

			return row;
		}) || [];

	const onPageChange = (page_no) => {
		filterRef.current = { ...filterRef.current, page_no: page_no + 1 };
		getDynamicModules(filterRef.current);
	};
	const onPageSizeChange = (e) => {
		const { value } = e.target;
		filterRef.current = { ...filterRef.current, pagesize: value };
		getDynamicModules(filterRef.current);
	};
	const onBulkExcelUpload = () => {
		fakeFileInput(async (formData) => {
			formData.append("type", EXCEL_TYPE);
			// const payload = {
			// 	file,
			// 	type: EXCEL_TYPE,
			// };

			// const {
			// 	data: { error, message },
			// } = await handleUploadExcel(formData);
			// if (error) {
			// 	toastMessage(false, message);
			// } else {
			// 	toastMessage(true, message);
			// 	onUpdateSuccess();
			// }
		});
	};

	if (isFetched && userPermissions && Object.keys(userPermissions).length && !canGet) return <Navigate to="/not-authorized" replace />;

	return (
		<>
			<ModuleCreater
				canAdd={canAdd}
				onModalOpen={onModalOpen}
				header={header}
				rows={rows}
				count={count}
				page_no={page_no}
				pagesize={pagesize}
				onPageChange={onPageChange}
				onPageSizeChange={onPageSizeChange}
				isLoading={isLoading}
			/>
			{isModal && <AddModule open={isModal} onClose={onModalClose} updateData={updateDataRef.current} onUpdateSuccess={onUpdateSuccess} />}
		</>
	);
}

const defaultForm = {
	moduleName: "",
	templateId: "",

	// listAPI: "",
	// addAPI: "",
	// updateAPI: "",
	// deleteAPI: "",

	// bulkUploadAPI: "",
	// downloadSampleAPI: "",
	// statsAPI: "",

	updateId: undefined, //_id for update case
	orgId: undefined,
	assignDefaultPerms: false,
	adminId: "",
	// permissions: {},
};

function fillUpdateData(data) {
	return {
		moduleName: data.moduleName,
		templateId: data.templateId,
		templateName: data.templateName,
		assignDefaultPerms: data.assignDefaultPerms,
		adminId: data.adminId,

		// listAPI: data.listAPI,
		// addAPI: data.addAPI,
		// updateAPI: data.updateAPI,
		// deleteAPI: data.deleteAPI,

		// bulkUploadAPI: data.bulkUploadAPI,
		// downloadSampleAPI: data.downloadSampleAPI,
		// statsAPI: data.statsAPI,

		updateId: data._id,
		orgId: data.orgId,
		// permissions: data.permissions,
	};
}
export function AddModule({ onClose, open, updateData, onUpdateSuccess }) {
	const [formData, setFormData] = useState(updateData ? fillUpdateData(updateData) : defaultForm);
	const [error, setError] = useState({});
	const { userDetail } = useUserDetail();
	const { orgOptions } = useOrgs();
	const orgValue = orgOptions.find((_) => _.value == formData.orgId) || null;
	const { templateOptions, getTemplateList } = useTemplateList();
	const templateValue = templateOptions.find((_) => _.value == formData.templateId) || null;

	const { getUsers, userOptions } = useUsers();
	const adminValue = userOptions.find((_) => _.value == formData.adminId) || null;

	useEffect(() => {
		if (!formData.orgId) return;
		getTemplateList({ organization_id: formData.orgId });
		getUsers({ orgId: formData.orgId, userType: "admin" });
	}, [formData.orgId]);

	const handleInput = (e) => {
		let { name, value, type, checked } = e.target;
		if (name == "moduleName") {
			if (!value.match(/^[a-zA-Z0-9_/()\-&\s]*$/)) {
				return;
			}
			if (value.length > 50) {
				return;
			}
		}
		value = type == "checkbox" ? checked : value;
		setFormData({ ...formData, [name]: value });
	};
	const onDropdownChange = (option, name) => {
		handleInput({ target: { name, value: option?.value || "" } });
	};

	const [handleAdd, { isLoading: isAdding }] = useCreateDynamicModuleMutation();
	const [handleUpdate, { isLoading: isUpdating }] = useUpdateDynamicModuleMutation();
	const handleFormSubmit = async () => {
		let payload = { ...formData, orgName: orgValue?.label, templateName: templateValue?.label };

		if (updateData) {
			const { updateId, ...updateData } = payload;
			payload = { updateId, updateData };
		}
		// console.log(payload)
		const {
			data: { error, message },
		} = updateData ? await handleUpdate(payload) : await handleAdd(payload);
		if (error) {
			toastMessage(false, message);
		} else {
			toastMessage(true, message);
			onUpdateSuccess();
			handleClose();
		}
	};
	const handleFormValidation = () => {
		let error = {};
		if (userDetail?.userType == "superAdmin" && !formData.orgId) error.orgId = "Organization is required";
		if (!formData.moduleName) error.moduleName = "Module is required";
		if (formData.moduleName.length < 3) error.moduleName = "Module name must be between 3 and 50 characters long";
		if (!formData.templateId) error.templateId = "Template is required";

		// if (!formData.listAPI) error.listAPI = "List API is required";
		// if (!formData.addAPI) error.addAPI = "Add API is required";
		// if (!formData.updateAPI) error.updateAPI = "Update API is required";
		// if (!formData.deleteAPI) error.deleteAPI = "Delete API is required";
		// if (!Object.keys(formData.permissions).length) error.permissions = "Permissions are required";

		if (Object.keys(error).length) {
			setError(error);
		} else {
			handleFormSubmit();
		}
	};
	const handleClose = () => {
		setFormData(defaultForm);
		onClose();
	};

	return (
		<Dialog size="md" open={open} handleClose={handleClose} handleSubmit={handleFormValidation} title={`${updateData ? "Update" : "Add"} Module`} isSubmitDisabled={isAdding || isUpdating}>
			<form className="">
				<div className="form-inputs mt-2">
					{userDetail?.userType == "superAdmin" ? (
						<Dropdown
							id="orgId"
							name="orgId"
							label="Organization Name"
							onChange={onDropdownChange}
							options={orgOptions}
							value={orgValue}
							required
							error={error.orgId}
							disabled={!!updateData}
						/>
					) : null}
					<Input label="Name" placeholder="Enter Module Name" name="moduleName" value={formData.moduleName} onChange={handleInput} required error={error.moduleName} />
					<Dropdown id="template" name="templateId" label="Template" onChange={onDropdownChange} options={templateOptions} value={templateValue} required error={error.templateId} />
					<Checkbox label="Assign Permissions to Admin?" name="assignDefaultPerms" checked={formData.assignDefaultPerms} onChange={handleInput} readOnly={!!updateData} />
					{formData.assignDefaultPerms ? <Dropdown label="Select Admin" name="adminId" onChange={onDropdownChange} value={adminValue} options={userOptions} readonly={!!updateData} /> : null}

					{/* <Input label="Add API" placeholder="Enter Add API Endpoint" name="addAPI" value={formData.addAPI} onChange={handleInput} error={error.addAPI} /> */}
					{/* <Input label="List API" placeholder="Enter List API Endpoint" name="listAPI" value={formData.listAPI} onChange={handleInput} error={error.listAPI} /> */}
					{/* <Input label="Update API" placeholder="Enter Update API Endpoint" name="updateAPI" value={formData.updateAPI} onChange={handleInput} error={error.updateAPI} /> */}
					{/* <Input label="Delete API" placeholder="Enter Delete API Endpoint" name="deleteAPI" value={formData.deleteAPI} onChange={handleInput} error={error.deleteAPI} /> */}

					{/* <Input
						label="Bulk Upload API"
						placeholder="Enter Bulk Upload API Endpoint"
						name="bulkUploadAPI"
						value={formData.bulkUploadAPI}
						onChange={handleInput}
						error={error.bulkUploadAPI}
					/>
					<Input label="Download Sample API" placeholder="Enter Download Sample API Endpoint" name="addAPI" value={formData.addAPI} onChange={handleInput} error={error.addAPI} />
					<Input label="Stats API" placeholder="Enter Stats API Endpoint" name="addAPI" value={formData.addAPI} onChange={handleInput} error={error.addAPI} /> */}
				</div>
			</form>
		</Dialog>
	);
}

export function ModuleCreater({
	canAdd = true,
	onModalOpen = () => {},
	header = [],
	rows = [],
	count = 0,
	page_no = 1,
	pagesize = 10,
	onPageChange = () => {},
	onPageSizeChange = () => {},
	isLoading = false,
}) {
	return (
		<div className="contentpanel">
			<Grid container spacing="0.5rem" mb={2}>
				<Grid item xl={6} lg={6} md={5} sm={1} xs={12}>
					<h1 className="title">Module Management</h1>
				</Grid>
				<Grid item xl={6} lg={6} md={7} sm={1} xs={12}>
					<Grid container spacing="0.5rem" justifyContent={"flex-end"}>
						{canAdd ? (
							<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
								<Button onClick={onModalOpen} text="Add Module" style={buttonClasses.lynkitOrangeEmpty} />
							</Grid>
						) : null}
						{/* {userPermissions?.addDynamicModule?.value ? (
					<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
						<Button onClick={onBulkExcelUpload} text="Bulk Upload" style={buttonClasses.lynkitOrangeFill} />
					</Grid>
				) : null}
				{userPermissions?.addDynamicModule?.value ? (
					<Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
						<a href={SAMPLE_EXCEL_PATH} download>
							<Button text="Download Sample" style={buttonClasses.lynkitBlackFill} />
						</a>
					</Grid>
				) : null} */}
					</Grid>
				</Grid>
			</Grid>
			<hr className="bgg" />
			<GenericTable header={header} rows={rows} pageCount={count} pageNo={page_no} limit={pagesize} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} isLoading={isLoading} />
		</div>
	);
}
