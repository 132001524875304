import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import Burger from "./Burger";
import rfidLogo from "./Images/RFID.svg";
import bluetoothLogo from "./Images/Bluetooth.svg";
import barLogo from "./Images/barcode.svg";
import connectIcon from "./Images/Connect.svg";
import locationIcon from "./Images/location.svg";
import LynkId from "./Images/LynkID_white.png";
import RfidImage from "./Images/RFID.png";
import RfidImage2 from "./Images/secondRfid.png";
import RfidImage3 from "./Images/thirdRfid.png";
import RfidImage4 from "./Images/fourthRfid.png";
import SecondImage from "./Images/twofirst.png";
import SecondImage2 from "./Images/twotwo.png";
import ThirdImage from "./Images/fourthRfid.png";
import ThirdImage2 from "./Images/RFID.png";
import ThirdImage3 from "./Images/secondRfid.png";
import BpclImage from "./Images/bpcl_color.jpg";
import AbImage from "./Images/ab_color.jpg";
import DixonImage from "./Images/dixon_color.jpg";
import SleepwellImage from "./Images/sleepwell_color.jpg";
import BluedartImage from "./Images/bluedart_color.jpg";
import SamsungImage from "./Images/samsung.jpg";
import FlipkartImage from "./Images/flipkart_color.jpg";
import PepfuelImage from "./Images/pepfuels_color.jpg";
import LgImage from "./Images/lg_color.jpg";
import AbdImage from "./Images/adb_color.jpg";
import DelhiveryImage from "./Images/delhivery_color.jpg";
import CmaImage from "./Images/cma_color.jpg";
import KonoikeImage from "./Images/konoike_color.jpg";
import LandmarkImage from "./Images/landmark_color.jpg";

let imageData = [BpclImage, AbImage, DixonImage, SleepwellImage, BluedartImage, SamsungImage, FlipkartImage, PepfuelImage, LgImage, AbdImage, DelhiveryImage, CmaImage, KonoikeImage, LandmarkImage];

function Page() {
	const [accordianActive, setAccordianActive] = useState("1");
	const [pillActive, setPillActive] = useState("1");
	const [scrolled, setScrolled] = useState(false);
	const [partner, setPartner] = useState(false);

	let pill = pillActive;
	let accordian = accordianActive;
	const ChangeValueScroll = () => {
		if (window.scrollY > 10) {
			setScrolled(true);
		}
		if (window.scrollY < 10) {
			setScrolled(false);
		}
	};
	const handleChange = (type) => {
		if (type == "partners") {
			setPartner(!partner);
		}
	};
	const handlePillChange = (para) => {
		setPillActive(para);
		setAccordianActive("1");
	};
	const partners = useRef();
	const home = useRef();
	const contactUs = useRef();
	useEffect(() => {
		if (partner) {
			window.scrollTo({ top: partners.current.offsetTop - 45, behavior: "smooth" });
		}
	}, [partner]);
	window.addEventListener("scroll", ChangeValueScroll);

	const refresh = () => {
		window.location.reload(true);
	};

	return (
		<div>
			<div>
				<div className={scrolled ? "flex-row-sb header-2" : "flex-row-sb header"}>
					<div className="mt-5p">
						<img onClick={() => refresh()} src={LynkId} className="logo-w" />
					</div>
					{/* <div className='flex-row-sb gap-3 mr-4vw'>
            <div className='head-btn'>Home</div>
            <div className='head-btn'>Partners</div>
            <div className='head-btn'>Contact Us</div>
            <div className='head-btn'>Login</div>
          </div> */}

					<div className="mt-8">
						<Burger state={partners} change={handleChange} />
					</div>
				</div>
				<div className="flex-col">
					{/* <div className='flex-col'> */}
					<div ref={home} id="home" className="flex-row-sb web-back1">
						<div className="img1-web">
							<h1 className="head1-web">RFID BASED TRACKING AND IDENTIFICATION</h1>
						</div>
						<div className="img2-web "></div>
					</div>
					{/* </div> */}
					<div className="web-back2">
						<div>
							<p className="font-res">
								RFID provides logistics managers with multiple ways to track and manage products and assets in the supply chain. RFID tags and scanners can potentially improve product
								and materials handling inside and outside the warehouse environment, with applications ranging from inventory management to automation
							</p>
						</div>
						<div className="sec2-L1"></div>
						<div className="sec2-L2"></div>
					</div>
					<div className="flex-col sec3-div">
						<div className="d-flex bg-grey pill-size">
							<div className={pillActive == 1 ? "pil-2 flex-col" : "pil-1 flex-col"} onClick={() => handlePillChange("1")}>
								<div className="pill-logo">
									<img src={rfidLogo} />
								</div>
								<div className="pill-txt">
									<p>RFID</p>
								</div>
							</div>
							<div
								className={pillActive == 2 ? "pil-2 flex-col" : "pil-1 flex-col"}
								onClick={() => {
									handlePillChange("2");
								}}
							>
								<div className="pill-logo">
									<img src={bluetoothLogo} />
								</div>
								<div className="pill-txt">
									<p>Bluetooth</p>
								</div>
							</div>
							<div
								className={pillActive == 3 ? "pil-2 flex-col" : "pil-1 flex-col"}
								onClick={() => {
									handlePillChange("3");
								}}
							>
								<div className="pill-logo">
									<img src={barLogo} />
								</div>
								<div className="pill-txt">
									<p>Barcode Qr code</p>
								</div>
							</div>
							{/* <div className={pillActive == 4 ? 'pil-2 flex-col' : 'pil-1 flex-col'} onClick={() => { setPillActive(4) }}>
                <div className='pill-logo' ><img src={cameraLogo} /></div>
                <div className='pill-txt'><p>Camera</p></div>
              </div> */}
						</div>
						<div className="d-flex-change sec-accordian">
							<div className="f-basis100">
								{pillActive == 1 && accordianActive == 1 ? <img className="accordian-img" src={RfidImage} /> : null}
								{pillActive == 1 && accordianActive == 2 ? <img className="accordian-img" src={RfidImage2} /> : null}
								{pillActive == 1 && accordianActive == 3 ? <img className="accordian-img" src={RfidImage3} /> : null}
								{pillActive == 1 && accordianActive == 4 ? <img className="accordian-img" src={RfidImage4} /> : null}
								{pillActive == 2 && accordianActive == 1 ? <img className="accordian-img" src={SecondImage} /> : null}
								{pillActive == 2 && accordianActive == 2 ? <img className="accordian-img" src={SecondImage2} /> : null}
								{pillActive == 3 && accordianActive == 1 ? <img className="accordian-img" src={ThirdImage} /> : null}
								{pillActive == 3 && accordianActive == 2 ? <img className="accordian-img" src={ThirdImage2} /> : null}
								{pillActive == 3 && accordianActive == 3 ? <img className="accordian-img" src={ThirdImage3} /> : null}
							</div>
							{pillActive == 1 && (
								<div className="accordian">
									<div className="contentBx" onClick={() => setAccordianActive("1")}>
										<div className={accordianActive == 1 ? "label-web2" : "label-web"}>Gate Automation</div>
										<div className={accordianActive == 1 ? "content-web2" : "content-web"}>
											<p className="font-res">
												RFID provides logistics managers with multiple ways to track and manage products and assets in the supply chain. RFID tags and scanners can potentially
												improve product and materials handling inside and outside the warehouse environment, with applications ranging from inventory management to automation.
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("2")}>
										<div className={accordianActive == 2 ? "label-web2" : "label-web"}>Consignment Tracking-Point to Point</div>
										<div className={accordianActive == 2 ? "content-web2" : "content-web"}>
											<p className="font-res">
												Utilizing RFID tags to give each consignment a unique ID that could be recognized and read by RFID readers. Lynkit RFID consignment tracking
												applications have functions of Consignment dispatching, Delivery, returning, searching, locating, and inventorying. It can automatically identify and
												record users and consignment, which can not only clarify the responsibility but also realize automatic inventory.
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("3")}>
										<div className={accordianActive == 3 ? "label-web2" : "label-web"}>Asset Allocation with Biometric</div>
										<div className={accordianActive == 3 ? "content-web2" : "content-web"}>
											<p className="font-res">
												Since storage importments vary from product to product, warehouse mapping studies are necessary to prevent product and monetary loss. Warehouse mapping
												entails determining if the area meets the product importments for the type(s) of goods being stored
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("4")}>
										<div className={accordianActive == 4 ? "label-web2" : "label-web"}>Inventory Management</div>
										<div className={accordianActive == 4 ? "content-web2" : "content-web"}>
											<p className="font-res">
												RFID provides logistics managers with multiple ways to track and manage products and assets in the supply chain. RFID tags and scanners can potentially
												improve product and materials handling inside and outside the warehouse environment, with applications ranging from inventory management to automation
											</p>
										</div>
									</div>
								</div>
							)}
							{pillActive == 2 && (
								<div className="accordian">
									<div className="contentBx" onClick={() => setAccordianActive("1")}>
										<div className={accordianActive == 1 ? "label-web2" : "label-web"}>Asset tracking</div>
										<div className={accordianActive == 1 ? "content-web2" : "content-web"}>
											<p className="font-res">
												Asset tracking is important because it allows businesses to keep tabs on their valuable property, even as it moves from location to location, or
												property to property. Asset tracking can also help businesses become more efficient, identify inventory loss, and quickly and accurately file taxes.
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("2")}>
										<div className={accordianActive == 2 ? "label-web2" : "label-web"}>Man power tracking at warehouse</div>
										<div className={accordianActive == 2 ? "content-web2" : "content-web"}>
											<p className="font-res">
												A) BLE Enabled bands orID cards will be permanently allocated, Plus, on site allocation will be done during check in, which will be returned during
												check out.
												<br />
												B) Auto Attendance, It’s mapping with payroll data or contractual per day rates or working hours, Time spent in specific authorised or unauthorised
												zones, Alerts regarding the same will all be tracked.
												<br />
												C) Security -Worker’s data can be monitored for safety, Their Access control for blocking unauthorized access and alarms for no entry areas or
												operations.
												<br />
												D) Productivity - On the basis of Real Time Tracking, Auto task allocation generated from WMS would be allocated to workers most close by to the items
												based on location data..
											</p>
										</div>
									</div>
								</div>
							)}
							{pillActive == 3 && (
								<div className="accordian">
									<div className="contentBx" onClick={() => setAccordianActive("1")}>
										<div className={accordianActive == 1 ? "label-web2" : "label-web"}>Non Replicable QR code</div>
										<div className={accordianActive == 1 ? "content-web2" : "content-web"}>
											<p className="font-res">
												Secure QR codes with an embedded security image: A robust anti-counterfeiting solution for packaging. A copy detection pattern, also known as a
												copy-resistant image or embedded security image, is a digital image designed to lose key information when copied and re-printed, thus signalling that it
												is a copy.
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("2")}>
										<div className={accordianActive == 2 ? "label-web2" : "label-web"}>Asset Tracking-Point to Point</div>
										<div className={accordianActive == 2 ? "content-web2" : "content-web"}>
											<p className="font-res">
												QR codes for logistics have improved the shipment and delivery operations of many logistics companies worldwide. Especially for small businesses,
												logistics play an important role in boosting customer loyalty because a stress-free shipment and delivery leave a lasting impression on customers.
											</p>
										</div>
									</div>
									<div className="contentBx" onClick={() => setAccordianActive("3")}>
										<div className={accordianActive == 3 ? "label-web2" : "label-web"}>Inventory Management</div>
										<div className={accordianActive == 3 ? "content-web2" : "content-web"}>
											<p className="font-res">
												Barcode asset tracking is an easy to use and cost-effective way for businesses of all sizes to manage and track their physical assets. Whether being
												used for inventory and stock control or lifecycle management, there are two elements that make barcode asset tracking possible; a barcode label and a
												reader
											</p>
										</div>
									</div>
								</div>
							)}
							{/* {
                pillActive == 4 &&
                <div className="accordian" >
                  <div className="contentBx" onClick={() => setAccordianActive(1)}>
                    <div className={accordianActive == 1 ? "label-web2" : "label-web"} >OCR for container number</div>
                    <div className={accordianActive == 1 ? "content-web2" : "content-web"}><p className='font-res'>Since storage importments vary from product to product, warehouse mapping studies are necessary to prevent product and monetary loss.
                      Warehouse mapping entails determining if the area meets the product importments for the type(s) of goods being stored.</p></div>
                  </div>
                  <div className="contentBx" onClick={() => setAccordianActive(2)}>
                    <div className={accordianActive == 2 ? "label-web2" : "label-web"}>Number plates in and out counting</div>
                    <div className={accordianActive == 2 ? "content-web2" : "content-web"}><p className='font-res'>A Bluetooth Beacon is a remote gadget that regularly transmits
                      a Bluetooth Low Energy advertising packet. It’s intercepted by a smart device or other
                      Bluetooth digital gadget and used to establish its position in relation to the beacon itself.</p></div>
                  </div>
                  <div className="contentBx" onClick={() => setAccordianActive(3)}>
                    <div className={accordianActive == 3 ? "label-web2" : "label-web"}>Parcel counting</div>
                    <div className={accordianActive == 3 ? "content-web2" : "content-web"}><p className='font-res'>RFID provides logistics managers with multiple ways to track and manage products and assets in the supply chain.
                      RFID tags and scanners can potentially improve product and materials handling inside and outside the warehouse environment, with applications ranging from inventory management to automation</p></div>
                  </div>

                </div>
              } */}
						</div>
						<div className="d-flex circle-pos">
							<div className={pillActive == 1 ? "circle2" : "circle"}></div>
							<div className={pillActive == 2 ? "circle2" : "circle"}></div>
							<div className={pillActive == 3 ? "circle2" : "circle"}></div>
							{/* <div className={pillActive == 4 ? 'circle2' : 'circle'}></div> */}
						</div>
					</div>
					<div ref={partners} id="partners" className="flex-col grid-back">
						<div>
							<h1 className="client-head" style={{ display: "flex", justifyContent: "center", marginBottom: "14px" }}>
								Our Clients
							</h1>
						</div>
						<div className="grid-client">
							{imageData.map((data, i) => {
								return <img src={data} key={i} />;
							})}
						</div>
					</div>
					<div ref={contactUs} id="contact" className="contact-Us d-flex-change justify-center pos-rel" style={{ background: "#fff" }}>
						<div className="f-basis100  w-75 p-3vw align-sc">
							<h1 className="contact-head">Contact Us</h1>
							<p className="txt-left">We will get back to you as soon as we can.</p>
							<div className="flex-col w-75">
								<input placeholder="Name" className="contact-inpt"></input>
								<input placeholder="Email" className="contact-inpt"></input>
								<input placeholder="Message" className="contact-inpt"></input>
								<button className="contact-btn">Send</button>
							</div>
						</div>
						<div className="f-basis100 pos-rel d-flex">
							<div className="f-basis75 bg-white height-full"></div>
							<div className="f-basis25 contact-img height-full"></div>
							<div className="contact-right">
								<div className="d-flex contact-det">
									<div className="contact-img-div">
										<img src={locationIcon} className="contact-logo" />
									</div>
									<div className="flex-col width-50 contact-detail">
										<div className="web-heading2-w">Address</div>
										<div>
											<p className="font-res">246, Block A, Okhla Industrual Area Phase 1 New Delhi: 110020</p>
										</div>
									</div>
								</div>
								<div className="d-flex contact-det">
									<div className="contact-img-div">
										<img src={connectIcon} className="contact-logo" />
									</div>
									<div className="flex-col width-50 contact-detail">
										<div className="web-heading2-w">Contacts</div>
										<div>
											<p className="font-res">+9111-4082-4028 contact@lynkit.in</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="footer2">Copyright ©2019 LynkID. All Rights Reserved</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page;
