import React, { useEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const PieChartComponent = ({pieData}) => {
  const chartDivRef = useRef(null);

  useEffect(() => {
    // Apply theme
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    let chart = am4core.create(chartDivRef.current, am4charts.PieChart);
    chart.logo.disabled = true;

    // Add data
    // console.log('pieData==>', pieData)  
    chart.data = pieData["product"];

    // Set inner radius
    chart.innerRadius = am4core.percent(50);

    let chartTitle = chart.titles.create();
    chartTitle.text = pieData["productCategory"];
    chartTitle.fontSize = 14;
    chartTitle.marginBottom = 10;
    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "size";
    pieSeries.dataFields.category = "sector";

    // Animate chart
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Cleanup on unmount
    return () => {
      chart.dispose();
    };
  }, [pieData]);

  return <div id="chartdiv" ref={chartDivRef} style={{ width: "400px", height: "250px" }}></div>;
};

export default PieChartComponent;
