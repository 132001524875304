import { Navigate } from "react-router-dom/dist";
import { useAuth } from "../../services/hooks";
import { Loader } from "../utils";

export default function LoadScreen() {
	const redirectUrl = useAuth();
	if (redirectUrl) {
		return <Navigate to={redirectUrl} />;
	}
	return (
		<>
			<Loader height="70vh" size="4rem" />
		</>
	);
}
